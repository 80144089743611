import React from 'react';
import './WalletsMenu.css';

function WalletMenu(props) {
    return (props.trigger) ? (
        <div className='walletsMenu'>
            <div className='walletsMenu-Body slide-in-bottom'>
                <button className='close-btn' onClick={() => props.setTrigger(false)}>  X  </button>
                { props.children }
            </div>
        </div>
    ) : "";
}

export default WalletMenu;