import React from 'react';
import './SectionsMenu.css';

function SectionsMenu(props) {
    return (props.trigger) ? (
        <div className='sectionsMenu'>
            <div className='sectionsMenu-Body slide-in-bottom'>
                <button className='close-btn' onClick={() => props.setTrigger(false)}>  X  </button>
                { props.children }
            </div>
        </div>
    ) : "";
}

export default SectionsMenu;