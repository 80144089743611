import React from 'react';
import './SuccessWindow.css';


function WalletMenu(props) {
    return (props.trigger) ? (
        <div className='outSideSuccessWindow'>
            <div className='mainSuccessWindow-Body slide-in-bottom'>
                <button className='close-btn' onClick={() => props.setTrigger(false)}>  X  </button>
                
                
                { props.children }
            </div>
        </div>
    ) : "";
}

export default WalletMenu;