import React from 'react';
import './WarningWindow.css';

function WarningWindow(props) {
    return (props.trigger) ? (
        <div className='outSideWarningWindow'>
            <div className='mainWarningWindow-Body slide-in-fwd-center'>
                <button className='close-btn' onClick={() => props.setTrigger(false)}>  X  </button>
                
                { props.children }
                
            </div>
        </div>
    ) : "";
}

export default WarningWindow;