import React from 'react';
import './TermsAndConditionsWindow.css';

//<button className='close-btn' onClick={() => props.setTrigger(false)}>  X  </button>

function TermsAndConditionsWindow(props) {
    return (props.trigger) ? (
        <div className='outSideTermsAndConditionsWindow'>
            <div className='termsAndConditionsWindow-Body slide-in-bottom'>
                
                <h3 className={"menuTitle"}>{props.title}</h3>
                <br></br>
                { props.children }
            </div>
        </div>
    ) : "";
}

export default TermsAndConditionsWindow;