import React from 'react';
import './SelectNFTMenu.css';


function SelectNFTMenu(props) {
    return (props.trigger) ? (
        <div className='selectNFTMenu'>
            <div className='selectNFTMenu-Body slide-in-bottom' >
                <button className='close-btn' onClick={() => props.setTrigger(false)}>  X  </button>
                <h3 className={"menuTitle"}>Select an NFT from Wallet</h3>
                <br></br>
                { props.children }
            </div>
        </div>
    ) : "";
}

export default SelectNFTMenu;