import React from "react";
import "./HeaderComponent.css"
import logo from "../Logo.png";
//import Wallet from "./localPackages/@harmonicpool/cardano-wallet-interface/src/Wallet/index.js";
//import * as WASM from '@emurgo/cardano-serialization-lib-browser';
import {C} from 'lucid-cardano';
//import {Buffer} from "buffer";
import initCardanoDAppConnectorBridge from "./cardano-dapp-connector-bridge.js";

import { useState, useEffect } from "react";
import { useCookies } from 'react-cookie';

import WalletMenu from "./WalletsMenu.js";
import WalletDropDown from "./WalletDropDown.js";
import WarningWindow from "./WarningWindow.js";
import BufferWindow from "./BufferWindow.js";
import loadingIcon from "../Loading.gif";

import TokensMenu from "./TokensMenu.js";
import SectionsMenu from './SectionsMenu.js';

import uglyTokenIcon from "../ugly-token.png";

import stakingImg from '../stakingImg.png';
import rafflesImg from '../rafflesImg.png';
import ATMImg from '../ATMImg.png'

//const WASM = C;
let Wallet;
let WalletInterface;
let currentRewardAddress="No wallet";
let activatedRewardAddress="No wallet";
//let selectedDashboard = "staking";

let useClickOutsite = (handler,elementClass) => {

    useEffect(() => {
        let maybeHandler = (event) => {
                if(event.target.className.toString()==elementClass.toString()){
                    handler();
                }
        };
        document.addEventListener("mousedown", maybeHandler);
           
        return () => {
            document.removeEventListener("mousedown", maybeHandler);
        };
    });
}


async function getWalletAddress(walletType)  {
    const WalletInterface = await getWalletInterface(walletType);
    let walletAddress="";
    const usedAddresses = await WalletInterface.getUsedAddresses();
    let changeAddress = C.Address.from_bytes(Buffer.from(usedAddresses[0],"hex"));
    let addressPrefix = "";
    await WalletInterface.getNetworkId() == 1 ? addressPrefix="addr" : addressPrefix = "addr_test" ;
    walletAddress = changeAddress.to_bech32(addressPrefix);

    return walletAddress;
}

function getWalletIcon(walletType){

    
    switch(walletType.toLowerCase()){
        case "eternl":
            return window.cardano.eternl.icon;
        case "nami":
            return window.cardano.nami.icon;  
        case "flint":
            return window.cardano.flint.icon;
        case "gero":
            return window.cardano.gero.icon; 
    }

}

async function getWalletInterface(walletType) {
    

    switch(walletType.toLowerCase()){
        case "eternl":
            return await window.cardano.eternl.enable();   
        case "nami":
            return await window.cardano.nami.enable();
        case "flint":
            return await window.cardano.flint.enable();
        case "gero":
            return await window.cardano.gero.enable();   
    }

}


function isWalletInstalled(walletName){
    switch(walletName.toLowerCase()){
        case "eternl":
            
            if(window.cardano.eternl?.name != undefined)
                return true;
            else
                return false;
        case "nami":
            
            if(window.cardano.nami?.name != undefined)
                return true;
            else
                return false;
        case "flint":
            
            if(window.cardano.flint?.name != undefined)
                return true;
            else
                return false; 
        case "gero":
            
            if(window.cardano.gero?.name != undefined)
                return true;
            else
                return false;            
    }
}

async function isWalletEnabled(walletName){
    switch(walletName.toLowerCase()){
        case "eternl":
            
            return await window.cardano.eternl.isEnabled();
        case "nami":
            
            return await window.cardano.nami.isEnabled();
        case "flint":
            
            return await window.cardano.flint.isEnabled();
        case "gero":
            
            return await window.cardano.gero.isEnabled();          
    }
}

async function checkWalletForWhitelist(walletType,setMintAddress,setBufferWindowTrigger,setWarningWindowMsg,setWarningWindowTrigger){

        setBufferWindowTrigger(true);
        //quick fix for ccvault <-> Eternl
        if(walletType=="Eternl")
            walletType = "ccvault";
        //get stake address of wallet
        const WalletRawInterface = await getWalletInterface(walletType);
        const rewardAddresses = await WalletRawInterface.getRewardAddresses();
        let rewardAddress = C.Address.from_bytes(Buffer.from(rewardAddresses[0],"hex"));
        let stakeAddress = rewardAddress.to_bech32("stake");
        //console.log("stake addr: "+stakeAddress);
        //
   
        const availableMintsQuery = await fetch("https://shroomcoins.io/wp-content/checkWhitelistToMint.php?stakeAddress="+stakeAddress+"&table=WTDWhitelist", {cache: "no-store"});
        const availableMintsString = await availableMintsQuery.text();
        //console.log(availableMintsString);
        setMintAddress(availableMintsString.split(":")[2]);
        let  availableMints = parseInt(availableMintsString.split(":")[1]);
        let isWalletWhitelisted=false;
        if (availableMintsString.split(":")[2]=="OE"){ // legacy condition: if (isNaN(availableMints))
            setWarningWindowMsg("Not allowed to mint. This is not a whitelisted wallet.");
            setWarningWindowTrigger(true);
            setBufferWindowTrigger(false);
        }
        else if(availableMintsString.split(":")[2].substring(0,4)=="addr"){
            setWarningWindowMsg("Welcome, this wallet is whitelisted. "+availableMints);
            setWarningWindowTrigger(true);
            setBufferWindowTrigger(false);
            isWalletWhitelisted=true;
        }
        else{
            setWarningWindowMsg("Could not connect to servers due to high traffic.");
            setWarningWindowTrigger(true);
            setBufferWindowTrigger(false);
        } 
        
        return isWalletWhitelisted;
}



function includeWalletConnectIfAvailable(walletType,setBufferWindowTrigger,setConnectButton,setActiveWallet,setActivatedWalletName,setActivatedWalletAddress,setActivatedWalletIcon,setActivatedWalletType,isWalletFound,setIsWalletFound,setIsEternlMobile,setCookie) {
    
    if(window.cardano == undefined)
    return;

    async function setActiveWalletDetails(walletType){
        setActiveWallet("Connecting wallet...");
        setBufferWindowTrigger(true);
        setActivatedWalletType(walletType);
        let walletAddress = await getWalletAddress(walletType);
        if(walletType.toString()=="ccvault"){
            setActiveWallet("Eternl - "+walletAddress.substring(0,12));
            setActivatedWalletName("Eternl");
        }
        else if(walletType.toString()=="Flint Wallet"){
            setActiveWallet("Flint - "+walletAddress.substring(0,12));
            setActivatedWalletName(walletType.toString());
        }
        else if(walletType.toString()=="GeroWallet"){
            setActiveWallet("Gero - "+walletAddress.substring(0,12));
            setActivatedWalletName(walletType.toString());
        }
        else{
            setActiveWallet(walletType.toString()+" - "+walletAddress.substring(0,12));
            setActivatedWalletName(walletType.toString());
        }
        
        setActivatedWalletAddress(walletAddress.substring(0,10)+"...."+walletAddress.slice(-10));
        setActivatedWalletIcon(getWalletIcon(walletType));
        let WalletInterface = await getWalletInterface(walletType);
        let rewardAddresses = await WalletInterface.getRewardAddresses();
        currentRewardAddress = C.Address.from_bytes(Buffer.from( rewardAddresses[0],"hex")).to_bech32("stake");
        
        setBufferWindowTrigger(false);
    } 

    async function onClickWrapper(){
        setConnectButton(false);
        
        if(isWalletEnabled(walletType)){
            //await Wallet.enable(walletType);
            setActiveWalletDetails(walletType);
            setCookie('last-connected-wallet', walletType, { path: '/' });
            //onWalletChange(setConnectedWalletName,walletType.toString());
            document.getElementById("selectedWallet").value =walletType.toString();
            document.getElementById('selectedWallet').dispatchEvent(new Event('change', { bubbles: true }));
            setIsEternlMobile("false");
        }
        else{
            try{
                //await Wallet.enable(Wallet.Names.Nami);
                await getWalletInterface(walletType);
                setActiveWalletDetails(walletType);
                setCookie('last-connected-wallet', walletType, { path: '/' });
                //onWalletChange(setConnectedWalletName,walletType.toString());
                document.getElementById("selectedWallet").value=walletType.toString();
                document.getElementById('selectedWallet').dispatchEvent(new Event('change', { bubbles: true }));
                setIsEternlMobile("false");
            }
            catch{
                alert("User declined action.");
            }
        }
        
    }

    async function onClickWrapperEternlMobile(){
        
        setConnectButton(false);
        
        
        
        initCardanoDAppConnectorBridge(async () => {
            if(isWalletEnabled("Eternl")){
                setIsEternlMobile("true");
                setActiveWalletDetails("Eternl");
                
                //console.log("eternl mobile connected");
                //console.log(document.getElementById('isEternlMobile').innerHTML);
            }
            else{
                try{
                    setIsEternlMobile("true");
                    await Wallet.enable("Eternl");
                        
                    setActiveWalletDetails("Eternl");
                    
                    //console.log("eternl mobile connected");
                    //console.log(document.getElementById('isEternlMobile').innerHTML);
                }
                catch{
                    alert("User declined action.");
                }
            }
        });
        setBufferWindowTrigger(false);

    }

    if(isWalletInstalled(walletType)){
        if(!isWalletFound){
            setIsWalletFound(true);
        }
        let walletIcon = getWalletIcon(walletType);
        return (
            <div>
                <button className={"connectWalletButton horizontal-center"} onClick={async () => await onClickWrapper()}><img src={walletIcon} width={30} height="30" style={{float:"left"}}/><div style={{lineHeight:"25px",position:"absolute",marginLeft:"auto",width:"100%"}}>{walletType.toString()}</div></button>
                <br></br>
            </div>
        );
    }
    else if(walletType=="Eternl" ){
        let isEternlMobileAviable=false;
        initCardanoDAppConnectorBridge(async () => { 
            if(isWalletInstalled("Eternl")){
                isEternlMobileAviable=true;
                if(!isWalletFound){
                    setIsWalletFound(true);
                }
            }
        })
        if(isEternlMobileAviable)
            return (
                <div>
                    <button className={"connectWalletButton horizontal-center"} onClick={async () => await onClickWrapperEternlMobile}>Eternl Wallet</button>
                    <br></br>
                </div>
            );
    }
}

async function changeSelectedDashboard(dashboard){
    
    document.getElementById("selectedDashboard").value=dashboard;
    document.getElementById('selectedDashboard').dispatchEvent(new Event('change', { bubbles: true }));
}

async function changeSelectedToken(tokenName){
    
    document.getElementById("selectedTokenElement").value=tokenName;
    document.getElementById('selectedTokenElement').dispatchEvent(new Event('change', { bubbles: true }));
}


async function getActivatedWalletBalance(activatedWalletType,setActivatedWalletBalance){

    const WalletRawInterface = await getWalletInterface(activatedWalletType);
    const cborBalance = await WalletRawInterface.getBalance();
    const valueBalance = C.Value.from_bytes(Buffer.from(cborBalance,"hex"));
    let adaAmountString = "";
    let lovelaceAmountString = valueBalance.coin().to_str();
    let adaAmountInt = parseInt(lovelaceAmountString.substring(0,lovelaceAmountString.length-6));
    
    if(adaAmountInt<1000)
        adaAmountString=adaAmountInt;
    else if(adaAmountInt<1000000){
        adaAmountInt=adaAmountInt/1000;
        adaAmountString=adaAmountInt+"K";
    }else if(adaAmountInt<1000000000){
        adaAmountInt=adaAmountInt/1000000;
        adaAmountString=adaAmountInt+"M";
    }else{
        adaAmountInt=adaAmountInt/1000000000;
        adaAmountString=adaAmountInt+"B";
    }
    
    setActivatedWalletBalance(adaAmountString);
}

async function setActiveWalletDetails(walletType,setActiveWallet,setBufferWindowTrigger,setActivatedWalletType,setActivatedWalletName,setActivatedWalletAddress,setActivatedWalletIcon){
    setActiveWallet("Connecting wallet...");
    setBufferWindowTrigger(true);
    setActivatedWalletType(walletType);
    let walletAddress = await getWalletAddress(walletType);
    if(walletType.toString()=="ccvault"){
        setActiveWallet("Eternl - "+walletAddress.substring(0,12));
        setActivatedWalletName("Eternl");
    }
    else if(walletType.toString()=="Flint Wallet"){
        setActiveWallet("Flint - "+walletAddress.substring(0,12));
        setActivatedWalletName(walletType.toString());
    }
    else if(walletType.toString()=="GeroWallet"){
        setActiveWallet("Gero - "+walletAddress.substring(0,12));
        setActivatedWalletName(walletType.toString());
    }
    else{
        setActiveWallet(walletType.toString()+" - "+walletAddress.substring(0,12));
        setActivatedWalletName(walletType.toString());
    }
    
    setActivatedWalletAddress(walletAddress.substring(0,10)+"...."+walletAddress.slice(-10));
    setActivatedWalletIcon(getWalletIcon(walletType));
    let WalletInterface = await getWalletInterface(walletType);
    let rewardAddresses = await WalletInterface.getRewardAddresses();
    currentRewardAddress = C.Address.from_bytes(Buffer.from( rewardAddresses[0],"hex")).to_bech32("stake");
    
    setBufferWindowTrigger(false);
} 


async function connectOrChangeWallet(setConnectButton,setWalletDropDownButton,activatedWallet,activatedWalletType,setActivatedWalletBalance,cookies,setCookie,setActiveWallet,setBufferWindowTrigger,setActivatedWalletType,setActivatedWalletName,setActivatedWalletAddress,setActivatedWalletIcon){
    if(activatedWallet=="Connect wallet"){
        if(cookies['last-connected-wallet'] == undefined)
            setConnectButton(true);
        else{
            setActiveWalletDetails(cookies['last-connected-wallet'],setActiveWallet,setBufferWindowTrigger,setActivatedWalletType,setActivatedWalletName,setActivatedWalletAddress,setActivatedWalletIcon);
            setCookie('last-connected-wallet', cookies['last-connected-wallet'], { path: '/' });
            //onWalletChange(setConnectedWalletName,walletType.toString());
            document.getElementById("selectedWallet").value =cookies['last-connected-wallet'].toString();
            document.getElementById('selectedWallet').dispatchEvent(new Event('change', { bubbles: true }));
        }
    }
    else{
        setWalletDropDownButton(true);
        getActivatedWalletBalance(activatedWalletType,setActivatedWalletBalance);
    }
}

function checkIfNoWalletsFound(isWalletFound){
    if(window.innerWidth>800 && !isWalletFound)
        return(
            <div className="normalText">This browser has no Cardano wallets extensions.</div>
        ); 
    else if(!isWalletFound)
        return(
            <div className="normalText">Please enter the dApp from a mobile wallet application.</div>
        );
}


function Header(){
    

    const [connectButton, setConnectButton] = useState(false);
    const [walletDropDownButton, setWalletDropDownButton] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['last-connected-wallet']);

    const [activatedWalletType,setActivatedWalletType] = useState(null);
    const [activatedWalletName, setActivatedWalletName] = useState("No wallet");
    const [mintAddress, setMintAddress] = useState("Shrooms");
    const [activatedWalletAddress, setActivatedWalletAddress] = useState("No Address");
    const [activatedWallet, setActiveWallet] = useState("Connect wallet");
    const [activatedWalletIcon, setActivatedWalletIcon] = useState("");
    const [activetedWalletBalance,setActivatedWalletBalance] = useState("0");
    
    const [isWalletFound, setIsWalletFound]=useState(false);
    const [isEternlMobile,setIsEternlMobile]=useState("false");


    const [warningWindowTrigger, setWarningWindowTrigger] = useState(false);
    const [warningWindowMsg, setWarningWindowMsg] = useState("Warning Message");
    const [bufferWindowTrigger, setBufferWindowTrigger] = useState(false);

    const [selectedDashboard,setSelectedDashboard] = useState("");
    const [dashboardTitle,setDashBoardTitle] = useState("")

    const [selectSectionTrigger,setSelectSectionTrigger]=useState(false);

    const [selectTokenTrigger,setSelectTokenTrigger] =useState(false);
    const [selectedToken,setSelectedToken] = useState("Shroom");
    const [selectTokenIcon,setSelectedTokenIcon] = useState(logo);



    let menuRef = useClickOutsite(() => setConnectButton(false),"walletsMenu");
    let dropdownRef = useClickOutsite(() => setWalletDropDownButton(false),"outSideDropDown");
    let tokenMenuRef = useClickOutsite(() => setSelectTokenTrigger(false),"tokensMenu");
    let sectionMenuRef = useClickOutsite(() => setSelectSectionTrigger(false),"sectionsMenu");


    useEffect(() => {

        //changeSelectedDashboard('staking');
        const queryParams = new URLSearchParams(window.location.search);
        const raffleTx = queryParams.get('raffle');
        if(raffleTx != null){
            setSelectedDashboard("raffles");
            setDashBoardTitle("Raffles");
            changeSelectedDashboard('raffles');
            
        }
        if(cookies["last-connected-wallet"]!=undefined && activatedWalletName == "No wallet")
            connectOrChangeWallet(setConnectButton,setWalletDropDownButton,activatedWallet,activatedWalletType,setActivatedWalletBalance,cookies,setCookie,setActiveWallet,setBufferWindowTrigger,setActivatedWalletType,setActivatedWalletName,setActivatedWalletAddress,setActivatedWalletIcon);

        const interval = setInterval(async () => {

              if(document.getElementById('selectedWallet').value != "No wallet"){
                let rewardAddresses;
                try{
                    rewardAddresses = await WalletInterface.getRewardAddresses();
                }catch{
                    WalletInterface = await getWalletInterface(document.getElementById('selectedWallet').value);
                    rewardAddresses = await WalletInterface.getRewardAddresses();
                }
                
                  //const WalletInterface = await getWalletInterface(document.getElementById('connectedWallet').innerHTML);
                  //let rewardAddresses = await WalletInterface.getRewardAddresses();
                  currentRewardAddress = C.Address.from_bytes(Buffer.from(rewardAddresses[0],"hex")).to_bech32("stake");
                  if(currentRewardAddress != activatedRewardAddress){
                    activatedRewardAddress=currentRewardAddress;
                    let walletAddress = C.Address.from_bytes(Buffer.from((await WalletInterface.getUsedAddresses())[0],'hex')).to_bech32("addr");
                    let walletName = document.getElementById('selectedWallet').value=="Flint Wallet"?"Flint":document.getElementById('selectedWallet').value
                    setActiveWallet(walletName+" - "+walletAddress.substring(0,12));
                    setActivatedWalletAddress(walletAddress.substring(0,10)+"...."+walletAddress.slice(-10));
                  }
              }
          }
          , 5000);
        return () => {
          clearInterval(interval);
        };
      }, []);



    return(
        <React.Fragment>
            {selectedDashboard==""?
            <div className="mainMenuPage">
                <div className="platformSelectTitle">Select Platform</div>
                <div className="platformMenu">
                    <div className="platformSelectButton" onClick={async () => {setSelectedDashboard('staking');setDashBoardTitle('Staking');changeSelectedDashboard('staking')}}>
                        <img src={stakingImg} height="300"/>
                        <div className="platformSelectText">Staking</div>
                    </div>
                    <div className="platformSelectButton"onClick={async () => {setSelectedDashboard('rafflles');setDashBoardTitle('Raffles');changeSelectedDashboard('raffles')}}>
                        <img src={rafflesImg} height="300"/>
                        <div className="platformSelectText">Raffles</div>
                    </div>
                    <div className="platformSelectButton"onClick={async () => {setSelectedDashboard('ATM');setDashBoardTitle('ATM');changeSelectedDashboard('ATM')}}>
                        <img src={ATMImg} height="300"/>
                        <div className="platformSelectText">Shroom ATM</div>
                    </div>
                </div>
            </div>
            :
            <div className="header-container">
                <a className="normalLink" href="https://shrooms.site"><img src={logo} className="siteLogo" width={120} height="120"></img></a>
                <div className="switchDashboard" style={{display:'block'}} onClick={() => setSelectSectionTrigger(true)}>Select platform</div>
                <div className="tokenSelect" style={selectedDashboard=="staking"?{ display:'block'}:{display:'none'}} onClick={() => {if(selectedDashboard=="staking") setSelectTokenTrigger(true)}}>Token: <img src={selectTokenIcon} width={30} height="30"/>{selectedToken}</div>
                <div className="siteTitle" >{dashboardTitle}</div>
                <div id="connectedWallet" style={{visibility:"hidden"}}>{activatedWalletName}</div>
                <div id="selectedToken" style={{visibility:"hidden"}}>{selectedToken}</div>
                <div id="mintAddress" style={{visibility:"hidden"}}>{mintAddress}</div>
                
                <button onClick={async () =>await connectOrChangeWallet(setConnectButton,setWalletDropDownButton,activatedWallet,activatedWalletType,setActivatedWalletBalance,cookies,setCookie,setActiveWallet,setBufferWindowTrigger,setActivatedWalletType,setActivatedWalletName,setActivatedWalletAddress,setActivatedWalletIcon)} className="connectBtn" id="connectBtn">{activatedWallet}</button>
                
                <WalletMenu trigger={connectButton} setTrigger={setConnectButton} ref={menuRef}>
                    <div>
                    <h3 className={"menuTitle  horizontal-center"}>Select Wallet</h3>
                    <br></br>
                    {includeWalletConnectIfAvailable("Eternl",setBufferWindowTrigger,setConnectButton,setActiveWallet,setActivatedWalletName,setActivatedWalletAddress,setActivatedWalletIcon,setActivatedWalletType,isWalletFound,setIsWalletFound,setIsEternlMobile,setCookie)}
                    {includeWalletConnectIfAvailable("Nami",setBufferWindowTrigger,setConnectButton,setActiveWallet,setActivatedWalletName,setActivatedWalletAddress,setActivatedWalletIcon,setActivatedWalletType,isWalletFound,setIsWalletFound,setIsEternlMobile,setCookie)}
                    {includeWalletConnectIfAvailable("Flint",setBufferWindowTrigger,setConnectButton,setActiveWallet,setActivatedWalletName,setActivatedWalletAddress,setActivatedWalletIcon,setActivatedWalletType,isWalletFound,setIsWalletFound,setIsEternlMobile,setCookie)}
                    {includeWalletConnectIfAvailable("Gero",setBufferWindowTrigger,setConnectButton,setActiveWallet,setActivatedWalletName,setActivatedWalletAddress,setActivatedWalletIcon,setActivatedWalletType,isWalletFound,setIsWalletFound,setIsEternlMobile,setCookie)}
                    {checkIfNoWalletsFound(isWalletFound)}
                    </div>
                </WalletMenu>
                <WalletDropDown trigger={walletDropDownButton} setTrigger={setWalletDropDownButton} ref={dropdownRef}>
                    <div>
                        <div>
                            <img src={activatedWalletIcon} className="walletIcon" width={60} height="60"></img>
                            <div className="walletNameFont">{activatedWalletName}</div>
                            <div className="addressFont">{activatedWalletAddress}</div>
                        </div>
                        <br/>
                        <hr className="horizontalLine"/>
                        <div className="walletAssetArea"><div>Cardano <div className="walletAssetQuantity">{activetedWalletBalance} ADA</div></div></div>
                        <br/>
                        <br/>
                    <button onClick={() => {setWalletDropDownButton(false);setConnectButton(true)}} className="changeWalletButton">Change wallet</button>
                    </div>
                    
                </WalletDropDown>
                <SectionsMenu trigger={selectSectionTrigger} setTrigger={setSelectSectionTrigger} ref={sectionMenuRef}>
                <div>
                    <h3 className={"menuTitle  horizontal-center"}>Select Platform</h3>
                    <br></br>
                    <div>
                        <button className="selectSectionButton horizontal-center" onClick={async () => {setSelectedDashboard('staking');setDashBoardTitle('Staking');changeSelectedDashboard('staking');setSelectSectionTrigger(false)}}>Staking</button>
                        <br/>
                    </div>
                    <div>
                        <button className="selectSectionButton horizontal-center" onClick={async () => {setSelectedDashboard('raffles');setDashBoardTitle('Raffles');changeSelectedDashboard('raffles');setSelectSectionTrigger(false)}}>Raffles</button>
                        <br/>
                    </div>
                    <div>
                        <button className="selectSectionButton horizontal-center" onClick={async () => {setSelectedDashboard('ATM');setDashBoardTitle('ATM');changeSelectedDashboard('ATM');setSelectSectionTrigger(false)}}>ATM</button>
                        <br/>
                    </div>
                </div>
                </SectionsMenu>
                <TokensMenu trigger={selectTokenTrigger} setTrigger={setSelectTokenTrigger} ref={tokenMenuRef}>
                <div>
                    <h3 className={"menuTitle  horizontal-center"}>Select Token</h3>
                    <br></br>
                    <div>
                        <button className="selectTokenButton horizontal-center" onClick={async () => {setSelectedToken("Shroom");setSelectedTokenIcon(logo);await changeSelectedToken("Shroom");setSelectTokenTrigger(false)}}><img src={logo} width={50} height="50" style={{float:"left"}}/><div style={{lineHeight:"45px",position:"absolute",marginLeft:"auto",width:"100%"}}>Shroom Coins</div></button>
                        <br/>
                    </div>
                    <div>
                        <button className="selectTokenButton horizontal-center" onClick={async () => {setSelectedToken("UGLY");setSelectedTokenIcon(uglyTokenIcon);await changeSelectedToken("UGLY");setSelectTokenTrigger(false)}}><img src={uglyTokenIcon} width={50} height="50" style={{float:"left"}}/><div style={{lineHeight:"45px",position:"absolute",marginLeft:"auto",width:"100%"}}>Ugly Tokens</div></button>
                        <br/>
                    </div>
                </div>
                </TokensMenu>
                <BufferWindow trigger={bufferWindowTrigger} setTrigger={setBufferWindowTrigger} >
                    <img src={loadingIcon} width="50px" height={"50px"} className="bufferIcon"/>
                    <div className="msgFont">Connecting wallet...</div>
                </BufferWindow>
                <WarningWindow trigger={warningWindowTrigger} setTrigger={setWarningWindowTrigger}>
                    <br/>
                    <div className="warningMsgFont">{warningWindowMsg}</div>
                    { warningWindowMsg=="Could not connect to servers due to high traffic."?
                    <button className='warningOkButton' onClick={() => {setWarningWindowTrigger(false);checkWalletForWhitelist(activatedWalletType,setMintAddress,setBufferWindowTrigger,setWarningWindowMsg,setWarningWindowTrigger)}}> Retry </button>    
                    :
                    <button className='warningOkButton' onClick={() => setWarningWindowTrigger(false)}> OK </button> }           
                </WarningWindow>
            </div>
            }
        </React.Fragment>
        
    )
}

export default Header;
