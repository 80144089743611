import React from 'react';
import './BufferWindow.css';

function BufferWindow(props) {
    return (props.trigger) ? (
        <div className='outSideBufferWindow'>
            <div className='mainBufferWindow-Body slide-in-fwd-center'>
                { props.children }
            </div>
        </div>
    ) : "";
}

export default BufferWindow;