import React from 'react';
import './PopupWindow.css';

function PopupWindow(props) {
    return (props.trigger) ? (
            <div className="outSidePopupWindow">
            <div className='popupWindow-Body slide-in-fwd-center'>
                { props.children }
                
            </div>
            </div>
    ) : "";
}

export default PopupWindow;