import React from "react";
//import * as WASM from '@emurgo/cardano-serialization-lib-browser';
//import {Lucid, C,SLOT_CONFIG_NETWORK, createCostModels} from 'lucid-cardano';
import {Lucid, C, Blockfrost, Assets} from 'lucid-cardano';

//import Wallet from "./localPackages/@harmonicpool/cardano-wallet-interface/src/Wallet/index.js";
//import {Buffer} from "buffer";
//import * as process from 'process';
import { AMQPWebSocketClient } from '@cloudamqp/amqp-client';
import ProgressBar from './ProgressBar.js';


import cardanoLogo from "../cardanoLogo.png";
import backgroundImg from  "../background.jpg";
import mintSuccessfulImg from "../mintSuccessful.png";
import uglyClaimSuccessfulImg from "../UGLY-incoming.gif";
import registrationSuccessfulImg from "../registrationSuccessful.png";
import systemMaintinanceImg from "../systemMaintinanceImg.png"
import loadingIcon from "../Loading.gif";
import refreshIcon from "../refresh-icon.png";
import shroomsLogo from "../Shrooms Logo.ico";
import filterIcon from '../filter-icon.png';
import infoIcon from '../info_icon.png';
import searchIcon from '../search-icon.svg';
import fungiImage from '../Fungi.png';
import logo from "../Logo.png";
import uglyTokenIcon from "../ugly-token.png";
import poolpmLogo from "../poolpm-logo.svg";
import jpgStoreLogo from "../jpg-store-logo.svg";
import plutusArtLogo from "../plutus-art-logo.png";
import "./HomeComponent.css";
import PopupWindow from "./PopupWindow.js";
import BufferWindow from "./BufferWindow.js";
import SuccessWindow from "./SuccessWindow.js";
import SelectNFTMenu from './SelectNFTMenu';
import RaffleParametersWindow from './RaffleParametersWindow';
import TermsAndConditionsWindow from './TermsAndConditionsWindow';
import PriceTableWindow from "./PriceTableWindow";

import walletIcon from "../walletIcon.png";
import stakingIcon from "../stakingIcon.png";
import walletNFTsIcon from '../wallets-nfts-icon.png';


import freddieTheWhaleIcon from '../freddie-the-whale-icon.png';
import jarHeadsIcon from '../jar-heads-icon.png';
import gnomiesIcon from '../gnomies-icon.png';
import uglyBrosIcon from '../ugly-bros-icon.png';
import whatTheDuckIcon from '../what-the-duck-icon.png';
import ziProjectIcon from '../zi-project-icon.png'
import overExposedIcon from '../over-exposed-icon.png';
import nekomimiIcon from '../nekomimi-icon.png';

import uglyBrosS1Icon from '../ugly-bros-s1.png';
import uglyBrosCommunityIcon from '../ugly-bros-community.png';
import uglyBrosXmasIcon from '../ugly-bros-xmas.png';
import uglyBrosS2Icon from '../ugly-bros-s2.png';


import { useState, useEffect } from "react";
import { useCookies } from 'react-cookie';
import WarningWindow from "./WarningWindow.js";
import ProjectNFTsViewer from "./ProjectNFTsViewerContainer.js";
import TokensMenu from "./TokensMenu.js";





const uglyBrosCollectionsDictionary = {
    'UglyBrosS1' : uglyBrosS1Icon,
    'UglyBrosXmas' : uglyBrosXmasIcon,
    'UglyBrosCommunity' : uglyBrosCommunityIcon,
    'UglyBrosS2' : uglyBrosS2Icon
}

const nftsCountOf = {
    'UglyBrosS1' : 0,
    'UglyBrosXmas' : 0,
    'UglyBrosCommunity' : 0,
    'UglyBrosS2' : 0
}

const collectionBoost = {
    'UglyBrosS1' : 15,
    'UglyBrosXmas' : 5,
    'UglyBrosCommunity' : 1,
    'UglyBrosS2' : 6
}

const projectBaseReward = {
    'UglyBros' : 0.021
}

const raffleNFT = {
    'policy': "",
    'name':"",
    'displayName':"",
    'ipfsHash':""
}

let baseReward = 10000000;
let NFTsMediaReference = [];
let NFTsNameReference = [];
let selectedProjectDisplaySyntax= "";
let nftsInWallet;
let searchedNFTsInWallet;
//window.Buffer = Buffer;
//window['process'] = process;

let WalletInterface;
let assetsInWallet;
const REFRESH_PERIOD_MS = 5000;
let currentRewardAddress = "";
let activatedRewardAddress = "";
let refreshDisabled = false;
const epoch383PosixReference = 1671659092;
const slotsInEpoch = 432000;

let useClickOutsite = (handler,elementClass) => {

    useEffect(() => {
        let maybeHandler = (event) => {
                if(event.target.className.toString()==elementClass.toString()){
                    handler();
                }
        };
        document.addEventListener("mousedown", maybeHandler);

        return () => {
            document.removeEventListener("mousedown", maybeHandler);
        };
        
    });
}

let useClickOutside = (handler,elementClass) => {
    //here it checks if the click is not equal to given class name
    useEffect(() => {
        let maybeHandler = (event) => {
                if(event.target.parentElement?.className.toString()!=elementClass.toString()){
                    handler();
                }
        };
        document.addEventListener("mousedown", maybeHandler);

        return () => {
            document.removeEventListener("mousedown", maybeHandler);
        };
        
    });
}


function getWalletIcon(walletType){

    
    switch(walletType.toLowerCase()){
        case "eternl":
            return window.cardano.eternl.icon;
        case "nami":
            return window.cardano.nami.icon;  
        case "flint":
            return window.cardano.flint.icon;
        case "gero":
            return window.cardano.gero.icon; 
    }

}

async function getWalletInterface(walletType) {
    

    switch(walletType.toLowerCase()){
        case "eternl":
            return await window.cardano.eternl.enable();   
        case "nami":
            return await window.cardano.nami.enable();
        case "flint":
            return await window.cardano.flint.enable();
        case "gero":
            return await window.cardano.gero.enable();   
    }

}


async function createClaimRequest(utxoSelectionStrategy){

    const linearFeeAValue="44";
    const linearFeeBValue="155381";
    const minUTxOValue="1000000";
    const maxTxSizeValue="8000";
    const linearFeeA=C.BigNum.from_str(linearFeeAValue);
    const linearFeeB=C.BigNum.from_str(linearFeeBValue);
    const linearFee = C.LinearFee.new(linearFeeA,linearFeeB);
    
    
    const txBuilderConfig = C.TransactionBuilderConfigBuilder.new()
    .fee_algo(linearFee)
    .pool_deposit(C.BigNum.from_str('500000000'))
    .key_deposit(C.BigNum.from_str('2000000'))
    .max_value_size(5000)
    .max_tx_size(16384)
    .coins_per_utxo_byte(C.BigNum.from_str('4310'))
    .ex_unit_prices(C.ExUnitPrices.from_float(1,1))
    .collateral_percentage(0.05)
    .max_collateral_inputs(2)
    .build();

    const txBuilder = C.TransactionBuilder.new(txBuilderConfig);
    
    //const WalletInterface = await getWalletInterface(document.getElementById("connectedWallet").innerHTML);

    //prepare for eternl cut
    let eternlCutAmount = "1000000";
    //end of eternl cut


    const usedAddresses = await WalletInterface.getUsedAddresses();
    let changeAddress = C.Address.from_bytes(Buffer.from(usedAddresses[0],"hex"));
    
    let paymentAddresses = [];
    paymentAddresses[0]=changeAddress;
    let bigIntsShares = [];
    bigIntsShares[0]="6000000";

    let paymentValues = [];
    
    for(let i=0;i<paymentAddresses.length;i++){
        //console.log(i)
        paymentValues[i]=C.Value.new(C.BigNum.from_str(bigIntsShares[i]));
        txBuilder.add_output(C.TransactionOutput.new(paymentAddresses[i],paymentValues[i]));
        
    }


    const utxosList = await WalletInterface.getUtxos();
    //console.log("getUtxos result: "+utxosList.length)
    let unspentTxOutputs = [];
    
    if (utxosList != undefined)
        unspentTxOutputs = utxosList.map(u => C.TransactionUnspentOutput.from_bytes(Buffer.from(u, 'hex' )));
    else{
        return;
    }
    let transactionUnspentOutputs=C.TransactionUnspentOutputs.new();
    unspentTxOutputs.forEach(utxo => {
        transactionUnspentOutputs.add(utxo);
    });

    let txBody;
    
    //let newTx = lucid.newTx().complete();
    //lucid.selectWallet(WalletInterface);
   
    //txBuilder.add_inputs_from(transactionUnspentOutputs,changeAddress);
    
    //txBuilder.add_change_if_needed(changeAddress);
    //txBody = txBuilder.build_tx().body();
    
    
    //txBody = txBuilder.build();
    try{
        txBuilder.add_inputs_from(transactionUnspentOutputs,changeAddress);
        //txBuilder.add_inputs_from(transactionUnspentOutputs,utxoSelectionStrategy);
        //txBuilder.add_change_if_needed(changeAddress);
        txBuilder.balance(changeAddress);
        //txBody = txBuilder.build();
        let transaction =  txBuilder.build_tx();
        txBody = transaction.body();
        
    }
    catch{
        return "unsuff funds"; 
    }

    

        

    //console.log("number of inputs: "+txBody.inputs().len());
    let utxos = [];
    for(let i=0;i<txBody.inputs().len();i++){
        let txID=Buffer.from(txBody.inputs().get(i).transaction_id().to_bytes()).toString('hex');
        let txIx=txBody.inputs().get(i).index().to_str();
        //console.log(txID+"#"+txIx);
        utxos[i]=txID+"#"+txIx;
    }
    
    //console.log("number of outputs: "+txBody.outputs().len());
    let outputs = [];
    let address,lovelace,policyID,policyIDString,assetsOfPolicyID,assetName,assetAmount,assetNameInHex,outputAsString,assetsAsString;
    //let assets = [];
    for(let i=0;i<txBody.outputs().len();i++){
        address = txBody.outputs().get(i).address().to_bech32("addr");
        if(i==(txBody.outputs().len()-1))
            lovelace = txBody.outputs().get(i).amount().coin().checked_add(txBody.fee()).to_str();
        else
            lovelace = txBody.outputs().get(i).amount().coin().to_str();
        let assets =[];
        if(txBody.outputs().get(i).amount().multiasset()!=undefined){
            //console.log("number of unique policy ids in output: "+txBody.outputs().get(i).amount().multiasset().keys().len())
            for(let j=0;j<txBody.outputs().get(i).amount().multiasset().keys().len();j++){
                policyID = txBody.outputs().get(i).amount().multiasset().keys().get(j);
                policyIDString = Buffer.from(txBody.outputs().get(i).amount().multiasset().keys().get(j).to_bytes()).toString('hex');
                assetsOfPolicyID = txBody.outputs().get(i).amount().multiasset().get(policyID);
                //console.log("number of unique assets under current policy id: "+assetsOfPolicyID.len())
                for(let k=0;k<assetsOfPolicyID.len();k++){
                    assetName = assetsOfPolicyID.keys().get(k);
                    //console.log("asset name: "+Buffer.from(assetName.name()).toString('hex'))
                    assetAmount = assetsOfPolicyID.get(assetName).to_str();
                    assetNameInHex=Buffer.from(assetName.name()).toString('hex');
                    assets.push(assetAmount+" "+policyIDString+"."+assetNameInHex);
                }
            }
        }
        outputAsString=address+"++"+lovelace+"++";
        //console.log("asset string: "+assets)*/
        for(let l=0;l<assets.length;l++){
            if(l==0)
                outputAsString=outputAsString+"\"";
            outputAsString=outputAsString+assets[l];
            
            if(l!=(assets.length-1))
                outputAsString=outputAsString+" + ";
            else
                outputAsString=outputAsString+"\"";
        }
        //console.log(assets);
        //console.log(outputAsString);
        outputs[i]=outputAsString.split("++");
        //console.log("output hex index "+i+" "+txBody.outputs().get(i).to_hex())
    }
    //console.log(outputs);
    let utxo1 = Buffer.from(txBody.inputs().get(0).transaction_id().to_bytes()).toString('hex')+"#"+txBody.inputs().get(0).index();
    let sender = "";
    for(let i=0;i<unspentTxOutputs.length;i++){
        let utxo2 = Buffer.from(unspentTxOutputs[i].input().transaction_id().to_bytes()).toString('hex')+"#"+unspentTxOutputs[i].input().index();
        if(utxo1==utxo2){
            sender = unspentTxOutputs[i].output().address().to_bech32("addr");
            break;
        }
    }
    
    let requestMsg=buildClaimRequestMsg(sender,utxos,outputs);
    //console.log(requestMsg);
    return requestMsg; 
}

function buildClaimRequestMsg(sender,utxos,outputs){
    let msgBody="{\"sender\":\""+sender+"\",\"utxos\":[";
    for(let i=0;i<utxos.length;i++){
        msgBody=msgBody+"\""+utxos[i]+"\"";
        if(i==(utxos.length-1))
            msgBody=msgBody+"],";
        else
            msgBody=msgBody+",";
    }
    msgBody=msgBody+"\"outputs\":[";
    for(let i=0;i<outputs.length;i++){
        
        if(outputs[i][2].length==0)
            msgBody=msgBody+"{\"address\":\""+outputs[i][0]+"\",\"lovelace\":\""+outputs[i][1]+"\",\"assets\":\"\"}";
        else
            msgBody=msgBody+"{\"address\":\""+outputs[i][0]+"\",\"lovelace\":\""+outputs[i][1]+"\",\"assets\":"+outputs[i][2]+"}";
        if(i==(outputs.length-1))
            msgBody=msgBody+"]";
        else
            msgBody=msgBody+",";       
    }
    msgBody=msgBody+"}";

    //console.log(msgBody)
    return msgBody;
    
}


async function sendClaimRequest(claimRequestMsg){
    
    
    //console.log(mintRequestMsg);
    var selectedToken = document.getElementById('selectedTokenElement').value;
    let request_queue,answer_queue; 
    switch(selectedToken){
        case 'Shroom':
            request_queue = 'shroom_rewards_claiming_queue';
            answer_queue = 'shroom_rewards_claiming__answer_queue';
        break;
        case 'UGLY':
            request_queue = 'uglies_rewards_claiming_queue';
            answer_queue = 'uglies_rewards_claiming__answer_queue';
        break;
    }
    let claimTxBodyString = await rpc_request(request_queue,answer_queue,claimRequestMsg);
    return claimTxBodyString;
}

async function rpc_request(askQueue,ResponseQueue,requestMsg) {
    var correlationId = generateUuid();
    let responseMsg="no response";
    try {
      
      const amqp = new AMQPWebSocketClient("wss://queue.adalink.io:15674","vhost1","user","suckSomeDick"); 
      const conn = await amqp.connect()
      const ch = await conn.channel()
      const q = await ch.queue(askQueue,{durable:true})
      const qAnswer = await ch.queue(ResponseQueue)
      const consumer = await qAnswer.subscribe({noAck: false}, async (msg) => {
        //console.log(msg.properties.correlationId)
        //console.log(correlationId)
        if(msg.properties.correlationId==correlationId){
            responseMsg = msg.bodyToString()
            //console.log(msg.bodyToString())
            await msg.ack()
            await consumer.cancel()
            
        }
      })
      await q.publish(requestMsg, {deliveryMode: 2,correlationId:correlationId,replyTo:ResponseQueue})
      await consumer.wait() // will block until consumer is canceled or throw an error if server closed channel/connection
      await conn.close()
    } catch (e) {
      //console.error("ERROR", e)
      try{e.connection.close();}
      catch{}
      //setTimeout(rpc_request, 1000) // will try to reconnect in 1s
    }
    return responseMsg;
  }

function generateUuid() {
    return Math.random().toString() +
        Math.random().toString() +
        Math.random().toString();
}

async function createRaffleEntryRequest(raffleTxHash,raffleCoin,entryAmount,entriesCount,utxoSelectionStrategy){

    const linearFeeAValue="44";
    const linearFeeBValue="155381";
    const minUTxOValue="1000000";
    const maxTxSizeValue="8000";
    const linearFeeA=C.BigNum.from_str(linearFeeAValue);
    const linearFeeB=C.BigNum.from_str(linearFeeBValue);
    const linearFee = C.LinearFee.new(linearFeeA,linearFeeB);
    
    

    const txBuilderConfig = C.TransactionBuilderConfigBuilder.new()
    .fee_algo(linearFee)
    .pool_deposit(C.BigNum.from_str('500000000'))
    .key_deposit(C.BigNum.from_str('2000000'))
    .max_value_size(5000)
    .max_tx_size(16384)
    .coins_per_utxo_byte(C.BigNum.from_str('4310'))
    .ex_unit_prices(C.ExUnitPrices.from_float(1,1))
    .collateral_percentage(0.05)
    .max_collateral_inputs(2)
    .build();

    const txBuilder = C.TransactionBuilder.new(txBuilderConfig);
    
    //const WalletInterface = await getWalletInterface(document.getElementById("connectedWallet").innerHTML);

    //prepare for eternl cut
    let eternlCutAmount = "1000000";
    //end of eternl cut


    const usedAddresses = await WalletInterface.getUsedAddresses();
    let changeAddress = C.Address.from_bytes(Buffer.from(usedAddresses[0],"hex"));
    
    let paymentAddresses = [];
   
    
    let bigIntsShares = [];
    let paymentValues = [];

    const MultiAsset = C.MultiAsset.new();
    let assets = C.Assets.new();
    switch(raffleCoin){
        case "ADA":
            for(let i=0;i<parseInt(entriesCount);i++){
                paymentAddresses[i]=changeAddress;
                bigIntsShares[i]=(Math.floor(parseFloat(entryAmount)*1000000)).toString();
                paymentValues[i]=C.Value.new(C.BigNum.from_str(bigIntsShares[0]));
                txBuilder.add_output(C.TransactionOutput.new(paymentAddresses[i],paymentValues[i]));  
            }
        break;
        case "UGLY":
            let uglyPolicyID="95a15fafc24be11478735df6fa4eaba6e6319c27f24ced13f39bea44";
            var policyIDScriptHash = C.ScriptHash.from_bytes(Buffer.from(uglyPolicyID,"hex"));
            var uint8arrayName = new TextEncoder().encode(raffleCoin);
            var uglyName = C.AssetName.new(uint8arrayName);
            var assetCount = (Math.floor(parseInt(entriesCount)*parseFloat(entryAmount)*1000000)).toString();
            assets.insert(uglyName,C.BigNum.from_str(assetCount));
            MultiAsset.insert(policyIDScriptHash,assets);
            paymentAddresses[0]=changeAddress;
            bigIntsShares[0]="3000000";
            //paymentValues[0]=C.Value.new_with_assets(C.BigNum.from_str(bigIntsShares[0]),MultiAsset); C.Value.
            paymentValues[0]=C.Value.new(C.BigNum.from_str(bigIntsShares[0]))
            paymentValues[0].set_multiasset(MultiAsset);
            txBuilder.add_output(C.TransactionOutput.new(paymentAddresses[0],paymentValues[0])); 
        break;
        case "Shroom":
            var uint8arrayName = new TextEncoder().encode(raffleCoin);
            var shroomName = C.AssetName.new(uint8arrayName);
            var assetCount = (parseInt(entriesCount)*parseInt(entryAmount)).toString();
            let shroomsPolicyID="715d89545d6ce3cfca7680b216a3f327ee61d377732431049ed0b240";
            var policyIDScriptHash = C.ScriptHash.from_bytes(Buffer.from(shroomsPolicyID,"hex"));
            assets.insert(shroomName,C.BigNum.from_str(assetCount));
            MultiAsset.insert(policyIDScriptHash,assets);
            paymentAddresses[0]=changeAddress;
            bigIntsShares[0]="2000000";
            //paymentValues[0]=C.Value.new_with_assets(C.BigNum.from_str(bigIntsShares[0]),MultiAsset);
            paymentValues[0]=C.Value.new(C.BigNum.from_str(bigIntsShares[0]))
            paymentValues[0].set_multiasset(MultiAsset);
            txBuilder.add_output(C.TransactionOutput.new(paymentAddresses[0],paymentValues[0])); 

        break;
    }

    switch(raffleCoin){
        case "ADA":
            paymentAddresses[parseInt(entriesCount)]=changeAddress;
            bigIntsShares[parseInt(entriesCount)]="2000000";
            for(let i=parseInt(entriesCount);i<paymentAddresses.length;i++){
                paymentValues[i]=C.Value.new(C.BigNum.from_str(bigIntsShares[i]));
                
                txBuilder.add_output(C.TransactionOutput.new(paymentAddresses[i],paymentValues[i]));  
            }
        break;
        default:

        break;
    }


    const utxosList = await WalletInterface.getUtxos();
    console.log("getUtxos result: "+utxosList.length)
    let unspentTxOutputs = [];
    
    if (utxosList != undefined)
        unspentTxOutputs = utxosList.map(u => C.TransactionUnspentOutput.from_bytes(Buffer.from(u, 'hex' )));
    else{
        return;
    }
    let transactionUnspentOutputs=C.TransactionUnspentOutputs.new();
    unspentTxOutputs.forEach(utxo => {
        transactionUnspentOutputs.add(utxo);
    });

    let txBody;
    
    
    try{
        txBuilder.add_inputs_from(transactionUnspentOutputs,changeAddress);
        //txBuilder.add_inputs_from(transactionUnspentOutputs,utxoSelectionStrategy);
        //txBuilder.add_change_if_needed(changeAddress);
        txBuilder.balance(changeAddress);
        //txBody = txBuilder.build();
        let transaction =  txBuilder.build_tx();
        txBody = transaction.body();

    }
    catch(e){
        //console.log(e)
        //console.log(e.info)
        return "unsuff funds"; 
    }

    let utxos = [];
    for(let i=0;i<txBody.inputs().len();i++){
        let txID=Buffer.from(txBody.inputs().get(i).transaction_id().to_bytes()).toString('hex');
        let txIx=txBody.inputs().get(i).index().to_str();
        console.log(txID+"#"+txIx);
        utxos[i]=txID+"#"+txIx;
    }
    
    let outputs = [];
    let address,lovelace,policyID,policyIDString,assetsOfPolicyID,assetName,assetAmount,assetNameInHex,outputAsString,assetsAsString;
    //let assets = [];
    for(let i=0;i<txBody.outputs().len();i++){
        address = txBody.outputs().get(i).address().to_bech32("addr");
        if(i==(txBody.outputs().len()-1))
            lovelace = txBody.outputs().get(i).amount().coin().checked_add(txBody.fee()).to_str();
        else
            lovelace = txBody.outputs().get(i).amount().coin().to_str();
        let assets =[];
        if(txBody.outputs().get(i).amount().multiasset()!=undefined){
            console.log("number of unique policy ids in output: "+txBody.outputs().get(i).amount().multiasset().keys().len())
            for(let j=0;j<txBody.outputs().get(i).amount().multiasset().keys().len();j++){
                policyID = txBody.outputs().get(i).amount().multiasset().keys().get(j);
                policyIDString = Buffer.from(txBody.outputs().get(i).amount().multiasset().keys().get(j).to_bytes()).toString('hex');
                assetsOfPolicyID = txBody.outputs().get(i).amount().multiasset().get(policyID);
                //console.log("number of unique assets under current policy id: "+assetsOfPolicyID.len())
                for(let k=0;k<assetsOfPolicyID.len();k++){
                    assetName = assetsOfPolicyID.keys().get(k);
                    //console.log("asset name: "+Buffer.from(assetName.name()).toString('hex'))
                    assetAmount = assetsOfPolicyID.get(assetName).to_str();
                    assetNameInHex=Buffer.from(assetName.name()).toString('hex');
                    assets.push(assetAmount+" "+policyIDString+"."+assetNameInHex);
                }
            }
        }
        outputAsString=address+"++"+lovelace+"++";
        console.log("asset string: "+assets)
        for(let l=0;l<assets.length;l++){
            if(l==0)
                outputAsString=outputAsString+"\"";
            outputAsString=outputAsString+assets[l];
            
            if(l!=(assets.length-1))
                outputAsString=outputAsString+" + ";
            else
                outputAsString=outputAsString+"\"";
        }
        //console.log(assets);
        //console.log(outputAsString);
        outputs[i]=outputAsString.split("++");
        //console.log("output hex index "+i+" "+txBody.outputs().get(i).to_hex())
    }
    //console.log(outputs);
    let utxo1 = Buffer.from(txBody.inputs().get(0).transaction_id().to_bytes()).toString('hex')+"#"+txBody.inputs().get(0).index();
    let sender = "";
    for(let i=0;i<unspentTxOutputs.length;i++){
        let utxo2 = Buffer.from(unspentTxOutputs[i].input().transaction_id().to_bytes()).toString('hex')+"#"+unspentTxOutputs[i].input().index();
        if(utxo1==utxo2){
            sender = unspentTxOutputs[i].output().address().to_bech32("addr");
            break;
        }
    }
    
    let requestMsg=buildRaffleEntryRequestMsg(sender,raffleTxHash,entriesCount,utxos,outputs);
    console.log(requestMsg);
    return requestMsg; 
}


function buildRaffleEntryRequestMsg(sender,raffleTxHash,entriesCount,utxos,outputs){
    let msgBody='{"sender":"'+sender+'","raffle":"'+raffleTxHash+'","entries":"'+entriesCount+'","utxos":[';
    for(let i=0;i<utxos.length;i++){
        msgBody=msgBody+'"'+utxos[i]+'"';
        if(i==(utxos.length-1))
            msgBody=msgBody+"],";
        else
            msgBody=msgBody+",";
    }
    msgBody=msgBody+'"outputs":[';
    for(let i=0;i<outputs.length;i++){
        
        if(outputs[i][2].length==0)
            msgBody=msgBody+'{"address":"'+outputs[i][0]+'","lovelace":"'+outputs[i][1]+'","assets":""}';
        else
            msgBody=msgBody+'{"address":"'+outputs[i][0]+'","lovelace":"'+outputs[i][1]+'","assets":'+outputs[i][2]+"}";
        if(i==(outputs.length-1))
            msgBody=msgBody+"]";
        else
            msgBody=msgBody+",";       
    }
    msgBody=msgBody+"}";

    //console.log(msgBody)
    return msgBody;
    
}


function convertADAToLovelace(adaAmount){
     let adaAmountAsString=adaAmount.toString();
     //divide integers from decimal points
     let valueAsArray=adaAmountAsString.split(".");
     let integersPart=valueAsArray[0];
     let decimalsPart=valueAsArray[1];
     if(valueAsArray.length==2){
        if(decimalsPart.length>6)
            decimalsPart=decimalsPart.substr(0,6);
        else if(decimalsPart.length<6)
            decimalsPart=decimalsPart.padEnd(6, '0');
     }
     else
        decimalsPart="000000";

    return integersPart+decimalsPart;
}

function convertLovelaceToADA(lovelaceAmount){

    let integersPart=lovelaceAmount.substr(0,lovelaceAmount.length-6);
    let decimalsPart=lovelaceAmount.slice(-6);
    let adaAsString = integersPart+"."+decimalsPart;
    return parseFloat(adaAsString);
}

async function sendSyncRequest(SyncRequestMsg){
    
    var selectedToken = document.getElementById('selectedTokenElement').value;
    let request_queue,answer_queue; 
    switch(selectedToken){
        case 'Shroom':
            request_queue = 'shroom_rewards_claiming_sync_queue';
            answer_queue = 'shroom_rewards_claiming_sync_answer_queue';
        break;
        case 'UGLY':
            request_queue = 'uglies_rewards_claiming_sync_queue';
            answer_queue = 'uglies_rewards_claiming_sync_answer_queue';
        break;
    }

    let syncRequestFeedbackString = await rpc_request(request_queue,answer_queue,SyncRequestMsg);
    return syncRequestFeedbackString;
    
}


async function claimRewards(activatedWalletUnclaimedRewards,activatedWalletClaimedRewards,setActivatedWalletUnclaimedRewards,setActivatedWalletClaimedRewards,setPopupWindowTrigger,setPopupWindowMsg,setBufferWindowTrigger,setBufferWindowMsg,setSuccessWindowTrigger,setSuccessWindowMsgMode){

    
    //check if wallet connceted
    
    let activatedWalletName = document.getElementById("connectedWallet").innerHTML;
    //console.log("wallet: "+activatedWalletName);
    if(activatedWalletName == "No wallet"){
        setPopupWindowMsg("Please connnect your wallet first");
        setPopupWindowTrigger(true);
        return;
    }     

    //check if there is unclaimed rewards
    if(activatedWalletUnclaimedRewards=="N/A"){
        setPopupWindowMsg("Connected wallet must be registered first.");
        setPopupWindowTrigger(true);
        return;      
    }
    if(parseFloat(activatedWalletUnclaimedRewards)==0){
        setPopupWindowMsg("There is no current unclaimed rewards.");
        setPopupWindowTrigger(true);
        return;  
    }

    let txHashValue;
    let errorMsg;
    setBufferWindowTrigger(true);

    //const WalletInterface = await getWalletInterface(document.getElementById("connectedWallet").innerHTML);

    let utxoSelectionStrategy = 0;
    
    let claimRequestMsg = await createClaimRequest(utxoSelectionStrategy);

    while(claimRequestMsg=="unsuff funds"){
        if(utxoSelectionStrategy<3){
            utxoSelectionStrategy++;
            claimRequestMsg = await createClaimRequest(utxoSelectionStrategy);
        }
        else{
            setPopupWindowMsg("Insuffecient funds, to free some utxos in wallet and/or your wallet has less than 10 ADA, please fund it with 20 or more ADA.");
            setPopupWindowTrigger(true);
            setBufferWindowTrigger(false);   
            return;  
        }
    }
    //console.log("checkpoint 1")
    setBufferWindowMsg("Waiting in queue...");
    let claimTxBodyString = await sendClaimRequest(claimRequestMsg);
    if(claimTxBodyString=="no response"){
        setPopupWindowMsg("Could not connect to servers. If problem persists, try later.");
        setPopupWindowTrigger(true);
        setBufferWindowTrigger(false);
        return;
    }
    let claimTxBodyJson = JSON.parse(claimTxBodyString);
    setBufferWindowMsg("Fetching data...");
    //check if response is good
    switch(claimTxBodyJson['description']){
        case "invalid request":
                setPopupWindowMsg("Invalid request, please follow instructions correctly.");
                setPopupWindowTrigger(true);
                setBufferWindowTrigger(false);
                return;
        case "sold out":
                setPopupWindowMsg("There is no unclaimed rewards for this wallet currently. Please visit next epoch.");
                setPopupWindowTrigger(true);
                setBufferWindowTrigger(false);
                return;
        default:

        break;
    }
    setBufferWindowMsg("Building transaction...");
    let claimTxBodyCbor = claimTxBodyJson['cborHex'];
    let txID = claimTxBodyJson['txID'];

    let lucid = await Lucid.new(undefined,"Mainnet");
    //let newTx = lucid.newTx().complete();
    let newTx = lucid.fromTx(claimTxBodyCbor);
    lucid.selectWallet(WalletInterface);

    

    /* Commented to test Lucid ####################################################################################
    ###############################################################################################################

    */
    //setBufferWindowTrigger(false)
    //return;

    let txHash,signedTx;

    setBufferWindowMsg("Signing transaction...");
    try{
        signedTx = await newTx.sign().complete();

        //console.log("tx id: "+txID)
        //console.log("wallet just signed the tx: "+txWitness)
    }catch{
        setPopupWindowMsg("Transaction was not signed by the user.");
        setPopupWindowTrigger(true);
        setBufferWindowTrigger(false);  
        return;      
    }
    setBufferWindowMsg("Submitting transaction...");
    txHash = await signedTx.submit();
    
    if(txHash==undefined){
        setPopupWindowMsg("Transaction was not submitted by wallet due to a bug in the CSL library written by Emurgo.");
        setPopupWindowTrigger(true);
        setBufferWindowTrigger(false);  
        return;
    }
    let syncRequestMsg = "{\"txid\":\""+txID+"\"}";
    let syncRequestFeedbackString = await sendSyncRequest(syncRequestMsg);
    let syncRequestFeedbackJson = JSON.parse(syncRequestFeedbackString);
    let syncResult = syncRequestFeedbackJson['syncResult'];   

    if(syncResult=="ok"){
        setSuccessWindowMsgMode(1);
        setActivatedWalletClaimedRewards(displayNumberInPrettyFormat((parseFloat(activatedWalletClaimedRewards.replace(/,/g, ''))+parseFloat(activatedWalletUnclaimedRewards.replace(/,/g, ''))).toString()));
        setActivatedWalletUnclaimedRewards("0");
        setSuccessWindowTrigger(true);
        setBufferWindowTrigger(false);  
    }
    setBufferWindowTrigger(false);    
    
    //return Tx hash and show it on screen
} 

async function simulateTxSubmission(setSuccessWindowMsgMode,setSuccessWindowTrigger){
    let txID = "e5db6b88d7002f9b2e07d95c43d5fecdf2e885ae9c2b50ec8c1b1126953648cf";
    let syncRequestMsg = "{\"txid\":\""+txID+"\"}";
    let syncRequestFeedbackString = await sendSyncRequest(syncRequestMsg);
    let syncRequestFeedbackJson = JSON.parse(syncRequestFeedbackString);
    let syncResult = syncRequestFeedbackJson['syncResult'];   

    if(syncResult=="ok"){
        setSuccessWindowMsgMode(1);
        //setActivatedWalletClaimedRewards((parseInt(activatedWalletClaimedRewards.replace(/,/g, ''))+parseInt(activatedWalletUnclaimedRewards.replace(/,/g, ''))).toString());
        //setActivatedWalletUnclaimedRewards("0");
        setSuccessWindowTrigger(true);
        //setBufferWindowTrigger(false);  
    }
    //setBufferWindowTrigger(false);  
}

function recreateOutputValue(output){

    
    const recreatedOutputValue = C.Value.new(output.coin());
    const recreatedOutputMultiAsset = C.MultiAsset.new();
    
    const outputMultiAsset = output.multiasset();
    if(outputMultiAsset != undefined){
        for(let i=0;i<outputMultiAsset.len();i++){
            let policyID = outputMultiAsset.keys().get(i)
            let assets = C.Assets.new();
            for(let j=0;j<outputMultiAsset.get(outputMultiAsset.keys().get(i)).len();j++){
                //console.log("assetName unit8Array: "+outputMultiAsset.get(outputMultiAsset.keys().get(i)).keys().get(j).to_bytes());
                //console.log("assetName name: "+outputMultiAsset.get(outputMultiAsset.keys().get(i)).keys().get(j).name());
                //console.log("assetName hex: "+outputMultiAsset.get(outputMultiAsset.keys().get(i)).keys().get(j).to_hex());
                //let =String.fromCharCode(...outputMultiAsset.get(outputMultiAsset.keys().get(i)).keys().get(j).name())
                var stringName = new TextDecoder().decode(outputMultiAsset.get(outputMultiAsset.keys().get(i)).keys().get(j).name());
                //console.log(stringName)
                var uint8arrayName = new TextEncoder().encode(stringName);
                //console.log(uint8arrayName)
                
                let assetName = C.AssetName.new(uint8arrayName);
                let assetCount = outputMultiAsset.get(outputMultiAsset.keys().get(i)).get(assetName);
                assets.insert(assetName,assetCount);
            }
            //let assets = outputMultiAsset.get(outputMultiAsset.keys().get(i))
            
            recreatedOutputMultiAsset.insert(policyID,assets);
        }
        
        recreatedOutputValue.set_multiasset(recreatedOutputMultiAsset);
    }

    return recreatedOutputValue;
}

async function updateConnectedWalletProfile(setActivatedWalletName,setActivatedWalletIcon,setActivatedWalletAddress,setActivatedWalletStakeAddress,setActivatedWalletBalance,setActivatedWalletShroomsBalance,setActivatedWalletRegestrationStatus,setActivatedWalletRegestrationEpoch,setActivatedWalletPreviousRewards,setActivatedWalletUnclaimedRewards,setActivatedWalletTotalRewards,setActivatedWalletClaimedRewards){
   
    var connectedWalletName = document.getElementById("selectedWallet").value;
    //console.log(connectedWalletName)
    setActivatedWalletName(connectedWalletName);
    setActivatedWalletIcon(getWalletIcon(connectedWalletName));

    //wallet address
    
    //const WalletInterface = await getWalletInterface(connectedWalletName);
    const walletAddresses = await WalletInterface.getUsedAddresses();
    let walletAddress = C.Address.from_bytes(Buffer.from(walletAddresses[0],"hex")).to_bech32("addr");
    //console.log(walletAddress)
    setActivatedWalletAddress(walletAddress.substring(0,10)+"...."+walletAddress.slice(-10));

    //stake address
    
    const rewardAddresses = await WalletInterface.getRewardAddresses();
    let rewardAddress = C.Address.from_bytes(Buffer.from(rewardAddresses[0],"hex")).to_bech32("stake");
    activatedRewardAddress = rewardAddress;
    //console.log(rewardAddress)
    setActivatedWalletStakeAddress(rewardAddress.substring(0,10)+"...."+rewardAddress.slice(-10));

    //ada balance
    
    const cborBalance = await WalletInterface.getBalance();
    const valueBalance = C.Value.from_bytes(Buffer.from(cborBalance,"hex"));
    let adaAmountString = "";
    let lovelaceAmountString = valueBalance.coin().to_str();
    adaAmountString = await convertADABalanceToUserFormat(lovelaceAmountString);
    setActivatedWalletBalance(adaAmountString);

    //shrooms balance
    
    setActivatedWalletShroomsBalance(await getActivatedWalletShroomsBalance(WalletInterface));

    const response = await fetch('https://shroomsv2.adalink.io/api/getWalletStakingSummary.php?stakeAddress='+rewardAddress,{cache:"reload"});
    const walletStatus = (await response.text()).split(':');//"1:12000000:142000000:3210000000".split(':');//(await response.text()).split(':');
    
    switch(walletStatus[0]){
        case "0":
            setActivatedWalletRegestrationStatus("Not registered");
            setActivatedWalletRegestrationEpoch("N/A");
            setActivatedWalletPreviousRewards("N/A");
            setActivatedWalletUnclaimedRewards("N/A");
            setActivatedWalletTotalRewards("N/A");
            setActivatedWalletClaimedRewards("N/A");
        break;
        case "1":
            setActivatedWalletRegestrationStatus("Registered");
            setActivatedWalletRegestrationEpoch("Registered this epoch");
            setActivatedWalletPreviousRewards(walletStatus[1].replace(/(\d)(?=(\d{3})+$)/g, '$1,'));
            setActivatedWalletUnclaimedRewards(walletStatus[2].replace(/(\d)(?=(\d{3})+$)/g, '$1,'));
            setActivatedWalletTotalRewards(walletStatus[3].replace(/(\d)(?=(\d{3})+$)/g, '$1,'));
            setActivatedWalletClaimedRewards((parseInt(walletStatus[3]) - parseInt(walletStatus[2])).toString().replace(/(\d)(?=(\d{3})+$)/g, '$1,'));
        break;
        default:
            setActivatedWalletRegestrationStatus("Registered");
            setActivatedWalletRegestrationEpoch("Registered since epoch "+walletStatus[0]);
            setActivatedWalletPreviousRewards(walletStatus[1].replace(/(\d)(?=(\d{3})+$)/g, '$1,'));
            setActivatedWalletUnclaimedRewards(walletStatus[2].replace(/(\d)(?=(\d{3})+$)/g, '$1,'));
            setActivatedWalletTotalRewards(walletStatus[3].replace(/(\d)(?=(\d{3})+$)/g, '$1,'));
            setActivatedWalletClaimedRewards((parseInt(walletStatus[3]) - parseInt(walletStatus[2])).toString().replace(/(\d)(?=(\d{3})+$)/g, '$1,'));
        }
        //console.log("checkpoint 5")
}

async function updateConnectedWalletGivenProjectDetails(projectName,setRegistrationStatus,setRegistrationEpoch,setTokenBalance,setNFTsCount,setRewardMultiplier,setEstimatedRewards,setPreviousRewards,setUnclaimedRewards,setClaimedRewards,setTotalRewards){
    //This function calculates/gets a specific token balance, registration status, rewards details

    var connectedWalletName = document.getElementById("selectedWallet").value;
    //const WalletInterface = await getWalletInterface(connectedWalletName);
    //stake address
    const rewardAddresses = await WalletInterface.getRewardAddresses();
    let rewardAddress = C.Address.from_bytes(Buffer.from(rewardAddresses[0],"hex")).to_bech32("stake");
    activatedRewardAddress = rewardAddress;

    //token balance
    let tokenName = getProjectTokenName(projectName);
    let decimals = getTokenDecimals(tokenName);
    setTokenBalance(await getActivatedWalletTokenBalance(WalletInterface,tokenName,decimals));

    const response = await fetch('https://shroomsv2.adalink.io/api/getWalletStakingSummaryOfToken.php?stakeAddress='+rewardAddress+'&table='+tokenName+'RewardSummary',{cache:"reload"});
    const walletStatus = (await response.text()).split(':');//"1:12000000:142000000:3210000000".split(':');//(await response.text()).split(':');
    
    switch(walletStatus[0]){
        case "0":
            setRegistrationStatus("Not registered");
            setRegistrationEpoch("N/A");
            setPreviousRewards("N/A");
            setUnclaimedRewards("N/A");
            setTotalRewards("N/A");
            setClaimedRewards("N/A");
        break;
        case "1":
            setRegistrationStatus("Registered");
            setRegistrationEpoch("Registered this epoch");
            setPreviousRewards(displayNumberInPrettyFormat((parseInt(walletStatus[1])/Math.pow(10,decimals)).toString()));
            setUnclaimedRewards(displayNumberInPrettyFormat((parseInt(walletStatus[2])/Math.pow(10,decimals)).toString()));
            setTotalRewards(displayNumberInPrettyFormat((parseInt(walletStatus[3])/Math.pow(10,decimals)).toString()));
            setClaimedRewards(displayNumberInPrettyFormat(((parseInt(walletStatus[3]) - parseInt(walletStatus[2]))/Math.pow(10,decimals)).toString()));
        break;
        default:
            setRegistrationStatus("Registered");
            setRegistrationEpoch("Registered since epoch "+walletStatus[0]);   
            setPreviousRewards(displayNumberInPrettyFormat((parseInt(walletStatus[1])/Math.pow(10,decimals)).toString()));
            setUnclaimedRewards(displayNumberInPrettyFormat((parseInt(walletStatus[2])/Math.pow(10,decimals)).toString()));
            setTotalRewards(displayNumberInPrettyFormat((parseInt(walletStatus[3])/Math.pow(10,decimals)).toString()));
            setClaimedRewards(displayNumberInPrettyFormat(((parseInt(walletStatus[3]) - parseInt(walletStatus[2]))/Math.pow(10,decimals)).toString()));
    }

    setNFTsCount(getNFTsCountOfProject(projectName));
    setRewardMultiplier(getMultiplierOfProject(projectName));
    
    //setEstimatedRewards(getMultiplierOfProject(projectName)*projectBaseReward[projectName]);
    setEstimatedRewards(displayNumberInPrettyFormat(parseFloat((getMultiplierOfProject(projectName)*projectBaseReward[projectName]).toFixed(decimals)).toString()))

}

function hex2ascii(hexx) {
    var hex = hexx.toString();//force conversion
    var str = '';
    for (var i = 0; i < hex.length; i += 2)
        str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
    return str;
}


function displayNumberInPrettyFormat(numberAsString){
  
    let splitedString, wholeNumber, fractions, prettyNumber;
    splitedString = numberAsString.split(".");
    
    wholeNumber = splitedString[0];
    if (splitedString.length > 1){
        
        fractions = splitedString[1];
        prettyNumber = wholeNumber.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1,')+"."+fractions;
    }else{
        
        prettyNumber = wholeNumber.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1,')
    }
    
    return prettyNumber;
}

function removePrettyFormat(prettyNumberString){
    let output = prettyNumberString.replace(/,/g, '');
    return output 
}

async function convertADABalanceToUserFormat(lovelaceAmountString){

    let adaAmountString = "";
    let adaAmountInt = parseInt(lovelaceAmountString.substring(0,lovelaceAmountString.length-6));
    if(adaAmountInt<1000)
        adaAmountString=adaAmountInt;
    else if(adaAmountInt<1000000){
        adaAmountInt=adaAmountInt/1000;
        adaAmountString=adaAmountInt+"K";
    }else if(adaAmountInt<1000000000){
        adaAmountInt=adaAmountInt/1000000;
        adaAmountString=adaAmountInt+"M";
    }else{
        adaAmountInt=adaAmountInt/1000000000;
        adaAmountString=adaAmountInt+"B";
    }
    return adaAmountString;
}

async function getActivatedWalletShroomsBalance(WalletInterface){

    const cborBalance = await WalletInterface.getBalance();
    const valueBalance = C.Value.from_bytes(Buffer.from(cborBalance,"hex"));
    let shroomsPolicyID="715d89545d6ce3cfca7680b216a3f327ee61d377732431049ed0b240";
    const policyIDScriptHash = C.ScriptHash.from_bytes(Buffer.from(shroomsPolicyID,"hex"));

    let token = "Shroom";
    let decimals = 0; 
    let tokenAmountString = "";
    let assetNameString = "";
    let assetName;

    if(valueBalance.multiasset() == undefined){
        return "0";
    }

    if(valueBalance.multiasset().get(policyIDScriptHash)==undefined){
        return "0";
    }

    for(let i=0;i<valueBalance.multiasset().get(policyIDScriptHash).keys().len();i++){
        assetNameString = new TextDecoder().decode(valueBalance.multiasset().get(policyIDScriptHash).keys().get(i).name());
        if(assetNameString==token){
            assetName=valueBalance.multiasset().get(policyIDScriptHash).keys().get(i);
            let assetNameUint8Array = new TextEncoder("utf-8").encode(token);
            let assetNameFromBuffer = new Uint8Array(Buffer.from(token));
            const assetName2 = C.AssetName.new(assetNameFromBuffer);
            break;
        }    
    }

    let amountString = valueBalance.multiasset().get(policyIDScriptHash).get(assetName).to_str();

    let tokenAmountInt = 0;
    if(amountString.length>decimals)
        tokenAmountInt = parseInt(amountString.substring(0,amountString.length-decimals));
    
    if(tokenAmountInt<1000)
        tokenAmountString=tokenAmountInt;
    else if(tokenAmountInt<1000000){
        tokenAmountInt=tokenAmountInt/1000;
        if(tokenAmountInt.toString().length>6)
            tokenAmountString=tokenAmountInt.toString().substring(0,5)+"K";
        else
            tokenAmountString=tokenAmountInt+"K";
    }else if(tokenAmountInt<1000000000){
        tokenAmountInt=tokenAmountInt/1000000;
        if(tokenAmountInt.toString().length>6)
            tokenAmountString=tokenAmountInt.toString().substring(0,5)+"M";
        else
            tokenAmountString=tokenAmountInt+"M";
    }else if(tokenAmountInt<1000000000000){
        tokenAmountInt=tokenAmountInt/1000000000;
        if(tokenAmountInt.toString().length>6)
            tokenAmountString=tokenAmountInt.toString().substring(0,5)+"B";
        else
            tokenAmountString=tokenAmountInt+"B";
    }else {
        tokenAmountInt=tokenAmountInt/1000000000000;
        if(tokenAmountInt.toString().length>6)
            tokenAmountString=tokenAmountInt.toString().substring(0,5)+"T";
        else
            tokenAmountString=tokenAmountInt+"T";
    }
    
    return tokenAmountString;
}

async function getShroomsBalance(WalletInterface){

    const cborBalance = await WalletInterface.getBalance();
    const valueBalance = C.Value.from_bytes(Buffer.from(cborBalance,"hex"));
    let shroomsPolicyID="715d89545d6ce3cfca7680b216a3f327ee61d377732431049ed0b240";
    const policyIDScriptHash = C.ScriptHash.from_bytes(Buffer.from(shroomsPolicyID,"hex"));

    let token = "Shroom";
    let decimals = 0; 
    let tokenAmountString = "";
    let assetNameString = "";
    let assetName;

    if(valueBalance.multiasset() == undefined){
        return "0";
    }

    if(valueBalance.multiasset().get(policyIDScriptHash)==undefined){
        return "0";
    }

    for(let i=0;i<valueBalance.multiasset().get(policyIDScriptHash).keys().len();i++){
        assetNameString = new TextDecoder().decode(valueBalance.multiasset().get(policyIDScriptHash).keys().get(i).name());
        if(assetNameString==token){
            assetName=valueBalance.multiasset().get(policyIDScriptHash).keys().get(i);
            let assetNameUint8Array = new TextEncoder("utf-8").encode(token);
            let assetNameFromBuffer = new Uint8Array(Buffer.from(token));
            const assetName2 = C.AssetName.new(assetNameFromBuffer);
            break;
        }    
    }

    let amountString = valueBalance.multiasset().get(policyIDScriptHash).get(assetName).to_str();
    return amountString;
}

async function getFungiBalance(WalletInterface){

    const cborBalance = await WalletInterface.getBalance();
    const valueBalance = C.Value.from_bytes(Buffer.from(cborBalance,"hex"));
    let fungiPolicyID="35e174dff9dc26dfce20b4c817768c3f5e637126896461a6fa9193f6";
    const policyIDScriptHash = C.ScriptHash.from_bytes(Buffer.from(fungiPolicyID,"hex"));

    let token = "Fungi";
    let decimals = 0; 
    let tokenAmountString = "";
    let assetNameString = "";
    let assetName;

    if(valueBalance.multiasset() == undefined){
        return "0";
    }

    if(valueBalance.multiasset().get(policyIDScriptHash)==undefined){
        return "0";
    }

    for(let i=0;i<valueBalance.multiasset().get(policyIDScriptHash).keys().len();i++){
        assetNameString = new TextDecoder().decode(valueBalance.multiasset().get(policyIDScriptHash).keys().get(i).name());
        if(assetNameString==token){
            assetName=valueBalance.multiasset().get(policyIDScriptHash).keys().get(i);
            let assetNameUint8Array = new TextEncoder("utf-8").encode(token);
            let assetNameFromBuffer = new Uint8Array(Buffer.from(token));
            const assetName2 = C.AssetName.new(assetNameFromBuffer);
            break;
        }    
    }

    let amountString = valueBalance.multiasset().get(policyIDScriptHash).get(assetName).to_str();
    return amountString;
}

async function getActivatedWalletTokenBalance(WalletInterface,token,decimals){

    const cborBalance = await WalletInterface.getBalance();
    const valueBalance = C.Value.from_bytes(Buffer.from(cborBalance,"hex"));
    let shroomsPolicyID=getPolicyIdOf(token);
    const policyIDScriptHash = C.ScriptHash.from_bytes(Buffer.from(shroomsPolicyID,"hex"));

    let tokenAmountString = "";
    let assetNameString = "";
    let assetName;

    if(valueBalance.multiasset() == undefined){
        return "0";
    }

    if(valueBalance.multiasset().get(policyIDScriptHash)==undefined){
        return "0";
    }

    for(let i=0;i<valueBalance.multiasset().get(policyIDScriptHash).keys().len();i++){
        assetNameString = new TextDecoder().decode(valueBalance.multiasset().get(policyIDScriptHash).keys().get(i).name());
        if(assetNameString==token){
            assetName=valueBalance.multiasset().get(policyIDScriptHash).keys().get(i);
            let assetNameUint8Array = new TextEncoder("utf-8").encode(token);
            let assetNameFromBuffer = new Uint8Array(Buffer.from(token));
            const assetName2 = C.AssetName.new(assetNameFromBuffer);
            break;
        }    
    }

    let amountString = valueBalance.multiasset().get(policyIDScriptHash).get(assetName).to_str();
    
    return displayNumberInPrettyFormat((parseInt(amountString)/Math.pow(10,decimals)).toString())

    let tokenAmountInt = 0;
    if(amountString.length>decimals)
        tokenAmountInt = parseInt(amountString.substring(0,amountString.length-decimals));
    
    if(tokenAmountInt<1000)
        tokenAmountString=tokenAmountInt;
    else if(tokenAmountInt<1000000){
        tokenAmountInt=tokenAmountInt/1000;
        if(tokenAmountInt.toString().length>6)
            tokenAmountString=tokenAmountInt.toString().substring(0,5)+"K";
        else
            tokenAmountString=tokenAmountInt+"K";
    }else if(tokenAmountInt<1000000000){
        tokenAmountInt=tokenAmountInt/1000000;
        if(tokenAmountInt.toString().length>6)
            tokenAmountString=tokenAmountInt.toString().substring(0,5)+"M";
        else
            tokenAmountString=tokenAmountInt+"M";
    }else if(tokenAmountInt<1000000000000){
        tokenAmountInt=tokenAmountInt/1000000000;
        if(tokenAmountInt.toString().length>6)
            tokenAmountString=tokenAmountInt.toString().substring(0,5)+"B";
        else
            tokenAmountString=tokenAmountInt+"B";
    }else {
        tokenAmountInt=tokenAmountInt/1000000000000;
        if(tokenAmountInt.toString().length>6)
            tokenAmountString=tokenAmountInt.toString().substring(0,5)+"T";
        else
            tokenAmountString=tokenAmountInt+"T";
    }
    
    return tokenAmountString;
}

async function getNFTsInWallet(stakeAddress){
    //console.log(stakeAddress)
    //let stakeAddress="stake1u932s9htj6jdd7m9gr75s0vz0mmy0ajgjg8qvksaj70a7lcxswkke";
    response = await fetch('https://shroomsv2.adalink.io/api/get-nfts-in-wallet.php?address='+stakeAddress,{cache:"reload"});
    let walletInfo = JSON.parse(await response.text());
    nftsInWallet = walletInfo['tokens'];
    searchedNFTsInWallet = walletInfo['tokens'];
    //console.log("from getNFTsInWallet method: ")
    //console.log(nftsInWallet);
    //console.log("existing method...")
}

async function getMyEntriesCounts(stakeAddress){

    response = await fetch('https://shroomsv2.adalink.io/api/get-my-entries-counts.php?address='+stakeAddress,{cache: "reload"});
    myEntries = JSON.parse(await response.text());
    myEntries = JSON.parse(myEntries);
    //console.log(myEntries)
}

async function refreshEntriesCounters(){
    response = await fetch('https://shroomsv2.adalink.io/api/get-total-entries-counts.php',{cache:"reload"});
    totalEntries = JSON.parse(await response.text());
    totalEntries = JSON.parse(totalEntries);
    
    if (currentRewardAddress != "")
        await getMyEntriesCounts(currentRewardAddress);
    
}

async function refreshRaffles(){

    await refreshRafflesCollectionsList();
    response = await fetch('https://shroomsv2.adalink.io/api/get-listed-raffles.php',{cache:"reload"}); 
    response.text().then((value) => {
        responseText=value;
        listedRaffles = JSON.parse(responseText);
        listedRafflesEndingSoonSorted = JSON.parse(responseText);
        listedRafflesRecentSorted = JSON.parse(responseText);
        listedRafflesEndingSoonSorted.sort((a,b) => (parseInt(a['EndsAt'])>parseInt(b['EndsAt']))?1:-1);
        listedRafflesRecentSorted.sort((a,b) => (parseInt(a['StartedAt'])>parseInt(b['StartedAt']))?-1:1);
    })
    /*listedRaffles = JSON.parse(await response.text());*/
    response = await fetch('https://shroomsv2.adalink.io/api/get-ended-raffles.php',{cache:"reload"});
    response.text().then((value) => {
        responseText=value;
        endedRaffles = JSON.parse(responseText);
        endedRaffles.reverse();
    })
    //endedRaffles = JSON.parse(await response.text());
    await refreshEntriesCounters();
}


async function refreshRafflesCollectionsList(){
    
    response = await fetch('https://shroomsv2.adalink.io/api/get-registered-policies-names.php',{cache:"reload"});
    collectionsListString = JSON.parse(await response.text());
    collectionsList = JSON.parse(collectionsListString);
    searchedCollectionsList = Array();//JSON.parse(collectionsListString);
}




async function setSNFTsInWallet(liteModeStatus,setFirstArrivalsList,setZiBabiesList,setTreeOfLifeList,setFreddieTheWhaleList,setGnomiesOGList,setGnomiesTheReturnList,setJarHeadsList,setNekomimiList,setOverExposedGenesisList,setMekanismList,setMekanismCacheList,setShroomiesOGList,setUglyBrosS1List,setUglyBrosXmasList,setUglyBrosCommunityList,setUglyBrosS2List,setWhatTheDuckVIPBoxList,setWhatTheDuckList,setCollectionsDictionary,setActivatedWalletSNFTsCount,setActivatedWalletRewardMultiplier,setActivatedWalletEstimatedRewards){

    

    console.log("Starting the load sequence...")
    //load wallet interface
    var connectedWalletName = document.getElementById("selectedWallet").value;
    //const WalletInterface = await getWalletInterface(connectedWalletName);
    //get the total balance of wallet
    const cborBalance = await WalletInterface.getBalance();
    const valueBalance = C.Value.from_bytes(Buffer.from(cborBalance,"hex"));
    assetsInWallet = JSON.parse(valueBalance.to_json())["multiasset"];
    console.log(assetsInWallet)

    let n=18, m=1;
    
        //first arrival collection
    console.log(m+++"/"+n)
    let firstArrivalPolicyID="28e0e399d9dc03dbb7b97ad45461cda8e7c4f24c85f91b863b8b6374";
    let firstArivalList;
    try{firstArivalList=Object.keys(assetsInWallet[firstArrivalPolicyID]).map(asset_hex => Buffer.from(asset_hex,'hex').toString());
    }catch{firstArivalList=[];}
    setFirstArrivalsList(firstArivalList);
        //zi babies collection
        console.log(m+++"/"+n)    
    let ziBabiesPolicyID="20b6630b045014e4ee2c410329d36e192bf102155692308df69f4eff";
    let ziBabiesList;
    try{ziBabiesList=Object.keys(assetsInWallet[ziBabiesPolicyID]).map(asset_hex => Buffer.from(asset_hex,'hex').toString());
    }catch{ziBabiesList=[];}
    setZiBabiesList(ziBabiesList);
    console.log(m+++"/"+n)
        //tree of life collection
    let treeOfLifePolicyID="334dc668ba87d3a0caa8cd1112d9ea3068e32ce114cb9375a49d289d";
    let treeOfLifeList;
    try{treeOfLifeList=Object.keys(assetsInWallet[treeOfLifePolicyID]).map(asset_hex => Buffer.from(asset_hex,'hex').toString());
    }catch{treeOfLifeList=[];}    setTreeOfLifeList(treeOfLifeList);
    console.log(m+++"/"+n)
        //tree of life collection
    let gnomiesOGPolicyID=getPolicyIdOf("GnomiesOG");
    let gnomiesOGList
    try{gnomiesOGList=Object.keys(assetsInWallet[gnomiesOGPolicyID]).map(asset_hex => Buffer.from(asset_hex,'hex').toString());
    }catch{gnomiesOGList=[];}    setGnomiesOGList(gnomiesOGList);
    console.log(m+++"/"+n)
        //tree of life collection
    let freddieTheWhalePolicyID=getPolicyIdOf("FreddieTheWhale");
    let freddieTheWhaleList
    try{freddieTheWhaleList=Object.keys(assetsInWallet[freddieTheWhalePolicyID]).map(asset_hex => Buffer.from(asset_hex,'hex').toString());
    }catch{freddieTheWhaleList=[];}    setFreddieTheWhaleList(freddieTheWhaleList);
    console.log(m+++"/"+n)
        //tree of life collection
    let jarHeadsPolicyID=getPolicyIdOf("JarHeads");
    let jarHeadsList
    try{jarHeadsList=Object.keys(assetsInWallet[jarHeadsPolicyID]).map(asset_hex => Buffer.from(asset_hex,'hex').toString());
    }catch{jarHeadsList=[];}    setJarHeadsList(jarHeadsList);
    console.log(m+++"/"+n)
        //tree of life collection
    let nekomimiPolicyID=getPolicyIdOf("Nekomimi");
    let nekomimiList
    try{nekomimiList=Object.keys(assetsInWallet[nekomimiPolicyID]).map(asset_hex => Buffer.from(asset_hex,'hex').toString());
    }catch{nekomimiList=[];}    setNekomimiList(nekomimiList);
    console.log(m+++"/"+n)
        //tree of life collection
    let overExposedGenesisPolicyID=getPolicyIdOf("OverExposedGenesis");
    let overExposedGenesisList
    try{overExposedGenesisList=Object.keys(assetsInWallet[overExposedGenesisPolicyID]).map(asset_hex => Buffer.from(asset_hex,'hex').toString());
    }catch{overExposedGenesisList=[];}    setOverExposedGenesisList(overExposedGenesisList);
    console.log(m+++"/"+n)
        //tree of life collection
    let mekanismPolicyID=getPolicyIdOf("Mekanism");
    let mekanismList
    try{mekanismList=Object.keys(assetsInWallet[mekanismPolicyID]).map(asset_hex => Buffer.from(asset_hex,'hex').toString());
    }catch{mekanismList=[];}    setMekanismList(mekanismList);
    console.log(m+++"/"+n)
        //tree of life collection
    let mekanismCachePolicyID=getPolicyIdOf("MekanismCache");
    let mekanismCacheList
    try{mekanismCacheList=Object.keys(assetsInWallet[mekanismCachePolicyID]).map(asset_hex => Buffer.from(asset_hex,'hex').toString());
    }catch{mekanismCacheList=[];}    setMekanismCacheList(mekanismCacheList);
    console.log(m+++"/"+n)
        //tree of life collection
    let shroomiesOGPolicyID=getPolicyIdOf("ShroomiesOG");
    let shroomiesOGList
    try{shroomiesOGList=Object.keys(assetsInWallet[shroomiesOGPolicyID]).map(asset_hex => Buffer.from(asset_hex,'hex').toString());
    }catch{shroomiesOGList=[];}    setShroomiesOGList(shroomiesOGList);    
    console.log(m+++"/"+n)
        //tree of life collection
    let uglyBrosS1PolicyID=getPolicyIdOf("UglyBrosS1");
    let uglyBrosS1List
    try{uglyBrosS1List=Object.keys(assetsInWallet[uglyBrosS1PolicyID]).map(asset_hex => Buffer.from(asset_hex,'hex').toString());
    }catch{uglyBrosS1List=[];}    setUglyBrosS1List(uglyBrosS1List);
    nftsCountOf['UglyBrosS1']=uglyBrosS1List.length;
    console.log(m+++"/"+n)
        //tree of life collection
    let uglyBrosXmasPolicyID=getPolicyIdOf("UglyBrosXmas");
    let uglyBrosXmasList
    try{uglyBrosXmasList=Object.keys(assetsInWallet[uglyBrosXmasPolicyID]).map(asset_hex => Buffer.from(asset_hex,'hex').toString());
    }catch{uglyBrosXmasList=[];}    setUglyBrosXmasList(uglyBrosXmasList);
    nftsCountOf['UglyBrosXmas']=uglyBrosXmasList.length;
    console.log(m+++"/"+n)
            //tree of life collection
    let uglyBrosCommunityPolicyID=getPolicyIdOf("UglyBrosCommunity");
    let uglyBrosCommunityList
    try{uglyBrosCommunityList=Object.keys(assetsInWallet[uglyBrosCommunityPolicyID]).map(asset_hex => Buffer.from(asset_hex,'hex').toString());
    }catch{uglyBrosCommunityList=[];}    setUglyBrosCommunityList(uglyBrosCommunityList);
    nftsCountOf['UglyBrosCommunity']=uglyBrosCommunityList.length;
    console.log(m+++"/"+n)
            //tree of life collection
    let uglyBrosS2PolicyID=getPolicyIdOf("UglyBrosS2");
    let uglyBrosS2List
    try{uglyBrosS2List=Object.keys(assetsInWallet[uglyBrosS2PolicyID]).map(asset_hex => Buffer.from(asset_hex,'hex').toString());
    }catch{uglyBrosS2List=[];}    setUglyBrosS2List(uglyBrosS2List);
    nftsCountOf['UglyBrosS2']=uglyBrosS2List.length;
    console.log(m+++"/"+n)
            //tree of life collection
    let whatTheDuckVIPBoxPolicyID=getPolicyIdOf("WhatTheDuckVIPBox");
    let whatTheDuckVIPBoxList
    try{whatTheDuckVIPBoxList=Object.keys(assetsInWallet[whatTheDuckVIPBoxPolicyID]).map(asset_hex => Buffer.from(asset_hex,'hex').toString());
    }catch{whatTheDuckVIPBoxList=[];}    setWhatTheDuckVIPBoxList(whatTheDuckVIPBoxList);
    console.log(m+++"/"+n)
            //tree of life collection
    let whatTheDuckPolicyID=getPolicyIdOf("WhatTheDuck");
    let whatTheDuckList
    try{whatTheDuckList=Object.keys(assetsInWallet[whatTheDuckPolicyID]).map(asset_hex => Buffer.from(asset_hex,'hex').toString());
    }catch{whatTheDuckList=[];}    setWhatTheDuckList(whatTheDuckList);
    console.log(m+++"/"+n)
    
    console.log("concatinating data...")
    // get nfts ipfs hashes
    let numberOfSNFTs = (firstArivalList.length + ziBabiesList.length + treeOfLifeList.length 
                        + gnomiesOGList.length + jarHeadsList.length + freddieTheWhaleList.length
                        + nekomimiList.length + overExposedGenesisList.length + mekanismList.length
                        + mekanismCacheList.length + shroomiesOGList.length + uglyBrosS1List.length
                        + uglyBrosXmasList.length + uglyBrosCommunityList.length + uglyBrosS2List.length
                        + whatTheDuckVIPBoxList.length + whatTheDuckList.length)
    let nftsList = new Array(numberOfSNFTs);

    let startAt = 0
    let endAt = firstArivalList.length;
    for(let i=0;i<endAt;i++){
        nftsList[i+startAt]=firstArivalList[i];
    }
    startAt += firstArivalList.length;
    endAt = ziBabiesList.length;
    for(let i=0;i<endAt;i++){
        nftsList[i+startAt]=ziBabiesList[i];
    }   
    startAt += ziBabiesList.length;
    endAt = treeOfLifeList.length;
    for(let i=0;i<endAt;i++){
        nftsList[i+startAt]=treeOfLifeList[i];
    }    
    startAt += treeOfLifeList.length;
    endAt = gnomiesOGList.length;
    for(let i=0;i<endAt;i++){
        nftsList[i+startAt]=gnomiesOGList[i];
    }       
    startAt += gnomiesOGList.length;
    endAt = jarHeadsList.length;
    for(let i=0;i<endAt;i++){
        nftsList[i+startAt]=jarHeadsList[i];
    }     
    startAt += jarHeadsList.length;
    endAt = freddieTheWhaleList.length;
    for(let i=0;i<endAt;i++){
        nftsList[i+startAt]=freddieTheWhaleList[i];
    }     
    startAt += freddieTheWhaleList.length;
    endAt = nekomimiList.length;
    for(let i=0;i<endAt;i++){
        nftsList[i+startAt]=nekomimiList[i];
    }   
    startAt += nekomimiList.length;
    endAt = overExposedGenesisList.length;
    for(let i=0;i<endAt;i++){
        nftsList[i+startAt]=overExposedGenesisList[i];
    }   
    startAt += overExposedGenesisList.length;
    endAt = mekanismList.length;
    for(let i=0;i<endAt;i++){
        nftsList[i+startAt]=mekanismList[i];
    }   
    startAt += mekanismList.length;
    endAt = mekanismCacheList.length;
    for(let i=0;i<endAt;i++){
        nftsList[i+startAt]=mekanismCacheList[i];
    }   
    startAt += mekanismCacheList.length;
    endAt = shroomiesOGList.length;
    for(let i=0;i<endAt;i++){
        nftsList[i+startAt]=shroomiesOGList[i];
    }   
    startAt += shroomiesOGList.length;
    endAt = uglyBrosS1List.length;
    for(let i=0;i<endAt;i++){
        nftsList[i+startAt]=uglyBrosS1List[i];
    }   
    startAt += uglyBrosS1List.length;
    endAt = uglyBrosXmasList.length;
    for(let i=0;i<endAt;i++){
        nftsList[i+startAt]=uglyBrosXmasList[i];
    }   
    startAt += uglyBrosXmasList.length;
    endAt = uglyBrosCommunityList.length;
    for(let i=0;i<endAt;i++){
        nftsList[i+startAt]=uglyBrosCommunityList[i];
    }   
    startAt += uglyBrosCommunityList.length;
    endAt = uglyBrosS2List.length;
    for(let i=0;i<endAt;i++){
        nftsList[i+startAt]=uglyBrosS2List[i];
    }   
    startAt += uglyBrosS2List.length;
    endAt = whatTheDuckVIPBoxList.length;
    for(let i=0;i<endAt;i++){
        nftsList[i+startAt]=whatTheDuckVIPBoxList[i];
    }   
    startAt += whatTheDuckVIPBoxList.length;
    endAt = whatTheDuckList.length;
    for(let i=0;i<endAt;i++){
        nftsList[i+startAt]=whatTheDuckList[i];
    }   


    console.log("assets loaded.")
    const collectionsDictionary = {
        'FirstArrivals':firstArivalList,
        'ZiBabies':ziBabiesList,
        'TreeOfLife':treeOfLifeList,
        'FreddieTheWhale' : freddieTheWhaleList,
        'GnomiesOG' :gnomiesOGList,
        'JarHeads' : jarHeadsList,
        'Nekomimi':nekomimiList,
        'OverExposedGenesis':overExposedGenesisList,
        'Mekanism':mekanismList,
        'MekanismCache':mekanismCacheList,
        'ShroomiesOG':shroomiesOGList,
        'UglyBrosS1':uglyBrosS1List,
        'UglyBrosXmas':uglyBrosXmasList,
        'UglyBrosCommunity':uglyBrosCommunityList,
        'UglyBrosS2':uglyBrosS2List,
        'WhatTheDuckVIPBox':whatTheDuckVIPBoxList,
        'WhatTheDuck':whatTheDuckList
    }
    setCollectionsDictionary(collectionsDictionary);
    setActivatedWalletSNFTsCount(nftsList.length);
    let rewardMultiplier = nftsList.length+2*treeOfLifeList.length+4*firstArivalList.length+9*ziBabiesList.length;
    //console.log("muklt: "+rewardMultiplier)
    setActivatedWalletRewardMultiplier(rewardMultiplier);
    //let baseReward = 1000000;
    setActivatedWalletEstimatedRewards(displayNumberInPrettyFormat((rewardMultiplier*baseReward).toString()));
    //console.log(nftsList)
    if(liteModeStatus!=true){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'
                    
                },
        body: JSON.stringify({ 'AssetNames': [nftsList] })
    };
    //console.log(ziBabiesList.toString());
    try{
        let response = await fetch('https://shroomsv2.adalink.io/api/get-nfts-media-references.php', requestOptions);
        NFTsMediaReference = await response.json();
        //console.log(NFTsMediaReference);
        //setNFTsMediaReference(NFTsMediaReference);
        response = await fetch('https://shroomsv2.adalink.io/api/get-nfts-name-references.php', requestOptions);
        NFTsNameReference = await response.json();
        //console.log(NFTsNameReference);
        //setNFTsNameReference(NFTsNameReference);
        //console.log(await response.text());
    }
    catch{

    }
    }
}

async function setSNFTsInWalletV2(liteModeStatus,setFirstArrivalsList,setZiBabiesList,setTreeOfLifeList,setFreddieTheWhaleList,setGnomiesOGList,setGnomiesTheReturnList,setJarHeadsList,setNekomimiList,setOverExposedGenesisList,setMekanismList,setMekanismCacheList,setShroomiesOGList,setUglyBrosS1List,setUglyBrosXmasList,setUglyBrosCommunityList,setUglyBrosS2List,setWhatTheDuckVIPBoxList,setWhatTheDuckList,setCollectionsDictionary,setActivatedWalletSNFTsCount,setActivatedWalletRewardMultiplier,setActivatedWalletEstimatedRewards){

    

    
    //console.log("Starting the load sequence...")
    //console.log(nftsInWallet);
    //load wallet interface

    let n=18, m=1;
    
        //first arrival collection
    //console.log(m+++"/"+n)
    let firstArrivalPolicyID="28e0e399d9dc03dbb7b97ad45461cda8e7c4f24c85f91b863b8b6374";
    let firstArivalList;
    try{firstArivalList = nftsInWallet.reduce(function(list,asset) {if(asset['policy']==firstArrivalPolicyID){list.push(asset['name']);}return list;},[]);
    }catch{firstArivalList=[];}
    setFirstArrivalsList(firstArivalList);
        //zi babies collection
        //console.log(m+++"/"+n)    
    let ziBabiesPolicyID="20b6630b045014e4ee2c410329d36e192bf102155692308df69f4eff";
    let ziBabiesList;
    try{ziBabiesList=nftsInWallet.reduce(function(list,asset) {if(asset['policy']==ziBabiesPolicyID){list.push(asset['name']);}return list;},[]);
    }catch{ziBabiesList=[];}
    setZiBabiesList(ziBabiesList);
    //console.log(m+++"/"+n)
        //tree of life collection
    let treeOfLifePolicyID="334dc668ba87d3a0caa8cd1112d9ea3068e32ce114cb9375a49d289d";
    let treeOfLifeList;
    try{treeOfLifeList=nftsInWallet.reduce(function(list,asset) {if(asset['policy']==treeOfLifePolicyID){list.push(asset['name']);}return list;},[]);
    }catch{treeOfLifeList=[];}    setTreeOfLifeList(treeOfLifeList);
    //console.log(m+++"/"+n)
        //tree of life collection
    let gnomiesOGPolicyID=getPolicyIdOf("GnomiesOG");
    let gnomiesOGList
    try{gnomiesOGList=nftsInWallet.reduce(function(list,asset) {if(asset['policy']==gnomiesOGPolicyID){list.push(asset['name']);}return list;},[]);
    }catch{gnomiesOGList=[];}    setGnomiesOGList(gnomiesOGList);
    //console.log(m+++"/"+n)
        //tree of life collection
    let freddieTheWhalePolicyID=getPolicyIdOf("FreddieTheWhale");
    let freddieTheWhaleList
    try{freddieTheWhaleList=nftsInWallet.reduce(function(list,asset) {if(asset['policy']==freddieTheWhalePolicyID){list.push(asset['name']);}return list;},[]);
    }catch{freddieTheWhaleList=[];}    setFreddieTheWhaleList(freddieTheWhaleList);
    //console.log(m+++"/"+n)
        //tree of life collection
    let jarHeadsPolicyID=getPolicyIdOf("JarHeads");
    let jarHeadsList
    try{jarHeadsList=nftsInWallet.reduce(function(list,asset) {if(asset['policy']==jarHeadsPolicyID){list.push(asset['name']);}return list;},[]);
    }catch{jarHeadsList=[];}    setJarHeadsList(jarHeadsList);
    //console.log(m+++"/"+n)
        //tree of life collection
    let nekomimiPolicyID=getPolicyIdOf("Nekomimi");
    let nekomimiList
    try{nekomimiList=nftsInWallet.reduce(function(list,asset) {if(asset['policy']==nekomimiPolicyID){list.push(asset['name']);}return list;},[]);
    }catch{nekomimiList=[];}    setNekomimiList(nekomimiList);
    //console.log(m+++"/"+n)
        //tree of life collection
    let overExposedGenesisPolicyID=getPolicyIdOf("OverExposedGenesis");
    let overExposedGenesisList
    try{overExposedGenesisList=nftsInWallet.reduce(function(list,asset) {if(asset['policy']==overExposedGenesisPolicyID){list.push(asset['name']);}return list;},[]);
    }catch{overExposedGenesisList=[];}    setOverExposedGenesisList(overExposedGenesisList);
    //console.log(m+++"/"+n)
        //tree of life collection
    let mekanismPolicyID=getPolicyIdOf("Mekanism");
    let mekanismList
    try{mekanismList=nftsInWallet.reduce(function(list,asset) {if(asset['policy']==mekanismPolicyID){list.push(asset['name']);}return list;},[]);
    }catch{mekanismList=[];}    setMekanismList(mekanismList);
    //console.log(m+++"/"+n)
        //tree of life collection
    let mekanismCachePolicyID=getPolicyIdOf("MekanismCache");
    let mekanismCacheList
    try{mekanismCacheList=nftsInWallet.reduce(function(list,asset) {if(asset['policy']==mekanismCachePolicyID){list.push(asset['name']);}return list;},[]);
    }catch{mekanismCacheList=[];}    setMekanismCacheList(mekanismCacheList);
    //console.log(m+++"/"+n)
        //tree of life collection
    let shroomiesOGPolicyID=getPolicyIdOf("ShroomiesOG");
    let shroomiesOGList
    try{shroomiesOGList=nftsInWallet.reduce(function(list,asset) {if(asset['policy']==shroomiesOGPolicyID){list.push(asset['name']);}return list;},[]);
    }catch{shroomiesOGList=[];}    setShroomiesOGList(shroomiesOGList);    
    //console.log(m+++"/"+n)
        //tree of life collection
    let uglyBrosS1PolicyID=getPolicyIdOf("UglyBrosS1");
    let uglyBrosS1List
    try{uglyBrosS1List=nftsInWallet.reduce(function(list,asset) {if(asset['policy']==uglyBrosS1PolicyID){list.push(asset['name']);}return list;},[]);
    }catch{uglyBrosS1List=[];}    setUglyBrosS1List(uglyBrosS1List);
    nftsCountOf['UglyBrosS1']=uglyBrosS1List.length;
    //console.log(m+++"/"+n)
        //tree of life collection
    let uglyBrosXmasPolicyID=getPolicyIdOf("UglyBrosXmas");
    let uglyBrosXmasList
    try{uglyBrosXmasList=nftsInWallet.reduce(function(list,asset) {if(asset['policy']==uglyBrosXmasPolicyID){list.push(asset['name']);}return list;},[]);
    }catch{uglyBrosXmasList=[];}    setUglyBrosXmasList(uglyBrosXmasList);
    nftsCountOf['UglyBrosXmas']=uglyBrosXmasList.length;
    //console.log(m+++"/"+n)
            //tree of life collection
    let uglyBrosCommunityPolicyID=getPolicyIdOf("UglyBrosCommunity");
    let uglyBrosCommunityList
    try{uglyBrosCommunityList=nftsInWallet.reduce(function(list,asset) {if(asset['policy']==uglyBrosCommunityPolicyID){list.push(asset['name']);}return list;},[]);
    }catch{uglyBrosCommunityList=[];}    setUglyBrosCommunityList(uglyBrosCommunityList);
    nftsCountOf['UglyBrosCommunity']=uglyBrosCommunityList.length;
    //console.log(m+++"/"+n)
            //tree of life collection
    let uglyBrosS2PolicyID=getPolicyIdOf("UglyBrosS2");
    let uglyBrosS2List
    try{uglyBrosS2List=nftsInWallet.reduce(function(list,asset) {if(asset['policy']==uglyBrosS2PolicyID){list.push(asset['name']);}return list;},[]);
    }catch{uglyBrosS2List=[];}    setUglyBrosS2List(uglyBrosS2List);
    nftsCountOf['UglyBrosS2']=uglyBrosS2List.length;
    //console.log(m+++"/"+n)
            //tree of life collection
    let whatTheDuckVIPBoxPolicyID=getPolicyIdOf("WhatTheDuckVIPBox");
    let whatTheDuckVIPBoxList
    try{whatTheDuckVIPBoxList=nftsInWallet.reduce(function(list,asset) {if(asset['policy']==whatTheDuckVIPBoxPolicyID){list.push(asset['name']);}return list;},[]);
    }catch{whatTheDuckVIPBoxList=[];}    setWhatTheDuckVIPBoxList(whatTheDuckVIPBoxList);
    //console.log(m+++"/"+n)
            //tree of life collection
    let whatTheDuckPolicyID=getPolicyIdOf("WhatTheDuck");
    let whatTheDuckList
    try{whatTheDuckList=nftsInWallet.reduce(function(list,asset) {if(asset['policy']==whatTheDuckPolicyID){list.push(asset['name']);}return list;},[]);
    }catch{whatTheDuckList=[];}    setWhatTheDuckList(whatTheDuckList);
    //console.log(m+++"/"+n)
    
    //console.log("concatinating data...")
    // get nfts ipfs hashes
    let numberOfSNFTs = (firstArivalList.length + ziBabiesList.length + treeOfLifeList.length 
                        + gnomiesOGList.length + jarHeadsList.length + freddieTheWhaleList.length
                        + nekomimiList.length + overExposedGenesisList.length + mekanismList.length
                        + mekanismCacheList.length + shroomiesOGList.length + uglyBrosS1List.length
                        + uglyBrosXmasList.length + uglyBrosCommunityList.length + uglyBrosS2List.length
                        + whatTheDuckVIPBoxList.length + whatTheDuckList.length)
    let nftsList = new Array(numberOfSNFTs);

    let startAt = 0
    let endAt = firstArivalList.length;
    for(let i=0;i<endAt;i++){
        nftsList[i+startAt]=firstArivalList[i];
    }
    startAt += firstArivalList.length;
    endAt = ziBabiesList.length;
    for(let i=0;i<endAt;i++){
        nftsList[i+startAt]=ziBabiesList[i];
    }   
    startAt += ziBabiesList.length;
    endAt = treeOfLifeList.length;
    for(let i=0;i<endAt;i++){
        nftsList[i+startAt]=treeOfLifeList[i];
    }    
    startAt += treeOfLifeList.length;
    endAt = gnomiesOGList.length;
    for(let i=0;i<endAt;i++){
        nftsList[i+startAt]=gnomiesOGList[i];
    }       
    startAt += gnomiesOGList.length;
    endAt = jarHeadsList.length;
    for(let i=0;i<endAt;i++){
        nftsList[i+startAt]=jarHeadsList[i];
    }     
    startAt += jarHeadsList.length;
    endAt = freddieTheWhaleList.length;
    for(let i=0;i<endAt;i++){
        nftsList[i+startAt]=freddieTheWhaleList[i];
    }     
    startAt += freddieTheWhaleList.length;
    endAt = nekomimiList.length;
    for(let i=0;i<endAt;i++){
        nftsList[i+startAt]=nekomimiList[i];
    }   
    startAt += nekomimiList.length;
    endAt = overExposedGenesisList.length;
    for(let i=0;i<endAt;i++){
        nftsList[i+startAt]=overExposedGenesisList[i];
    }   
    startAt += overExposedGenesisList.length;
    endAt = mekanismList.length;
    for(let i=0;i<endAt;i++){
        nftsList[i+startAt]=mekanismList[i];
    }   
    startAt += mekanismList.length;
    endAt = mekanismCacheList.length;
    for(let i=0;i<endAt;i++){
        nftsList[i+startAt]=mekanismCacheList[i];
    }   
    startAt += mekanismCacheList.length;
    endAt = shroomiesOGList.length;
    for(let i=0;i<endAt;i++){
        nftsList[i+startAt]=shroomiesOGList[i];
    }   
    startAt += shroomiesOGList.length;
    endAt = uglyBrosS1List.length;
    for(let i=0;i<endAt;i++){
        nftsList[i+startAt]=uglyBrosS1List[i];
    }   
    startAt += uglyBrosS1List.length;
    endAt = uglyBrosXmasList.length;
    for(let i=0;i<endAt;i++){
        nftsList[i+startAt]=uglyBrosXmasList[i];
    }   
    startAt += uglyBrosXmasList.length;
    endAt = uglyBrosCommunityList.length;
    for(let i=0;i<endAt;i++){
        nftsList[i+startAt]=uglyBrosCommunityList[i];
    }   
    startAt += uglyBrosCommunityList.length;
    endAt = uglyBrosS2List.length;
    for(let i=0;i<endAt;i++){
        nftsList[i+startAt]=uglyBrosS2List[i];
    }   
    startAt += uglyBrosS2List.length;
    endAt = whatTheDuckVIPBoxList.length;
    for(let i=0;i<endAt;i++){
        nftsList[i+startAt]=whatTheDuckVIPBoxList[i];
    }   
    startAt += whatTheDuckVIPBoxList.length;
    endAt = whatTheDuckList.length;
    for(let i=0;i<endAt;i++){
        nftsList[i+startAt]=whatTheDuckList[i];
    }   


    //console.log("assets loaded.")
    const collectionsDictionary = {
        'FirstArrivals':firstArivalList,
        'ZiBabies':ziBabiesList,
        'TreeOfLife':treeOfLifeList,
        'FreddieTheWhale' : freddieTheWhaleList,
        'GnomiesOG' :gnomiesOGList,
        'JarHeads' : jarHeadsList,
        'Nekomimi':nekomimiList,
        'OverExposedGenesis':overExposedGenesisList,
        'Mekanism':mekanismList,
        'MekanismCache':mekanismCacheList,
        'ShroomiesOG':shroomiesOGList,
        'UglyBrosS1':uglyBrosS1List,
        'UglyBrosXmas':uglyBrosXmasList,
        'UglyBrosCommunity':uglyBrosCommunityList,
        'UglyBrosS2':uglyBrosS2List,
        'WhatTheDuckVIPBox':whatTheDuckVIPBoxList,
        'WhatTheDuck':whatTheDuckList
    }
    setCollectionsDictionary(collectionsDictionary);
    setActivatedWalletSNFTsCount(nftsList.length);
    let rewardMultiplier = nftsList.length+2*treeOfLifeList.length+4*firstArivalList.length+9*ziBabiesList.length;
    //console.log("muklt: "+rewardMultiplier)
    setActivatedWalletRewardMultiplier(rewardMultiplier);
    //let baseReward = 1000000;
    setActivatedWalletEstimatedRewards(displayNumberInPrettyFormat((rewardMultiplier*baseReward).toString()));
    //console.log(nftsList)
    if(liteModeStatus!=true){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'
                    
                },
        body: JSON.stringify({ 'AssetNames': [nftsList] })
    };
    //console.log(ziBabiesList.toString());
    try{
        let response = await fetch('https://shroomsv2.adalink.io/api/get-nfts-media-references.php', requestOptions);
        NFTsMediaReference = await response.json();
        //console.log(NFTsMediaReference);
        //setNFTsMediaReference(NFTsMediaReference);
        response = await fetch('https://shroomsv2.adalink.io/api/get-nfts-name-references.php', requestOptions);
        NFTsNameReference = await response.json();
        //console.log(NFTsNameReference);
        //setNFTsNameReference(NFTsNameReference);
        //console.log(await response.text());
    }
    catch{

    }
    }
}



async function getNFTsOfCollection(policyIDScriptHash,valueBalance){

    if(valueBalance.multiasset()==undefined){
        //console.log("No NFTs in wallet.");
        return [];
    }
    if(valueBalance.multiasset().get(policyIDScriptHash)==undefined){
        //console.log("No NFTs in wallet.");
        return [];
    }
    var nftName;
    var nftList = new Array(valueBalance.multiasset().get(policyIDScriptHash).keys().len());
    const nftList_hex  = valueBalance.multiasset().get(policyIDScriptHash).keys().to_js_value();
    console.log("policy id: "+policyIDScriptHash.to_hex())
    for(let i=0;i<nftList.length;i++){
        //nftName = new TextDecoder().decode(valueBalance.multiasset().get(policyIDScriptHash).keys().get(i).name());
        //nftList.push(nftName);
        nftList[i]= Buffer.from(nftList_hex[i],'hex').toString();
    }
    console.log("assets from policy loaded")
    return nftList;
}

function showNFTsOfCollection(collectionName,nftList, multiplier = 1){
    
    
    

    let policyID=getPolicyIdOf(collectionName);
    let collectionTitle;
    switch(collectionName){
        case "FreddieTheWhale":
            collectionTitle="Freddie The Whale - 1x Multiplier";
            break;
        case "TreeOfLife":
            collectionTitle=<>Tree Of Life - <em style={{color:'gold'}}>3x</em> Multiplier</>;
            break;
        case "GnomiesOG":
            collectionTitle="Gnomies OG - 1x Multiplier";
            break;
        case "GnomiesTheReturn":
            collectionTitle="Gnomies The Return - 2x Multiplier";
            break;
        case "JarHeads":
            collectionTitle="Jar Heads - 1x Multiplier";
            break;
        case "Nekomimi":
            collectionTitle="Nekomimi - 1x Multiplier";
            break; 
        case "OverExposedGenesis":
            collectionTitle="Over Exposed Genesis - 1x Multiplier";
            break;
        case "Mekanism":
            collectionTitle="Mekanism - 1x Multiplier";
            break;    
        case "MekanismCache":
            collectionTitle="Mekanism Cache - 1x Multiplier";
            break;
        case "ShroomiesOG":
            collectionTitle="Shroomies OG - 1x Multiplier";
            break; 
        case "UglyBrosS1":
            collectionTitle="Ugly Bros S1 - "+multiplier+"x Multiplier";
            break;
        case "UglyBrosXmas":
            collectionTitle="Ugly Bros Xmas - "+multiplier+"x Multiplier";
            break;   
        case "UglyBrosCommunity":
            collectionTitle="Ugly Bros x Ugly Community - "+multiplier+"x Multiplier";
            break;
        case "UglyBrosS2":
            collectionTitle="Ugly Bros S2 - "+multiplier+"x Multiplier";
            break; 
        case "WhatTheDuckVIPBox":
            collectionTitle="WTD VIP Box - 1x Multiplier";
            break;
        case "WhatTheDuck":
            collectionTitle="What The Duck - 1x Multiplier";
            break;
        case "FirstArrivals":
            collectionTitle=<>First Arrivals - <em style={{color:'gold'}}>5x</em> Multiplier</>;
            break;
        case "ZiBabies":
            collectionTitle=<>Zi Babies - <em style={{color:'gold'}}>10x</em> Multiplier</>;
            break;                    
    }

    if(nftList.length==0){
        return(
            <div className="collectionPool">
                <div className="normalText">{collectionTitle}</div>
                <hr className="horizontalLine"/>
                <p className="normalText" style={{color:'grey',paddingLeft:'15px'}}>No NFTs of this collection in wallet. You can check secondary market <a href={"https://www.jpg.store/collection/"+policyID} target="_blank" rel="noopener noreferrer"><em className="normalLink">here</em></a>.</p>
                <br/>
            </div>
        );
    }
    /*
    "https://infura-ipfs.io/ipfs/QmTtk4oNzTaY9sMfjJPtnqtk6JBnpAykRPUdJmgHZfqHVb/1.png"
    {constructImgSrc(policyID+"."+nftName)}
    <img className="nftImage" loading="lazy" width={(window.innerWidth-imgAParam)*3/8>150?"150":(window.innerWidth-imgAParam)*3/8} height={(window.innerWidth-imgAParam)*3/8>150?"150":(window.innerWidth-imgAParam)*3/8} src={"https://ipfs.io/ipfs/"+nftsMediaReference[nftName]} alt={nftName}/>
    */
    let imgAParam= 0;
    for(let i=0;i<document.getElementsByClassName('assetsDisplayArea').length;i++){
        if (imgAParam<document.getElementsByClassName('assetsDisplayArea')[i].clientWidth)
            imgAParam=document.getElementsByClassName('assetsDisplayArea')[i].clientWidth
    }
    
    
    let imgBParam=imgAParam/4-35;
    console.log(imgBParam)
    const htmlBlocks = nftList.map((nftName) => 
            <div key={nftName} className="nftImageContainer" style={{width:imgBParam}}>
                <img className="nftImage" loading="lazy" width={imgBParam>1500?"1500":imgBParam} height={imgBParam>1500?"1500":imgBParam} src={"https://ipfs.io/ipfs/"+NFTsMediaReference[nftName]} alt={nftName}/>
                <div className="nftImage normalText">{NFTsNameReference[nftName]}</div>
            </div>
            );
    return(
        <div className="collectionPool">
            <div className="normalText">{collectionTitle}</div>
            <hr className="horizontalLine"/>
            <div style={{overflow:'auto'}}>
                {htmlBlocks}
            </div>
            <br/>
        </div>
    );
    
}

function isWalletSizeOk(){
    //check if wallet has less than 1000 NFTs using assetsInWallet
    var connectedWalletName = document.getElementById("selectedWallet").value;
    
    if(connectedWalletName == "No wallet"){
        return true;
    }
    if(assetsInWallet == null)
        return true;
    let policyIDs = Object.keys(assetsInWallet)
    let assetsCount = 0;
    for(let i=0;i<policyIDs.length;i++){
        assetsCount+=Object.keys(assetsInWallet[policyIDs[i]]).length
    }
    
    if(assetsCount>1000){
        return false;
    }else{
        return true;
    }
    //
}


async function registerWallet(setActivatedWalletRegestrationStatus,setActivatedWalletRegestrationEpoch,setActivatedWalletPreviousRewards,setActivatedWalletUnclaimedRewards,setActivatedWalletTotalRewards,setActivatedWalletClaimedRewards,setBufferWindowTrigger,setPopupWindowTrigger,setPopupWindowMsg,setSuccessWindowTrigger,setSuccessWindowMsgMode){
    
    var connectedWalletName = document.getElementById("selectedWallet").value;
    
    if(connectedWalletName == "No wallet"){
        setPopupWindowMsg("Please connnect your wallet first.");
        setPopupWindowTrigger(true);
        return;
    }
    setBufferWindowTrigger(true);     
    
    let txHashValue;
    let errorMsg;




    await buildTxAndSign(0)
                .then(txHash => {txHashValue=txHash})
                .catch(err => {console.log(err); errorMsg=err});
    //console.log("txHash: "+txHashValue);
    let tries=0;
    while(txHashValue==undefined && tries++<4 && errorMsg.code!=2){
        await buildTxAndSign(1)
                .then(txHash => {txHashValue=txHash})
                .catch(err => {console.log(err); errorMsg=err});
    console.log("txHash: "+txHashValue);
    } 
    //txHashValue="stake1u9rfmht8f6ym8gk8cfr7d2y0cq0lgfq88plglz3ngpw4czg5f5jmv";
    var selectedToken = document.getElementById('selectedTokenElement').value;
    switch(selectedToken){
        case "UGLY":
            selectedToken = "UGLY"
            break;
    }
    //txHashValue="testing";
    if(txHashValue != undefined){
        const usedAddresses = await WalletInterface.getUsedAddresses();
        let changeAddress = C.Address.from_bytes(Buffer.from(usedAddresses[0],"hex"));
        let walletAddress = changeAddress.to_bech32("addr");
        const rewardAddresses = await WalletInterface.getRewardAddresses();
    
        let rewardAddress = C.Address.from_bytes(Buffer.from(rewardAddresses[0],"hex"));
        let stakeAddress = rewardAddress.to_bech32("stake");

        //let rewardAddress = txHashValue; //notice the retirn value is not actually tx hash but stake address
        let response;
        if(selectedToken!="Shroom"){
            response = await fetch('https://shroomsv2.adalink.io/api/register-wallet-in-staking-summary-of-token.php?stakeAddress='+stakeAddress+'&table='+selectedToken+'RewardSummary',{cache:"reload"});
            response = await fetch('https://shroomsv2.adalink.io/api/insert-registration-tx-of-token.php?txHash='+txHashValue+'&address='+walletAddress+'&table='+selectedToken+'Registration',{cache:"reload"});
        }    
        else{
            response = await fetch('https://shroomsv2.adalink.io/api/register-wallet-in-staking-summary.php?stakeAddress='+stakeAddress,{cache:"reload"});
            response = await fetch('https://shroomsv2.adalink.io/api/insert-registration-tx.php?txHash='+txHashValue+'&address='+walletAddress,{cache:"reload"});
        }

        setActivatedWalletRegestrationStatus("Registered");
        setActivatedWalletRegestrationEpoch("Registered this epoch");
        setActivatedWalletPreviousRewards("0");
        setActivatedWalletUnclaimedRewards("0");
        setActivatedWalletTotalRewards("0");
        setActivatedWalletClaimedRewards("0");
        setSuccessWindowMsgMode(0);
        setSuccessWindowTrigger(true);
    }
    else{
        if(errorMsg=="missing input for some native asset"){
            setPopupWindowMsg("Unsuffecient funds. The ADA in the wallet locked with native assets, please fund your wallet with some ADA.");
            setPopupWindowTrigger(true);
        }
        else{
            //setPopupWindowMsg("Transaction was not submitted, please try again.");
            if(errorMsg.info != undefined)
                setPopupWindowMsg(errorMsg.info);
            else
                setPopupWindowMsg("Transaction was not submitted, please try again.");
            setPopupWindowTrigger(true);
        }
    }
    

    setBufferWindowTrigger(false);


}

async function buildTxAndSign(utxoSelectionStrategy){
    
    const linearFeeAValue="44";
    const linearFeeBValue="155381";
    const minUTxOValue="1000000";
    const maxTxSizeValue="8000";
    const linearFeeA=C.BigNum.from_str(linearFeeAValue);
    const linearFeeB=C.BigNum.from_str(linearFeeBValue);
    const linearFee = C.LinearFee.new(linearFeeA,linearFeeB);
    
    const txBuilderConfig = C.TransactionBuilderConfigBuilder.new()
    .fee_algo(linearFee)
    .pool_deposit(C.BigNum.from_str('500000000'))
    .key_deposit(C.BigNum.from_str('2000000'))
    .max_value_size(5000)
    .max_tx_size(16384)
    .coins_per_utxo_byte(C.BigNum.from_str('4310'))
    .ex_unit_prices(C.ExUnitPrices.from_float(1,1))
    .collateral_percentage(0.05)
    .max_collateral_inputs(2)
    .build();

    const txBuilder = C.TransactionBuilder.new(txBuilderConfig);
    
    //const WalletInterface = await getWalletInterface(document.getElementById("connectedWallet").innerHTML);

    //get utxos of wallet
    let paymentValue = C.Value.new(C.BigNum.from_str("2000000"));


    const utxosList = await WalletInterface.getUtxos();
    
    //console.log("getUtxos result: "+utxosList.length)
    let unspentTxOutputs = [];
    
    if (utxosList != undefined)
        unspentTxOutputs = utxosList.map(u => C.TransactionUnspentOutput.from_bytes(Buffer.from(u, 'hex' )));
    else{
        return;
    }
    let transactionUnspentOutputs=C.TransactionUnspentOutputs.new();
    unspentTxOutputs.forEach(utxo => {
        transactionUnspentOutputs.add(utxo);
    });

    //hardcode the node wallet
    var selectedToken = document.getElementById('selectedTokenElement').value;
    let nodeAddress;
    switch(selectedToken){
        case 'Shroom':
            nodeAddress = C.Address.from_bech32("addr1q883x9jcl2eypk8cvfguc5ys4xf8hdnlztrhaukmx40ude0mhrjjygfcapzxksz3uxu52mv9e50y2tv4faxnkayx0zgqyzkzte");
            break;
        case 'UGLY' :
            nodeAddress = C.Address.from_bech32("addr1q9nq2xh73gd3jx95u9xddtxdcl5809nvfal684nn52cd3a8mhrjjygfcapzxksz3uxu52mv9e50y2tv4faxnkayx0zgqgcqykn");
            break;
    }
    

    //set the changeWallet as well the readable "addr1..." address of the wallet
    const usedAddresses = await WalletInterface.getUsedAddresses();
    let changeAddress = C.Address.from_bytes(Buffer.from(usedAddresses[0],"hex"));
    let walletAddress = changeAddress.to_bech32("addr");

    let outputs = C.TransactionOutputs.new();
    let output1 = C.TransactionOutput.new(nodeAddress,paymentValue);
    outputs.add(output1);

    /*for (let i = 0; i < utxos.length; i++) {
        const utxo = utxos[i];
        txBuilder.add_input(
          utxo.output().address(),
          utxo.input(),
          utxo.output().amount()
        );
    }*/

    for(let i=0; i<outputs.len(); i++){
        txBuilder.add_output(outputs.get(i));
    }
    txBuilder.add_inputs_from(transactionUnspentOutputs,changeAddress);
    txBuilder.balance(changeAddress);
    //txBody = txBuilder.build();
    let transactionBuilt =  txBuilder.build_tx();

    //build tx body and add witness set to the transaction
    const txBody = transactionBuilt.body();
    const witnesses = C.TransactionWitnessSet.new();
    const transaction = C.Transaction.new(txBody,witnesses);
    
    const signatures = await WalletInterface.signTx(Buffer.from(transaction.to_bytes()).toString("hex"));
    const signedTx = C.Transaction.new(transaction.body(),C.TransactionWitnessSet.from_bytes(Buffer.from(signatures,"hex")));
    const txHash = await  WalletInterface.submitTx(Buffer.from(signedTx.to_bytes()).toString("hex"));

    return txHash;
}

function getPolicyIdOf(collectionName){

    let policyID;
    switch(collectionName){
        case "FreddieTheWhale":
            policyID="c977fb0c69646aeeaf1754514d6cf389996f4b9084ca43194ff2abeb";
            break;
        case "TreeOfLife":
            policyID="334dc668ba87d3a0caa8cd1112d9ea3068e32ce114cb9375a49d289d";
            break;
        case "GnomiesOG":
            policyID="b5fe4b252a54d6c39689c740e9f5b18355b9b983da2295cee6906420";
            break;
        case "GnomiesTheReturn":
            policyID="465c5f64ad4127d94946a071aed3be54ddf295731d0f0a18c07346d4";
            break;
        case "JarHeads":
            policyID="eba255af1c4234ea258d3d0ef1ae3bc715432de4b3e6ba481214a4ca";
            break;
        case "Nekomimi":
            policyID="20c19b6a1084802a36fa680116f410efd2a0f025e31590ff35e4a10a";
            break; 
        case "OverExposedGenesis":
            policyID="5fd604255c9c14dbf3930b78aeb2ccb70fb553bccf898696e92d5191";
            break;
        case "Mekanism":
            policyID="ffa56051fda3d106a96f09c3d209d4bf24a117406fb813fb8b4548e3";
            break;    
        case "MekanismCache":
            policyID="5d780417838525ce9a41782e3760f02633bc0a88a3b98bbcd990848f";
            break;
        case "ShroomiesOG":
            policyID="6ecfd93553a98ae41c5006c23f18637b0784aa9ac61325a26b11ac23";
            break; 
        case "UglyBrosS1":
            policyID="12f9d9446c422caa870948bae1b8844c26c64958943a3954103b034f";
            break;
        case "UglyBrosXmas":
            policyID="89c9857b0239f8d1074a8f66038c6734297c18538613c710673ea7a6";
            break;   
        case "UglyBrosCommunity":
            policyID="9e7d7a86edeb47bb78a6b98a0c0556028eddac4c4ec91e778b62ae15";
            break;
        case "UglyBrosS2":
            policyID="d7726f6b882c6fc2ca0cd96c51e5328e1d577d789b085ee0fbe23bf7";
            break; 
        case "WhatTheDuckVIPBox":
            policyID="e4043e606e71239c4611ace8e343f52a5cdd420e7c80700b8b67ef15";
            break;
        case "WhatTheDuck":
            policyID="258945167d9e2d6a56997e2763ab2e73002d05ddae39989939ecae39";
            break;
        case "FirstArrivals":
            policyID="28e0e399d9dc03dbb7b97ad45461cda8e7c4f24c85f91b863b8b6374";
            break;
        case "ZiBabies":
            policyID="20b6630b045014e4ee2c410329d36e192bf102155692308df69f4eff";
            break;   
        case "UglyToken":
            policyID="95a15fafc24be11478735df6fa4eaba6e6319c27f24ced13f39bea44";
            break;
        case "UGLY":
            policyID="95a15fafc24be11478735df6fa4eaba6e6319c27f24ced13f39bea44";
            break;
    }
    return policyID;
}

function getProjectTokenName(projectName){
    let tokenName;
    switch(projectName){
        case "UglyBros":
            tokenName="UGLY";
            break;
    }
    return tokenName;
}

function getTokenDecimals(tokenName){
    let decimals;
    switch(tokenName){
        case "UglyToken":
            decimals=6;
            break;
        case "UGLY":
        decimals=6;
        break;   
    }
    return decimals;
}

function getNFTsCountOfProject(projectName){
    switch(projectName){
        case 'UglyBros':
            return nftsCountOf['UglyBrosCommunity']+nftsCountOf['UglyBrosS1']+nftsCountOf['UglyBrosS2']+nftsCountOf['UglyBrosXmas'];
        break;
    }
}

function getMultiplierOfProject(projectName){
    switch(projectName){
        case 'UglyBros':
            return (nftsCountOf['UglyBrosCommunity']*collectionBoost['UglyBrosCommunity']+
                    nftsCountOf['UglyBrosS1']*collectionBoost['UglyBrosS1']+
                    nftsCountOf['UglyBrosS2']*collectionBoost['UglyBrosS2']+
                    nftsCountOf['UglyBrosXmas']*collectionBoost['UglyBrosXmas']);
        break;
    }
}

function getselectedTokenIcon(selectedToken){
    switch(selectedToken){
        case "Shroom":
            return logo;
        case "UGLY":
            return uglyTokenIcon;
    }
}

function getSucessClaimImg(selectedToken){
    switch(selectedToken){
        case "Shroom":
            return mintSuccessfulImg;
        case "UGLY":
            return uglyClaimSuccessfulImg;
    } 
}



let response = await fetch('https://shroomsv2.adalink.io/api/get-registered-policies-names.php',{cache:"reload"});
let collectionsListString = JSON.parse(await response.text());
let collectionsList = JSON.parse(collectionsListString);
let searchedCollectionsList = Array();//JSON.parse(collectionsListString);

const queryParams = new URLSearchParams(window.location.search);
const encryptedTxHash = queryParams.get('raffle');
let listedRaffle;
if (encryptedTxHash != null){
	if (encryptedTxHash == "UGLY"){
        listedRaffle = "UGLY";
    }
    else{
        response =  await fetch("https://shroomsv2.adalink.io/api/get-listed-raffle.php?raffle="+encryptedTxHash,{cache:"reload"});
        listedRaffle = JSON.parse(await response.text());
    }
}


response = await fetch('https://shroomsv2.adalink.io/api/get-listed-raffles.php',{cache:"reload"});
let listedRaffles,listedRafflesEndingSoonSorted,listedRafflesRecentSorted;
let responseText;
response.text().then((value) => {
    responseText=value;
    listedRaffles = JSON.parse(responseText);
    listedRafflesEndingSoonSorted = JSON.parse(responseText);
    listedRafflesRecentSorted = JSON.parse(responseText);
    listedRafflesEndingSoonSorted.sort((a,b) => (parseInt(a['EndsAt'])>parseInt(b['EndsAt']))?1:-1);
    listedRafflesRecentSorted.sort((a,b) => (parseInt(a['StartedAt'])>parseInt(b['StartedAt']))?-1:1);
})
/*let listedRaffles = JSON.parse(await response.text())*/

response = await fetch('https://shroomsv2.adalink.io/api/get-ended-raffles.php',{cache:"reload"});
let endedRaffles;// = JSON.parse(await response.text());
response.text().then((value) => {
    responseText=value;
    endedRaffles = JSON.parse(responseText);
    endedRaffles.reverse();
})

response = await fetch('https://shroomsv2.adalink.io/api/get-registered-policies-list.php',{cache:"reload"});
let registeredPolicies = JSON.parse(await response.text());
registeredPolicies = JSON.parse(registeredPolicies);

response = await fetch('https://shroomsv2.adalink.io/api/get-total-entries-counts.php',{cache:"reload"});
let totalEntries = JSON.parse(await response.text());
totalEntries = JSON.parse(totalEntries);

let myEntries={'raffle0':0};





function Home(){

    const [cookies, setCookie, removeCookie] = useCookies(['show-beta-aggreement']);
    const [showBetaTermsAndConditions,setShowBetaTermsAndConditions] = useState(false);

    const [activatedWalletIcon,setActivatedWalletIcon]=useState(walletIcon);
    const [activatedWalletName,setActivatedWalletName]=useState("No wallet");
    const [activatedWalletAddress,setActivatedWalletAddress]=useState("No address available");
    const [activatedWalletStakeAddress,setActivatedWalletStakeAddress]=useState("No stake address available");
    const [activatedWalletBalance,setActivatedWalletBalance]=useState("N/A");
    const [activatedWalletShroomsBalance,setActivatedWalletShroomsBalance]=useState("N/A");
    const [activatedWalletSNFTsCount,setActivatedWalletSNFTsCount]=useState("N/A");
    const [activatedWalletRegestrationStatus,setActivatedWalletRegestrationStatus]=useState("N/A");
    const [activatedWalletRegestrationEpoch,setActivatedWalletRegestrationEpoch]=useState("N/A");
    const [activatedWalletRewardMultiplier,setActivatedWalletRewardMultiplier]=useState("N/A");
    const [activatedWalletPreviousRewards,setActivatedWalletPreviousRewards]=useState("N/A");
    const [activatedWalletEstimatedRewards,setActivatedWalletEstimatedRewards]=useState("N/A");
    const [activatedWalletUnclaimedRewards,setActivatedWalletUnclaimedRewards]=useState("N/A");
    const [activatedWalletClaimedRewards,setActivatedWalletClaimedRewards]=useState("N/A");
    const [activatedWalletTotalRewards,setActivatedWalletTotalRewards]=useState("N/A");
    
    const [activatedWalletUgliesBalance,setActivatedWalletUgliesBalance]=useState("N/A");
    const [activatedWalletUglyBrosNFTsCount,setActivatedWalletUglyBrosNFTsCount]=useState("N/A");
    const [activatedWalletUglyBrosRegestrationStatus,setActivatedWalletUglyBrosRegestrationStatus]=useState("N/A");
    const [activatedWalletUglyBrosRegestrationEpoch,setActivatedWalletUglyBrosRegestrationEpoch]=useState("N/A");
    const [activatedWalletUglyBrosRewardMultiplier,setActivatedWalletUglyBrosRewardMultiplier]=useState("N/A");
    const [activatedWalletUglyBrosPreviousRewards,setActivatedWalletUglyBrosPreviousRewards]=useState("N/A");
    const [activatedWalletUglyBrosEstimatedRewards,setActivatedWalletUglyBrosEstimatedRewards]=useState("N/A");
    const [activatedWalletUglyBrosUnclaimedRewards,setActivatedWalletUglyBrosUnclaimedRewards]=useState("N/A");
    const [activatedWalletUglyBrosClaimedRewards,setActivatedWalletUglyBrosClaimedRewards]=useState("N/A");
    const [activatedWalletUglyBrosTotalRewards,setActivatedWalletUglyBrosTotalRewards]=useState("N/A");



    const [firstArrivalsList,setFirstArrivalsList]=useState([]);
    const [ziBabiesList,setZiBabiesList]=useState([]);
    const [treeOfLifeList,setTreeOfLifeList]=useState([]);
    const [freddieTheWhaleList,setFreddieTheWhaleList]=useState([]);
    const [gnomiesOGList,setGnomiesOGList]=useState([]);
    const [gnomiesTheReturnList,setGnomiesTheReturnList]=useState([]);
    const [jarHeadsList,setJarHeadsList]=useState([]);
    const [nekomimiList,setNekomimiList]=useState([]);
    const [overExposedGenesisList,setOverExposedGenesisList]=useState([]);
    const [mekanismList,setMekanismList]=useState([]);
    const [mekanismCacheList,setMekanismCacheList]=useState([]);
    const [shroomiesOGList,setShroomiesOGList]=useState([]);
    const [uglyBrosS1List,setUglyBrosS1List]=useState([]);
    const [uglyBrosXmasList,setUglyBrosXmasList]=useState([]);
    const [uglyBrosCommunityList,setUglyBrosCommunityList]=useState([]);
    const [uglyBrosS2List,setUglyBrosS2List]=useState([]);
    const [whatTheDuckVIPBoxList,setWhatTheDuckVIPBoxList]=useState([]);
    const [whatTheDuckList,setWhatTheDuckList]=useState([]);

    const [collectionsDictionary,setCollectionsDictionary]=useState();

    const [selectedDashboard,setSelectedDashboard] = useState("");

    const [mainContainerTrigger,setMainContainerTrigger] = useState(true);
    const [selectedToken,setSelectedToken]=useState("Shroom");
    const [selectTokenTrigger,setSelectTokenTrigger] =useState(false);

    const [selectedProject,setSelectedProject]=useState("fourzin");

    const [adaPaymentAmount, setAdaPaymentAmount] = useState("0");
    const [numberOfNFTs,setNumberOfNFTs]=useState(1);

    const [popupWindowTrigger, setPopupWindowTrigger] = useState(false);
    const [popupWindowMsg, setPopupWindowMsg] = useState("Warning Message");


    const [warningWindowTriger, setWarningWindowTrigger] = useState(false);
    const [warningWindowMsg, setWarningWindowMsg] = useState("Collection sold out! 🎉"+ "\n" +"Do not foget to join the grand reveal in 24 hours. Thank you.");

    const [message,setMessage] = useState("");
    const [messageWindowTrigger,setMessageWindowTrigger] = useState(false);

    const [confrimRegistrationWindowTrigger,setConfrimRegistrationWindowTrigger] = useState(false);

    const [msgForEternlMobile,setMsgForEternlMobile] = useState("");

    const [bufferWindowTrigger, setBufferWindowTrigger] = useState(false);
    const [bufferWindowMsg,setBufferWindowMsg] = useState("Loading assets...");
    const [assetsBufferWindowTrigger,setAssetsBufferWindowTrigger] = useState(false);
    const [successWindowTrigger, setSuccessWindowTrigger] = useState(false);
    const [successWindowMsgMode,setSuccessWindowMsgMode]=useState(0);
    const [successRaffleCreationWindowTrigger,setSuccessRaffleCreationWindowTrigger] =useState(false);
    const [successRaffleEntryWindowTrigger,setSuccessRaffleEntryWindowTrigger] =useState(false);
    const [successATMUsageWindowTrigger,setSuccessATMUsageWindowTrigger] =useState(false);
    const [selectNFTMenuTrigger, setSelectNFTMenuTrigger] = useState(false)
    const [raffleParametersWindowTrigger,setRaffleParametersWindowTrigger] = useState(false);
    const [raffleEntryWindowTrigger,setRaffleEntryWindowTrigger] = useState(false);
    const [endedRaffleDetailsWindowTrigger,setEndedRaffleDetailsWindowTrigger] = useState(false);
    const [raffleCoin,setRaffleCoin] = useState("ADA");
    const [raffleDuration,setRaffleDuration] =useState("1");
    const [raffleEntryAmount,setRaffleEntryAmount]=useState("0");
    const [selectedRaffleDetails,setSelectedRaffleDetails]=useState();
    const [raffleEntriesCount,setRaffleEntriesCount] = useState("1");
    const [termsAndConditionsWindowTrigger,setTermsAndConditionsWindowTrigger] = useState(false);
    const [raffleChargeRatesWindowTrigger,setRaffleChargeRatesWindowTrigger] = useState(false);
    const [fungiTokenWindowTrigger,setFungiTokenWindowTrigger]=useState(false);
    const [raffleConfirmationWindowTrigger,setRaffleConfirmationWindowTrigger] = useState(false);
    const [raffleConfirmationWindowMsg,setRaffleConfirmationWindowMsg] = useState("");

    const[showCollectionSearchResult,setShowCollectionSearchResult]=useState(false);
    const[showFilterElements,setShowFilterElements]=useState(true);
    const[raffleCollectionFilter,setRaffleCollectionFilter]=useState("All");
    const[raffleCreatedByFilter,setRaffleCreatedByFilter]=useState("All");
    const[raffleEntryTypeFilter,setRaffleEntryTypeFilter]=useState("All");
    const[raffleCoinFilter,setRaffleCoinFilter]=useState("All");
    const[raffleTimeFilter,setRaffleTimeFilter]=useState("Active");

    const [priceTableWindowTrigger, setPriceTableWindowTrigger] = useState(false);
    const [fungiAmount,setFungiAmount]=useState("0");

    const [currentEpoch,setCurrentEpoch] = useState("");
    const [slotsSinceCurrentEpochStart,setSlotsSinceCurrentEpochStart] = useState(0);
    const [daysUntilNextEpoch,setDaysUntilNextEpoch] = useState("");
    const [hoursUntilNextEpoch,setHoursUntilNextEpoch] = useState("");
    const [minutesUntilNextEpoch,setMinutesUntilNextEpoch] = useState("");
    const [secondsUntilNextEpoch,setSecondsUntilNextEpoch] = useState("");
    const [endOfEpochMsg,setEndOfEpochMsg] = useState("");

    const [liteModeStatus,setLiteModeStatus] = useState(false);

    let popupWindowRef = useClickOutsite(() => setPopupWindowTrigger(false),"outSidePopupWindow");
    let messageWindowRef = useClickOutsite(() => setMessageWindowTrigger(false),"outSideWarningWindow");
    let tokenMenuRef = useClickOutsite(() => setSelectTokenTrigger(false),"tokensMenu");
    let selectNFTMenuRef = useClickOutsite(() => setSelectNFTMenuTrigger(false),"selectNFTMenu");
    let raffleEntryWindowRef = useClickOutsite(() => setRaffleEntryWindowTrigger(false),"outSideRaffleParametersWindow");
    let endedRaffleDetailsWindowRef = useClickOutsite(() => setEndedRaffleDetailsWindowTrigger(false),"outSideRaffleParametersWindow");
    let raffleParametersWindowRef = useClickOutsite(() => setRaffleParametersWindowTrigger(false),"outSideRaffleParametersWindow");
    let termsAndConditionsWindowRef = useClickOutsite(() => setTermsAndConditionsWindowTrigger(false),"outSideTermsAndConditionsWindow");
    let raffleChargesRatesWindowRef = useClickOutsite(() => setRaffleChargeRatesWindowTrigger(false),"outSideTermsAndConditionsWindow");
    let fungiTokenWindowRef = useClickOutsite(() => setFungiTokenWindowTrigger(false),"outSideTermsAndConditionsWindow");
    let tablePriceWindowRef = useClickOutsite(() => setPriceTableWindowTrigger(false),"outSidePriceTableWindow");
    let collectionSearchResultRef = useClickOutside(() => setShowCollectionSearchResult(false),"collectionDataArea");


    function showRegistrationStatus(){
        switch(selectedToken){
            case 'Shroom':
                return activatedWalletRegestrationStatus;
            case 'UGLY':
                return activatedWalletUglyBrosRegestrationStatus;
        }
    }
    function showRegistrationEpoch(){
        switch(selectedToken){
            case 'Shroom':
                return activatedWalletRegestrationEpoch;
            case 'UGLY':
                return activatedWalletUglyBrosRegestrationEpoch;
        }
    }
    function showTokenBalance(){
        switch(selectedToken){
            case 'Shroom':
                return activatedWalletShroomsBalance;
            case 'UGLY':
                return activatedWalletUgliesBalance;
        }
    }
    function showNFTsCount(){
        switch(selectedToken){
            case 'Shroom':
                return activatedWalletSNFTsCount;
            case 'UGLY':
                return activatedWalletUglyBrosNFTsCount;
        }
    }
    function showRewardMultiplier(){
        switch(selectedToken){
            case 'Shroom':
                return activatedWalletRewardMultiplier;
            case 'UGLY':
                return activatedWalletUglyBrosRewardMultiplier;
        }
    }
    function showEstimatedRewards(){
        switch(selectedToken){
            case 'Shroom':
                return activatedWalletEstimatedRewards;
            case 'UGLY':
                return activatedWalletUglyBrosEstimatedRewards;
        }
    }
    function showPreviousRewards(){
        switch(selectedToken){
            case 'Shroom':
                return activatedWalletPreviousRewards;
            case 'UGLY':
                return activatedWalletUglyBrosPreviousRewards;
        }
    }
    function showUnclaimedRewards(){
        switch(selectedToken){
            case 'Shroom':
                return activatedWalletUnclaimedRewards;
            case 'UGLY':
                return activatedWalletUglyBrosUnclaimedRewards;
        }
    }
    function showClaimedRewards(){
        switch(selectedToken){
            case 'Shroom':
                return activatedWalletClaimedRewards;
            case 'UGLY':
                return activatedWalletUglyBrosClaimedRewards;
        }
    }
    function showTotalRewards(){
        switch(selectedToken){
            case 'Shroom':
                return activatedWalletTotalRewards;
            case 'UGLY':
                return activatedWalletUglyBrosTotalRewards;
        }
    }
    function getSetRegistrationStatus(){
        switch(selectedToken){
            case 'Shroom':
                return setActivatedWalletRegestrationStatus;
            case 'UGLY':
                return setActivatedWalletUglyBrosRegestrationStatus;
        }      
    }
    function getSetResgitrationEpoch(){
        switch(selectedToken){
            case 'Shroom':
                return setActivatedWalletRegestrationEpoch;
            case 'UGLY':
                return setActivatedWalletUglyBrosRegestrationEpoch;
        }     
    }
    function getSetPreviousRewards(){
        switch(selectedToken){
            case 'Shroom':
                return setActivatedWalletPreviousRewards;
            case 'UGLY':
                return setActivatedWalletUglyBrosPreviousRewards;
        }     
    }
    function getSetUnclaimedRewards(){
        switch(selectedToken){
            case 'Shroom':
                return setActivatedWalletUnclaimedRewards;
            case 'UGLY':
                return setActivatedWalletUglyBrosUnclaimedRewards;
        }     
    }
    function getSetClaimedRewards(){
        switch(selectedToken){
            case 'Shroom':
                return setActivatedWalletClaimedRewards;
            case 'UGLY':
                return setActivatedWalletUglyBrosClaimedRewards;
        }     
    }
    function getSetTotalRewards(){
        switch(selectedToken){
            case 'Shroom':
                return setActivatedWalletTotalRewards;
            case 'UGLY':
                return setActivatedWalletUglyBrosTotalRewards;
        }     
    }

    function generateRegisterButtonText(selectedToken){
        
        switch(selectedToken){
            case "Shroom":
                return "Register wallet to earn Shrooms";
            case "UGLY":
                return "Register wallet to earn UGLY";
        }
    }


    function generateClaimButtonText(selectedToken){
        
        switch(selectedToken){
            case "Shroom":
                return "Claim Shroom coins";
            case "UGLY":
                return "Claim UGLY tokens";
        }
    }

    useEffect(() => {

      if(window.innerWidth<800){
        setShowFilterElements(false);
      }


      if (listedRaffle !== undefined){
        if (listedRaffle != null){
            if(listedRaffle == "UGLY"){
                setRaffleCoinFilter("UGLY");
            }
            else{
                setSelectedRaffleDetails(listedRaffle);
                setRaffleEntriesCount("1");
                setRaffleEntryWindowTrigger(true);
            }
        }else{
            setPopupWindowMsg("Raffle either non-existant or ended.");
            setPopupWindowTrigger(true);
        }
      }
        
            

      const rafflerefreshTicker = setInterval(async () => {
        if(document.getElementById("selectedDashboard").value=="raffles"){
            await refreshRaffles();
        }
      }
      ,120000);

      const interval = setInterval(async () => {

            if(document.getElementById('selectedWallet').value != "No wallet" && !refreshDisabled){
                //const WalletInterface = await getWalletInterface(document.getElementById('connectedWallet').innerHTML);
                let rewardAddresses;
                try{
                    rewardAddresses = await WalletInterface.getRewardAddresses();
                }
                catch{
                    WalletInterface = await getWalletInterface(document.getElementById('connectedWallet').innerHTML);
                    rewardAddresses = await WalletInterface.getRewardAddresses();
                }
                //let rewardAddresses = await WalletInterface.getRewardAddresses();
                currentRewardAddress = C.Address.from_bytes(Buffer.from(rewardAddresses[0],"hex")).to_bech32("stake");
                if(currentRewardAddress != activatedRewardAddress){
                    setBufferWindowMsg("Loading assets...");
                    setAssetsBufferWindowTrigger(true);
                    await updateConnectedWalletProfile(setActivatedWalletName,setActivatedWalletIcon,setActivatedWalletAddress,setActivatedWalletStakeAddress,setActivatedWalletBalance,setActivatedWalletShroomsBalance,setActivatedWalletRegestrationStatus,setActivatedWalletRegestrationEpoch,setActivatedWalletPreviousRewards,setActivatedWalletUnclaimedRewards,setActivatedWalletTotalRewards,setActivatedWalletClaimedRewards);
                    await getNFTsInWallet(currentRewardAddress)
                    await setSNFTsInWalletV2(liteModeStatus,setFirstArrivalsList,setZiBabiesList,setTreeOfLifeList,setFreddieTheWhaleList,setGnomiesOGList,setGnomiesTheReturnList,setJarHeadsList,setNekomimiList,setOverExposedGenesisList,setMekanismList,setMekanismCacheList,setShroomiesOGList,setUglyBrosS1List,setUglyBrosXmasList,setUglyBrosCommunityList,setUglyBrosS2List,setWhatTheDuckVIPBoxList,setWhatTheDuckList,setCollectionsDictionary,setActivatedWalletSNFTsCount,setActivatedWalletRewardMultiplier,setActivatedWalletEstimatedRewards)
                    await updateConnectedWalletGivenProjectDetails('UglyBros',setActivatedWalletUglyBrosRegestrationStatus,setActivatedWalletUglyBrosRegestrationEpoch
                                                                    ,setActivatedWalletUgliesBalance,setActivatedWalletUglyBrosNFTsCount,setActivatedWalletUglyBrosRewardMultiplier
                                                                    ,setActivatedWalletUglyBrosEstimatedRewards,setActivatedWalletUglyBrosPreviousRewards,setActivatedWalletUglyBrosUnclaimedRewards
                                                                    ,setActivatedWalletUglyBrosClaimedRewards,setActivatedWalletUglyBrosTotalRewards);
                    
                    await getMyEntriesCounts(currentRewardAddress);
                    setAssetsBufferWindowTrigger(false);                    
                }else{
                    const cborBalance = await WalletInterface.getBalance();
                    const valueBalance = C.Value.from_bytes(Buffer.from(cborBalance,"hex"));
                    let adaAmountString = "";
                    let lovelaceAmountString = valueBalance.coin().to_str();
                    adaAmountString = await convertADABalanceToUserFormat(lovelaceAmountString);
                    setActivatedWalletBalance(adaAmountString);
                    setActivatedWalletShroomsBalance(await getActivatedWalletShroomsBalance(WalletInterface));
                    setActivatedWalletUgliesBalance(await getActivatedWalletTokenBalance(WalletInterface,'UGLY',6));
                }
            }
        }
        , 5000);
      
        const secondsTicker = setInterval(async () => {
            //setPosixTime(Date.now());
            let posixTimeInSeconds = Date.now()/1000;
            let secondsSinceEpoch383 = posixTimeInSeconds - epoch383PosixReference;
            let currentEpoch = 383 + parseInt(secondsSinceEpoch383/slotsInEpoch);
            let secondsSinceCurrentEpoch = secondsSinceEpoch383%slotsInEpoch;
            let fullDaysSinceCurrentEpoch = (secondsSinceCurrentEpoch/86400);
            let fullHoursSinceLastDay = (secondsSinceCurrentEpoch%86400/3600);
            let fullMinutesSinceLastHour = (secondsSinceCurrentEpoch%86400%3600/60);
            let fullSeondsSinceLastMinute = (secondsSinceCurrentEpoch%86400%3600%60);
            setCurrentEpoch(currentEpoch);
            setSlotsSinceCurrentEpochStart(secondsSinceCurrentEpoch);
            setDaysUntilNextEpoch(Math.floor(5-fullDaysSinceCurrentEpoch));
            setHoursUntilNextEpoch(String(Math.floor(24-fullHoursSinceLastDay)).padStart(2,'0'));
            setMinutesUntilNextEpoch(String(Math.floor(60-fullMinutesSinceLastHour)).padStart(2,'0'));
            setSecondsUntilNextEpoch(String(Math.floor(60-fullSeondsSinceLastMinute)).padStart(2,'0'));
            if(secondsSinceCurrentEpoch>(slotsInEpoch-1220)){
                //this means epoch has less than 20 minutes to end
                setEndOfEpochMsg("Epoch is about to end. Claiming rewards will be resumed after processing the snapshot at the start of the next epoch.")
                if(successWindowMsgMode!=-1)
                    setSuccessWindowMsgMode(-1);
            }
            else if(secondsSinceCurrentEpoch<7200){
                //this means epoch started less than two hours ago
                let hours= String(Math.floor((7200-secondsSinceCurrentEpoch)/3600)).padStart(1,'0');
                let minutes= String(Math.floor((7200-secondsSinceCurrentEpoch)%3600/60)).padStart(2,'0');
                let seconds= String(Math.floor((7200-secondsSinceCurrentEpoch)%3600%60)).padStart(2,'0');
                setEndOfEpochMsg(<div>Processing new epoch's snapshot data. Claiming rewards will be resumed in less than <em>{hours} : {minutes} : {seconds}</em></div>);
                if(successWindowMsgMode!=-2){
                    setSuccessWindowMsgMode(-2);
                    if(document.getElementById('connectedWallet').innerHTML!="No wallet"){
                        setActivatedWalletPreviousRewards("Waiting snapshot...");
                        setActivatedWalletUnclaimedRewards("Waiting snapshot...");
                        setActivatedWalletTotalRewards("Waiting snapshot...");
                        setActivatedWalletUglyBrosPreviousRewards("Waiting snapshot...");
                        setActivatedWalletUglyBrosUnclaimedRewards("Waiting snapshot...");
                        setActivatedWalletUglyBrosTotalRewards("Waiting snapshot...");
                    }
                }
                    
            }
            else{
                if(successWindowMsgMode!=0 && successWindowMsgMode!=-3){
                    setSuccessWindowMsgMode(0);
                    await updateConnectedWalletProfile(setActivatedWalletName,setActivatedWalletIcon,setActivatedWalletAddress,setActivatedWalletStakeAddress,setActivatedWalletBalance,setActivatedWalletShroomsBalance,setActivatedWalletRegestrationStatus,setActivatedWalletRegestrationEpoch,setActivatedWalletPreviousRewards,setActivatedWalletUnclaimedRewards,setActivatedWalletTotalRewards,setActivatedWalletClaimedRewards);
                    await updateConnectedWalletGivenProjectDetails('UglyBros',setActivatedWalletUglyBrosRegestrationStatus,setActivatedWalletUglyBrosRegestrationEpoch
                    ,setActivatedWalletUgliesBalance,setActivatedWalletUglyBrosNFTsCount,setActivatedWalletUglyBrosRewardMultiplier
                    ,setActivatedWalletUglyBrosEstimatedRewards,setActivatedWalletUglyBrosPreviousRewards,setActivatedWalletUglyBrosUnclaimedRewards
                    ,setActivatedWalletUglyBrosClaimedRewards,setActivatedWalletUglyBrosTotalRewards);
                    setEndOfEpochMsg("");
                }
                
            }

        }, 1000)
      return () => {
        clearInterval(interval);
        clearInterval(secondsTicker);
        clearInterval(rafflerefreshTicker);
      };
    }, []);


    function constructSelectedProjectDisplaySyntax(selectedProject){
        if(activatedWalletName!="No wallet"){
            switch(selectedProject){
                    case "FreddieTheWhale":
                        return showNFTsOfCollection("FreddieTheWhale",freddieTheWhaleList);                               
                    case "Gnomies":
                        return(
                            <div>
                                {showNFTsOfCollection("TreeOfLife",treeOfLifeList)}
                                {showNFTsOfCollection("GnomiesOG",gnomiesOGList)}
                            </div>
                        )
                    case "JarHeads":
                        return showNFTsOfCollection("JarHeads",jarHeadsList);
                    case "Nekomimi":
                        return showNFTsOfCollection("Nekomimi",nekomimiList);
                    case "OverExposed":
                        return(
                            <div>
                                {showNFTsOfCollection("OverExposedGenesis",overExposedGenesisList)}
                                {showNFTsOfCollection("Mekanism",mekanismList)}
                                {showNFTsOfCollection("MekanismCache",mekanismCacheList)}
                            </div>
                        )  
                        case "Shrooms":
                            return(
                                <div>
                                    {showNFTsOfCollection("ShroomiesOG",shroomiesOGList)}
                                </div>
                            )   
                    case "UglyBros":
                        return(
                            <div>
                                {showNFTsOfCollection("UglyBrosS1",uglyBrosS1List)}
                                {showNFTsOfCollection("UglyBrosXmas",uglyBrosXmasList)}
                                {showNFTsOfCollection("UglyBrosCommunity",uglyBrosCommunityList)}
                                {showNFTsOfCollection("UglyBrosS2",uglyBrosS2List)}
                            </div>
                        )
                    case "WhatTheDuck":
                        return (
                            <div>
                                {showNFTsOfCollection("WhatTheDuckVIPBox",whatTheDuckVIPBoxList)}
                                {showNFTsOfCollection("WhatTheDuck",whatTheDuckList)}
                            </div>
                        )                                                                                                                                                   
                    case "ZiProject":
                        return (
                            <div>
                                {showNFTsOfCollection("FirstArrivals",firstArrivalsList)}
                                {showNFTsOfCollection("ZiBabies",ziBabiesList)}
                            </div>
                        )       
                    default:

            }
        }else
            return(
                <div className="normalText">
                    Connect a wallet to show the NFTs minted by Shrooms.
                </div>    
            )
    }

    function constructCollectionsListSyntax(collectionsList){
        //build the html syntax for the projects list
        if (collectionsList.length == 0)
            return(<></>)
        else
            return (<>
                    {collectionsList.map( collection => 
                        collection['CollectionName'] != "Unverified NFTs"?
                        <div className="collectionDataArea" onClick={() => {if (selectedProject == collection['CollectionName']) {setSelectedProject("fourzin");document.getElementById('raffleCollectionDropMenu').value="All";} else{setSelectedProject(collection['CollectionName']);document.getElementById('raffleCollectionDropMenu').value="Selected";}document.getElementById('raffleCollectionDropMenu').dispatchEvent(new Event('change', { bubbles: true }));document.getElementById("collectionSearchInput").value="";updateCollectionsSearchResult("");setShowCollectionSearchResult(false)}} style={selectedProject==collection['CollectionName']?{backgroundColor:"#d3cff8",color:"#141616"}:{}}><img src={collection['Image']} className="projectDataIcon" width={40} height="40"/><div className="collectionDataName">{collection['CollectionName']}</div></div>
                        :
                        "")}
                        <div className="collectionDataArea" onClick={() => {if (selectedProject == "Unverified NFTs") {setSelectedProject("fourzin");document.getElementById('raffleCollectionDropMenu').value="All";} else {setSelectedProject("Unverified NFTs");document.getElementById('raffleCollectionDropMenu').value="Selected";}document.getElementById('raffleCollectionDropMenu').dispatchEvent(new Event('change', { bubbles: true }));document.getElementById("collectionSearchInput").value="";updateCollectionsSearchResult("");setShowCollectionSearchResult(false)}} style={selectedProject=="Unverified NFTs"?{backgroundColor:"#d3cff8",color:"#141616"}:{}}><img src={"https://shrooms-rewards.adalink.io/icons/unverified.webp"} className="projectDataIcon" width={40} height="40"/><div className="collectionDataName">{"Unverified NFTs"}</div></div>

                    </>)
    }

    function getIPFSHash(input){
        if (input == undefined){
            return "";    
        }
        if(typeof input === 'string'){
            switch(input[0]){
                case 'd':
                    return "";
                case 'i':
                    return input.split('//',2)[1];
                default:
                    
                    return input
            }
        }else{
            
            let inputAsString ="";
            for (let i=0;i<input.length;i++){
                inputAsString = inputAsString+input[i];
            }
            return getIPFSHash(inputAsString)
        }
    }

    async function identifyPolicyID(policyID){

        //first check if policy ID is in registeredPolicies list
        if(registeredPolicies[policyID]!=null)
            return;
        //if not then identify it with api, and add it to DB if verified, if not ignore it
        response = await fetch('https://shroomsv2.adalink.io/api/get-collection-info.php?policyID='+policyID);
        let collectionInfo = JSON.parse(await response.text());
        if (collectionInfo['CollectionName'] != undefined)
            registeredPolicies[policyID]=collectionInfo;
        
    }

    function constructNFTsInWalletDisplaySyntax(){
        
        if(nftsInWallet == undefined)
            return "";
    

        let imgAParam= 0;
        for(let i=0;i<document.getElementsByClassName('nftPool').length;i++){
            if (imgAParam<document.getElementsByClassName('nftPool')[i].clientWidth)
                imgAParam=document.getElementsByClassName('nftPool')[i].clientWidth
        }
        
        //change searchedNFTsInWallet to nftsInWallet to show all NFTs
        let imgBParam=(imgAParam - 90)/4;
        const htmlBlocks = searchedNFTsInWallet.map((nft) => nft['quantity'] == 1 && nft['metadata'] != undefined && nft['metadata']['name'] != undefined?
                <div key={nft['policy']+'.'+nft['name']} className="nftImageContainer" style={{width:imgBParam}} onClick={async () => {raffleNFT.policy=nft['policy'];raffleNFT.name=nft['name'];raffleNFT.displayName=nft['metadata']['name'];raffleNFT.ipfsHash=getIPFSHash(nft['metadata']['image']);setSelectNFTMenuTrigger(false);setBufferWindowMsg("Identifying policy ID...");setBufferWindowTrigger(true);await identifyPolicyID(nft['policy']);setRaffleParametersWindowTrigger(true);setBufferWindowTrigger(false)}}>
                    <img className="nftImage" loading="lazy" width={imgBParam>1500?"1500":imgBParam} height={imgBParam>1500?"1500":imgBParam}  src={"https://ipfs.io/ipfs/"+getIPFSHash(nft['metadata']['image'])} alt={nft['metadata']['name']}
                        onError={event => {
                            event.target.src = "https://shrooms-rewards.adalink.io/icons/broken-link.webp"
                            event.onerror = null
                    }}/>
                    <div className="nftImage normalText">{nft['metadata']['name']}</div>
                </div>:""
                );
           
        return(
                <div >
                    {htmlBlocks.every(function (block) {return block == ''})? 
                    <div className="normalText" style={{marginLeft:"5px"}}>No NFTs in wallet include search input.</div>
                    :
                    htmlBlocks}
                </div>
        );
    }

    function isRaffleEntryAmountValid(){
        let decimals;
        let raffleEntryAmountString = raffleEntryAmount;
        if (raffleEntryAmountString == '')
            return false;
        let stringSplit = raffleEntryAmountString.split('.',2);
        let integersPart = parseInt(stringSplit[0]);
        if (stringSplit.length <= 1){
            if (integersPart == 0) 
                return false;
            if (integersPart > 20 && raffleCoin == "ADA")
                return false;
            if (integersPart < 100000000 && raffleCoin == "Shroom")
                return false;
        }
        if (stringSplit.length > 1){
            if (stringSplit[1] == "")
                return false;
            switch(raffleCoin){
                case "ADA":
                    decimals = 6;
                    break;
                case "UGLY":
                    decimals = 6;
                    break;
                case "Shroom":
                    decimals = 0;
                    break;
            }
            if ( (integersPart == 0 && ( (parseInt(stringSplit[1])) == 0 || raffleCoin == "ADA") ) || stringSplit[1].length > decimals )
                return false;
            if (integersPart >= 20 &&  raffleCoin == "ADA")
                return false;
        } 
        return true;
    }

    async function createRaffle(){

        let shroomsBalance = await getShroomsBalance(WalletInterface);
        if (parseInt(shroomsBalance) < 500000000){
            setPopupWindowMsg("You need to have at least 500,000,000 Shroom to create a raffle.")
            setPopupWindowTrigger(true)
            return
        }

        setBufferWindowMsg("Building transaction...");
        setBufferWindowTrigger(true);
        let nftNameInHex = new TextEncoder().encode(raffleNFT.name);
        nftNameInHex = Buffer.from(nftNameInHex).toString('hex');
        let assetFullName=raffleNFT.policy+nftNameInHex;
        let lucid = await Lucid.new(new Blockfrost("https://cardano-mainnet.blockfrost.io/api/v0","mainnetVjJTy0IDjPJvEaXW1H2LoOyEKGdAd609"),"Mainnet");
        lucid.selectWallet(WalletInterface);

        let assets = {};
        if(raffleCoin == "ADA")
            assets['lovelace']=2000000n;
        else
        assets['lovelace']=4000000n;
        assets[assetFullName]=1n;
        let shroomFullName="715d89545d6ce3cfca7680b216a3f327ee61d377732431049ed0b2405368726f6f6d";
        assets[shroomFullName]=500000000n;
        let endsAt = Math.ceil(Date.now()/1000+raffleDuration*86400);
        let metadataString = '{"msg":["'+endsAt+'","'+raffleCoin+'","'+raffleEntryAmount+'","'+"1"+'","'+"1"+'"]}'; 
        let tx,signedTx,txHash;
        try{
            tx = await lucid.newTx()
            .attachMetadata(674,JSON.parse(metadataString))
            .payToAddress("addr1q8rwjy6w85jp92g2eq9henyejne03ch94xmjzxj575cr3l8mhrjjygfcapzxksz3uxu52mv9e50y2tv4faxnkayx0zgqngs9hp", assets)
            .complete();
        }catch(e){
            setPopupWindowMsg(e.info)
            setPopupWindowTrigger(true)
            setBufferWindowTrigger(false)
            return
        }
        setBufferWindowMsg("Signing transaction...")
        try{
            signedTx = await tx.sign().complete();
        }catch(e){
            setPopupWindowMsg(e.info)
            setPopupWindowTrigger(true)
            setBufferWindowTrigger(false)
            return
        }
        setBufferWindowMsg("Submitting transaction...")
        try{
            txHash = await signedTx.submit();
        }catch(e){
            setPopupWindowMsg(e.info)
            setPopupWindowTrigger(true)
            setBufferWindowTrigger(false)
            return
        }
        setBufferWindowTrigger(false);
        setRaffleConfirmationWindowTrigger(false);
        setSuccessRaffleCreationWindowTrigger(true);
        
        //console.log(txHash);
    }

    async function enterRaffle(raffleDetails,entriesCount){

        //check if wallet connceted
        
        let activatedWalletName = document.getElementById("connectedWallet").innerHTML;
        //console.log("wallet: "+activatedWalletName);
        if(activatedWalletName == "No wallet"){
            setPopupWindowMsg("Please connnect your wallet first.");
            setPopupWindowTrigger(true);
            return;
        }     
    
        let raffleTxHash = raffleDetails['TxHash'];
        let raffleCoin = raffleDetails['Coin'];
        let entryAmount = raffleDetails['EntryAmount'];
        let endsAt = raffleDetails['EndsAt'];
        let creatorAddress = raffleDetails['StakeAddress'];

        if (currentRewardAddress == creatorAddress){
            setPopupWindowMsg("Can not enter a raffle you created.");
            setPopupWindowTrigger(true);
            return;
        }

        if (Date.now()/1000 > parseInt(endsAt)){
            setPopupWindowMsg("Can not enter raffle, it already ended.");
            setPopupWindowTrigger(true);
            return;
        }

        setBufferWindowMsg("Initiating request...")
        setBufferWindowTrigger(true);
        if (raffleCoin != "ADA"){
            switch(raffleCoin){
                case "Shroom":
                    let shroomsBalance = await getShroomsBalance(WalletInterface);
                    if (parseInt(shroomsBalance) < parseInt(entryAmount)){
                        setPopupWindowMsg("You need to have at least "+displayNumberInPrettyFormat((parseInt(entriesCount)*parseInt(entryAmount)).toString())+" Shroom to enter this raffle.")
                        setPopupWindowTrigger(true)
                        setBufferWindowTrigger(false);
                        return
                    }
                break;
                case "UGLY":
                    let uglyBalance = await getActivatedWalletTokenBalance(WalletInterface,"UGLY",6);
                    console.log(uglyBalance)
                    if (parseFloat(uglyBalance) < parseFloat(entryAmount)){
                        setPopupWindowMsg("You need to have at least "+displayNumberInPrettyFormat((parseInt(entriesCount)*parseFloat(entryAmount)).toString())+" UGLY to enter this raffle.")
                        setPopupWindowTrigger(true)
                        setBufferWindowTrigger(false);
                        return
                    }
                break;
            }
        }

        let txHashValue;
        let errorMsg;
        
    
        let utxoSelectionStrategy = 0;
        if(raffleCoin!="ADA")
            utxoSelectionStrategy = 2;
        let txBuildingTries=0;

        let raffleEntryRequestMsg = await createRaffleEntryRequest(raffleTxHash,raffleCoin,entryAmount,entriesCount,utxoSelectionStrategy)
        txBuildingTries++;
        while(raffleEntryRequestMsg=="unsuff funds"){
            if(utxoSelectionStrategy<3){
                utxoSelectionStrategy++;
                raffleEntryRequestMsg = await createRaffleEntryRequest(raffleTxHash,raffleCoin,entryAmount,entriesCount,utxoSelectionStrategy);
                if(utxoSelectionStrategy==3 && txBuildingTries<6 ){
                    utxoSelectionStrategy--;
                }
            }
            else{
                if(raffleCoin=="ADA")
                    setPopupWindowMsg("Insuffecient funds, to free some utxos in wallet and/or your wallet has less than 10 ADA, please fund it with 20 or more ADA.");
                else
                    setPopupWindowMsg("Due to CSL (Library created by Emurgo) inability to effeciently create transactions. Please send yourself the tokens amount you want to spend with 4 ADA. This will free up some utxos.");
                setPopupWindowTrigger(true);
                setBufferWindowTrigger(false);   
                return;  
            }
        }
        //console.log("checkpoint 1")
        setBufferWindowMsg("Fetching data...");
        let url;
        if (raffleCoin == "ADA")
            url="https://shroomsv2.adalink.io/api/request-raffle-entries-ADA.php";
        else
            url="https://shroomsv2.adalink.io/api/request-raffle-entries-token.php";
        let queryResponse = await fetch(url, {
            method: 'POST', 
            mode: 'cors', 
            cache: 'no-cache', 
            credentials: 'same-origin', 
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer', 
            body: raffleEntryRequestMsg // string variable
          });

        /*if(raffleEntryTxBodyString=="no response"){
            setPopupWindowMsg("Could not connect to servers. If problem persists, try later.");
            setPopupWindowTrigger(true);
            setBufferWindowTrigger(false);
            return;
        }*/
        //console.log(raffleEntryRequestMsg)
        let raffleEntryTxBodyString = await queryResponse.text();
        //console.log(raffleEntryTxBodyString)
        let raffleEntryTxBodyJson = JSON.parse(raffleEntryTxBodyString);
        
        //check if response is good
        switch(raffleEntryTxBodyJson['description']){
            case "invalid request":
                    setPopupWindowMsg("Invalid request, please follow instructions correctly.");
                    setPopupWindowTrigger(true);
                    setBufferWindowTrigger(false);
                    return;
            case "sold out":
                    setPopupWindowMsg("Maximum entries amount reached.");
                    setPopupWindowTrigger(true);
                    setBufferWindowTrigger(false);
                    return;
            default:
    
            break;
        }
        setBufferWindowMsg("Building transaction...");
        let raffleEntryTxBodyCbor = raffleEntryTxBodyJson['cborHex'];
        let txID = raffleEntryTxBodyJson['txID'];
    
        let lucid = await Lucid.new(undefined,"Mainnet");
        //let newTx = lucid.newTx().complete();
        let newTx = lucid.fromTx(raffleEntryTxBodyCbor);
        lucid.selectWallet(WalletInterface);
    
        let txHash,signedTx;
    
        setBufferWindowMsg("Signing transaction...");
        try{
            signedTx = await newTx.sign().complete();
    
            //console.log("tx id: "+txID)
            //console.log("wallet just signed the tx: "+txWitness)
        }catch{
            setPopupWindowMsg("Transaction was not signed by the user.");
            setPopupWindowTrigger(true);
            setBufferWindowTrigger(false);  
            return;      
        }
        setBufferWindowMsg("Submitting transaction...");
        try{
            txHash = await signedTx.submit();
        }catch(e){
            setPopupWindowMsg(e.info)
            setPopupWindowTrigger(true)
            setBufferWindowTrigger(false)
            return
        }
        
        if(txHash==undefined){
            setPopupWindowMsg("Transaction was not submitted by wallet due to a bug in the CSL library written by Emurgo.");
            setPopupWindowTrigger(true);
            setBufferWindowTrigger(false);  
            return;
        } 
    
        setBufferWindowTrigger(false);    
        setRaffleEntryWindowTrigger(false);
        setSuccessRaffleEntryWindowTrigger(true);
        
        //return Tx hash and show it on screen
    } 

    async function sendToATM(lovelaceAmount,fungiAmount){

        let activatedWalletName = document.getElementById("connectedWallet").innerHTML;
        //console.log("wallet: "+activatedWalletName);
        if(activatedWalletName == "No wallet"){
            setPopupWindowMsg("Please connnect your wallet first.");
            setPopupWindowTrigger(true);
            return;
        }  
        
        let fungiBalance = "";
        if (parseInt(fungiAmount)>0){
            fungiBalance = await getFungiBalance(WalletInterface);
            if (parseInt(fungiBalance) < parseInt(fungiAmount)){
                setPopupWindowMsg("Insufficient Fungi balance in wallet.")
                setPopupWindowTrigger(true)
                return
            }
        }

        setBufferWindowMsg("Building transaction...");
        setBufferWindowTrigger(true);
        let lucid = await Lucid.new(new Blockfrost("https://cardano-mainnet.blockfrost.io/api/v0","mainnetVjJTy0IDjPJvEaXW1H2LoOyEKGdAd609"),"Mainnet");
        lucid.selectWallet(WalletInterface);

        let assets = {};
        assets['lovelace']=parseInt(lovelaceAmount);
        let fungiFullName="35e174dff9dc26dfce20b4c817768c3f5e637126896461a6fa9193f646756e6769";
        if (parseInt(fungiAmount) > 0)
            assets[fungiFullName]=parseInt(fungiAmount);new C.BigInt(fungiAmount);
        let tx,signedTx,txHash;
        console.log(assets)
        try{
            tx = await lucid.newTx()
            .payToAddress("addr1vylam640q03qm54scxtpkknzkeu7mx2h9p7n5qm6wttnuwgqftv9x", assets)
            .complete();
        }catch(e){
            setPopupWindowMsg(e.info)
            setPopupWindowTrigger(true)
            setBufferWindowTrigger(false)
            return
        }
        setBufferWindowMsg("Signing transaction...")
        try{
            signedTx = await tx.sign().complete();
        }catch(e){
            setPopupWindowMsg(e.info)
            setPopupWindowTrigger(true)
            setBufferWindowTrigger(false)
            return
        }
        setBufferWindowMsg("Submitting transaction...")
        try{
            txHash = await signedTx.submit();
        }catch(e){
            setPopupWindowMsg(e.info)
            setPopupWindowTrigger(true)
            setBufferWindowTrigger(false)
            return
        }
        setBufferWindowTrigger(false);
        setSuccessATMUsageWindowTrigger(true);
        
        //console.log(txHash);
    }

    function countdownTimer(endAtPosix){
        let posixTimeInSeconds = Date.now()/1000;
        let endAt = parseInt(endAtPosix);
        if(posixTimeInSeconds >= endAt)
            return 0;
        let secondsToEnd = endAt - posixTimeInSeconds;

        let days = (secondsToEnd/86400);
        let hours = (secondsToEnd%86400/3600);
        let minutes = (secondsToEnd%86400%3600/60);
        let seconds = (secondsToEnd%86400%3600%60);
        
        days = Math.floor(days);
        hours = String(Math.floor(hours)).padStart(2,'0');
        minutes = String(Math.floor(minutes)).padStart(2,'0');
        seconds = String(Math.floor(seconds)).padStart(2,'0');
        return days+"d:"+hours+"h:"+minutes+"m:"+seconds+"s";
    }

    function getRaffleLink(raffleTxHash){
        let encryptedHash = raffleTxHash.substring(0,10)+raffleTxHash.substring(0,1)+raffleTxHash.substring(15,16)+raffleTxHash.substring(10);
        return "https://shroomsv2.adalink.io/?raffle="+encryptedHash;
    }



    function isFilterPass(nft){
        //console.log(raffleCreatedByFilter+", "+raffleEntryTypeFilter+', '+raffleCoinFilter)
        switch(raffleCreatedByFilter){
            case "Me":
                if(nft['StakeAddress']!=currentRewardAddress)
                    return false;
                break;
            case "Others":
                if(nft['StakeAddress']==currentRewardAddress)
                    return false;
        }

        switch(raffleEntryTypeFilter){
            case "Me":
                if(myEntries[nft['TxHash']]==null)
                    return false;
                break;
            case "Others":
                if(myEntries[nft['TxHash']]!=null)
                return false;
        }      

        switch(raffleCoinFilter){
            case "ADA":
                if(nft['Coin']!="ADA")
                    return false;
                break;
            case "UGLY":
                //console.log(nft['DisplayName'], nft['Coin'])
                if(nft['Coin']!="UGLY")
                    return false;
                break;
            case "Shroom":
                if(nft['Coin']!="Shroom")
                    return false;                   
        }  

        /*switch(raffleTimeFilter){
            case "Recent":
                if(Date.now()/1000-parseInt(nft['StartedAt']) >= 43200)
                    return false;
                break;
            case "Ending soon":
                if(parseInt(nft['EndsAt'])-Date.now()/1000 >= 43200 || parseInt(nft['EndsAt'])-Date.now()/1000 < 0)
                    return false;
                break;             
        }*/  

        return true;
    }

    function updateNFTsSearchResult(searchString){
        
        if(nftsInWallet!=undefined){
            
            let searchResult = nftsInWallet.reduce(function(list,nft) {if(nft['quantity'] == 1 && nft['metadata'] != undefined && nft['metadata']['name'] != undefined)if(nft['metadata']['name'].toLowerCase().includes(searchString.toLowerCase())){list.push(nft);}return list;},[]);
            searchedNFTsInWallet = searchResult;  
        }      
    }


    function updateCollectionsSearchResult(searchString){

        let searchResult;
        
        if(searchString == "" || searchString == " ")
            searchResult=Array();
        else
            searchResult = collectionsList.reduce(function(list,policyID) {if(policyID['CollectionName'].toLowerCase().includes(searchString.toLowerCase())){list.push(policyID);}return list;},[]);
        searchedCollectionsList = searchResult;
    }


    function constructSelectedProjectRafflesDisplaySyntax(selectedProject){

        if(listedRaffles == undefined)
            return "";

        let imgAParam= 0;
        for(let i=0;i<document.getElementsByClassName('rafflesDisplayArea').length;i++){
            if (imgAParam<document.getElementsByClassName('rafflesDisplayArea')[i].clientWidth)
                imgAParam=document.getElementsByClassName('rafflesDisplayArea')[i].clientWidth
        }

        let imgBParam=(imgAParam - 40)/2;
        if (imgAParam > 450)
            imgBParam=(imgAParam - 80)/5;
        let htmlBlocks;
        
        switch(raffleTimeFilter){
            case "Ending soon":
                htmlBlocks = listedRafflesEndingSoonSorted.map((nft) => ((registeredPolicies[nft['PolicyID']] == undefined && selectedProject == "Unverified NFTs") || 
                                                                registeredPolicies[nft['PolicyID']]?.CollectionName == selectedProject || raffleCollectionFilter == "All") && isFilterPass(nft,raffleCreatedByFilter,raffleEntryTypeFilter,raffleCoinFilter)    ?
                        <div key={nft['TxHash']} className="raffleContainer" style={{width:imgBParam}} onClick={() => {setSelectedRaffleDetails(nft);setRaffleEntriesCount("1");setRaffleEntryWindowTrigger(true)} }>
                            <img className="nftImage" loading="lazy" width={imgBParam>1500?"1500":imgBParam} height={imgBParam>1500?"1500":imgBParam}  src={"https://ipfs.io/ipfs/"+getIPFSHash(nft['IPFSHash'])} alt={nft['DisplayName']}
                                onError={event => {
                                    event.target.src = "https://shrooms-rewards.adalink.io/icons/broken-link.webp"
                                    event.onerror = null
                            }}/>
                            <div className="nftImage normalText">{nft['DisplayName']}</div>
                            <br/>
                            {countdownTimer(nft['EndsAt'])==0?
                                <div className="nftRaffleParameter">Ended</div>
                                :
                                <div className="nftRaffleParameter">Ends in: {countdownTimer(nft['EndsAt'])}</div>}
                            <div className="nftRaffleParameter">My entries: {myEntries[nft['TxHash']]==null?0:myEntries[nft['TxHash']]}</div>
                            <div className="nftRaffleParameter">Total entries: {totalEntries[nft['TxHash']]==null?0:totalEntries[nft['TxHash']]}</div>
                            <button className="enterRaffleButton" onClick={() => {setSelectedRaffleDetails(nft);setRaffleEntriesCount("1");setRaffleEntryWindowTrigger(true)} }>{displayNumberInPrettyFormat(nft['EntryAmount'])} {nft['Coin']}</button>
                        </div>
                        :""); 
            break;
            case "Recent":
                htmlBlocks = listedRafflesRecentSorted.map((nft) => ((registeredPolicies[nft['PolicyID']] == undefined && selectedProject == "Unverified NFTs") || 
                                                                registeredPolicies[nft['PolicyID']]?.CollectionName == selectedProject || raffleCollectionFilter == "All") && isFilterPass(nft,raffleCreatedByFilter,raffleEntryTypeFilter,raffleCoinFilter)    ?
                        <div key={nft['TxHash']} className="raffleContainer" style={{width:imgBParam}} onClick={() => {setSelectedRaffleDetails(nft);setRaffleEntriesCount("1");setRaffleEntryWindowTrigger(true)} }>
                            <img className="nftImage" loading="lazy" width={imgBParam>1500?"1500":imgBParam} height={imgBParam>1500?"1500":imgBParam}  src={"https://ipfs.io/ipfs/"+getIPFSHash(nft['IPFSHash'])} alt={nft['DisplayName']}
                                onError={event => {
                                    event.target.src = "https://shrooms-rewards.adalink.io/icons/broken-link.webp"
                                    event.onerror = null
                            }}/>
                            <div className="nftImage normalText">{nft['DisplayName']}</div>
                            <br/>
                            {countdownTimer(nft['EndsAt'])==0?
                                <div className="nftRaffleParameter">Ended</div>
                                :
                                <div className="nftRaffleParameter">Ends in: {countdownTimer(nft['EndsAt'])}</div>}
                            <div className="nftRaffleParameter">My entries: {myEntries[nft['TxHash']]==null?0:myEntries[nft['TxHash']]}</div>
                            <div className="nftRaffleParameter">Total entries: {totalEntries[nft['TxHash']]==null?0:totalEntries[nft['TxHash']]}</div>
                            <button className="enterRaffleButton" onClick={() => {setSelectedRaffleDetails(nft);setRaffleEntriesCount("1");setRaffleEntryWindowTrigger(true)} }>{displayNumberInPrettyFormat(nft['EntryAmount'])} {nft['Coin']}</button>
                        </div>
                        :""); 
            break;
            case "Ended":
                htmlBlocks = endedRaffles.map((nft) => ((registeredPolicies[nft['PolicyID']] == undefined && selectedProject == "Unverified NFTs") || 
                                                                registeredPolicies[nft['PolicyID']]?.CollectionName == selectedProject || raffleCollectionFilter == "All") && isFilterPass(nft,raffleCreatedByFilter,raffleEntryTypeFilter,raffleCoinFilter)    ?
                        <div key={nft['TxHash']} className="raffleContainer" style={{width:imgBParam}} onClick={() => {setSelectedRaffleDetails(nft);setEndedRaffleDetailsWindowTrigger(true)} }>
                            <img className="nftImage" loading="lazy" width={imgBParam>1500?"1500":imgBParam} height={imgBParam>1500?"1500":imgBParam}  src={"https://ipfs.io/ipfs/"+getIPFSHash(nft['IPFSHash'])} alt={nft['DisplayName']}
                                onError={event => {
                                    event.target.src = "https://shrooms-rewards.adalink.io/icons/broken-link.webp"
                                    event.onerror = null
                            }}/>
                            <div className="nftImage normalText">{nft['DisplayName']}</div>
                            <br/>
                            <div className="nftRaffleParameter">Ended</div>
                            <div className="nftRaffleParameter">My entries: {myEntries[nft['TxHash']]==null?0:myEntries[nft['TxHash']]}</div>
                            <div className="nftRaffleParameter">Total entries: {nft['TotalEntries']}</div>
                            <button className="enterRaffleButton" onClick={() => {setSelectedRaffleDetails(nft);setEndedRaffleDetailsWindowTrigger(true)} }>{displayNumberInPrettyFormat(nft['EntryAmount'])} {nft['Coin']}</button>
                        </div>
                        :""); 
            break;
            default:
                htmlBlocks = listedRaffles.map((nft) => ((registeredPolicies[nft['PolicyID']] == undefined && selectedProject == "Unverified NFTs") || 
                                                                registeredPolicies[nft['PolicyID']]?.CollectionName == selectedProject || raffleCollectionFilter == "All") && isFilterPass(nft,raffleCreatedByFilter,raffleEntryTypeFilter,raffleCoinFilter)    ?
                        <div key={nft['TxHash']} className="raffleContainer" style={{width:imgBParam}} onClick={() => {setSelectedRaffleDetails(nft);setRaffleEntriesCount("1");setRaffleEntryWindowTrigger(true)} }>
                            <img className="nftImage" loading="lazy" width={imgBParam>1500?"1500":imgBParam} height={imgBParam>1500?"1500":imgBParam}  src={"https://ipfs.io/ipfs/"+getIPFSHash(nft['IPFSHash'])} alt={nft['DisplayName']}
                                onError={event => {
                                    event.target.src = "https://shrooms-rewards.adalink.io/icons/broken-link.webp"
                                    event.onerror = null
                            }}/>
                            <div className="nftImage normalText">{nft['DisplayName']}</div>
                            <br/>
                            {countdownTimer(nft['EndsAt'])==0?
                                <div className="nftRaffleParameter">Ended</div>
                                :
                                <div className="nftRaffleParameter">Ends in: {countdownTimer(nft['EndsAt'])}</div>}
                            <div className="nftRaffleParameter">My entries: {myEntries[nft['TxHash']]==null?0:myEntries[nft['TxHash']]}</div>
                            <div className="nftRaffleParameter">Total entries: {totalEntries[nft['TxHash']]==null?0:totalEntries[nft['TxHash']]}</div>
                            <button className="enterRaffleButton" onClick={() => {setSelectedRaffleDetails(nft);setRaffleEntriesCount("1");setRaffleEntryWindowTrigger(true)} }>{displayNumberInPrettyFormat(nft['EntryAmount'])} {nft['Coin']}</button>
                        </div>
                        :"");
            break;
        }


        return(
                <div >
                    {selectedProject == "None"?
                        <div>Select a collection to view related raffles.</div>
                        :
                        htmlBlocks.every(function (block) {return block == ''})? 
                        <div>{raffleCollectionFilter=="Selected"?
                            <div>There is no raffles with NFTs related to <a style={{color:'#beb5ff'}}>{selectedProject}</a>. Click <a className="normalLink" onClick={() => {document.getElementById('raffleCollectionDropMenu').value="All";document.getElementById('raffleCollectionDropMenu').dispatchEvent(new Event('change', { bubbles: true }));setSelectedProject("fourzin")}}>here</a> to go back</div>
                            :
                            <div>There is no raffles with selected criteria.</div>}
                        </div>
                        :
                        htmlBlocks
                    }
                </div>
        );        
    }

    return(
        <React.Fragment>
            <img className="backgroundImg" src={backgroundImg} />

            <select id="selectedDashboard" style={{display:"none"}}
                onChange={async () => {
                
                    setSelectedDashboard(document.getElementById("selectedDashboard").value);
                    setSelectedProject("fourzin");
                    if (document.getElementById("selectedDashboard").value == "raffles"){
                        if(cookies["show-beta-aggreement"]==undefined || cookies["show-beta-aggreement"]=="true"){
                            //console.log(cookies['show-beta-aggreement'])
                            setShowBetaTermsAndConditions(true);
                          }
                    }
                     
            }}>
                <option value="staking">staking</option>
                <option value="raffles">raffles</option>
                <option value="ATM">ATM</option>
            </select>
            <select id="selectedTokenElement" style={{display:"none"}}
                onChange={async () => {
                
                    setSelectedToken(document.getElementById("selectedTokenElement").value);
                    
            }}>
                <option value="Shroom">Shroom</option>
                <option value="UGLY">UGLY</option>
            </select>
            <select id="selectedWallet" style={{display:"none"}}
                onChange={async () => {
                    refreshDisabled = true;
                    setBufferWindowMsg("Loading assets...");
                    setAssetsBufferWindowTrigger(true);
                    WalletInterface = await getWalletInterface(document.getElementById('selectedWallet').value);
                    await updateConnectedWalletProfile(setActivatedWalletName,setActivatedWalletIcon,setActivatedWalletAddress,setActivatedWalletStakeAddress,setActivatedWalletBalance,setActivatedWalletShroomsBalance,setActivatedWalletRegestrationStatus,setActivatedWalletRegestrationEpoch,setActivatedWalletPreviousRewards,setActivatedWalletUnclaimedRewards,setActivatedWalletTotalRewards,setActivatedWalletClaimedRewards);
                    let rewardAddresses = await WalletInterface.getRewardAddresses();
                    currentRewardAddress = C.Address.from_bytes(Buffer.from(rewardAddresses[0],"hex")).to_bech32("stake");
                    //console.log(currentRewardAddress)
                    await getNFTsInWallet(currentRewardAddress)
                    await setSNFTsInWalletV2(liteModeStatus,setFirstArrivalsList,setZiBabiesList,setTreeOfLifeList,setFreddieTheWhaleList,setGnomiesOGList,setGnomiesTheReturnList,setJarHeadsList,setNekomimiList,setOverExposedGenesisList,setMekanismList,setMekanismCacheList,setShroomiesOGList,setUglyBrosS1List,setUglyBrosXmasList,setUglyBrosCommunityList,setUglyBrosS2List,setWhatTheDuckVIPBoxList,setWhatTheDuckList,setCollectionsDictionary,setActivatedWalletSNFTsCount,setActivatedWalletRewardMultiplier,setActivatedWalletEstimatedRewards)
                    await updateConnectedWalletGivenProjectDetails('UglyBros',setActivatedWalletUglyBrosRegestrationStatus,setActivatedWalletUglyBrosRegestrationEpoch
                    ,setActivatedWalletUgliesBalance,setActivatedWalletUglyBrosNFTsCount,setActivatedWalletUglyBrosRewardMultiplier
                    ,setActivatedWalletUglyBrosEstimatedRewards,setActivatedWalletUglyBrosPreviousRewards,setActivatedWalletUglyBrosUnclaimedRewards
                    ,setActivatedWalletUglyBrosClaimedRewards,setActivatedWalletUglyBrosTotalRewards);
                    
                    
                    
                    await getMyEntriesCounts(currentRewardAddress);
                    setAssetsBufferWindowTrigger(false);
                    refreshDisabled =false;
            }}>
                <option value="No wallet">No wallet</option>
                <option value="Nami">Nami</option>
                <option value="Eternl">Eternl</option>
                <option value="Flint Wallet">Flint Wallet</option>
            </select>
            <PopupWindow trigger={popupWindowTrigger} setTrigger={setPopupWindowTrigger} ref={popupWindowRef}>
                    <div className="msgFont">{popupWindowMsg}</div>
                    <button className='okButton' onClick={() => setPopupWindowTrigger(false)}>Ok</button>
            </PopupWindow>
            <WarningWindow trigger={warningWindowTriger} setTrigger={setWarningWindowTrigger}>
                    <div className="warningMsgFont-LeftAlign">This wallet has more than 1000 NFTs/tokens. You might have issues claiming rewards in coming epochs. 
                    It is recommended to not use such large wallets in the platform.</div>
                    <button className='warningLongButton' onClick={async () => {setWarningWindowTrigger(false);refreshDisabled=true;await registerWallet(getSetRegistrationStatus(),getSetResgitrationEpoch(),getSetPreviousRewards(),getSetUnclaimedRewards(),getSetTotalRewards(),getSetClaimedRewards(),setBufferWindowTrigger,setPopupWindowTrigger,setPopupWindowMsg,setSuccessWindowTrigger,setSuccessWindowMsgMode);refreshDisabled=false;}}>I still want to proceed</button>
                    <br/>
                    <button className='warningLongButton' onClick={() => {setWarningWindowTrigger(false)}}>Cancel</button>
            </WarningWindow>
            <WarningWindow trigger={confrimRegistrationWindowTrigger} setTrigger={setConfrimRegistrationWindowTrigger}>
                    <div className="warningMsgFont-LeftAlign">You are about to register the connected wallet to start earning {selectedToken} tokens. 
                    Are you sure?</div>
                    <br/>
                    <img src={getselectedTokenIcon(selectedToken)} className="warningIcon" width={60} height="60"></img>
                    <button className='warningLongButton' onClick={async () => {setConfrimRegistrationWindowTrigger(false);setBufferWindowMsg("Building transaction...");refreshDisabled=true;if(isWalletSizeOk()) await registerWallet(getSetRegistrationStatus(),getSetResgitrationEpoch(),getSetPreviousRewards(),getSetUnclaimedRewards(),getSetTotalRewards(),getSetClaimedRewards(),setBufferWindowTrigger,setPopupWindowTrigger,setPopupWindowMsg,setSuccessWindowTrigger,setSuccessWindowMsgMode);else await setWarningWindowTrigger(true);refreshDisabled=false;}}>Yes, start earning {selectedToken} tokens</button>
                    <br/>
                    <button className='warningLongButton' onClick={() => {setConfrimRegistrationWindowTrigger(false)}}>No, I want another token</button>
            </WarningWindow> 
            <BufferWindow trigger={bufferWindowTrigger} setTrigger={setBufferWindowTrigger} >
                    <img src={loadingIcon} width="50px" height={"50px"} className="bufferIcon"/>
                    <div className="msgFont">{bufferWindowMsg}</div>
            </BufferWindow>
            <SelectNFTMenu trigger={selectNFTMenuTrigger} setTrigger={setSelectNFTMenuTrigger} ref={selectNFTMenuRef}>
                <div className="nftSearchBarContainer">
                    <div className="nftSearchBar">
                        <img src={searchIcon} style={{marginLeft:"5px"}}/>  
                        <input className="nftSearchInput" placeholder="Search by NFT name" id="nftSearchInput" onChange={() => updateNFTsSearchResult(document.getElementById("nftSearchInput").value)}></input>  
                    </div>
                </div>
                {activatedWalletName == "No wallet"?
                    <div className="nftPool normalText">
                        Connect your wallet first.
                    </div>
                    :
                    <div className="nftPool">
                        {
                            nftsInWallet==undefined?
                            <BufferWindow trigger={true} >
                                <img src={loadingIcon} width="50px" height={"50px"} className="bufferIcon"/>
                                <div className="msgFont">Loading NFTs...</div>
                            </BufferWindow>
                            :
                            constructNFTsInWalletDisplaySyntax()
                        }
                    </div>
                }
            </SelectNFTMenu>
            <RaffleParametersWindow trigger={raffleParametersWindowTrigger} setTrigger={setRaffleParametersWindowTrigger} ref={raffleParametersWindowRef}>
                <h3 className={"menuTitle"}>Raffle Parameters</h3>
                <br></br>
                <div style={{height: 'auto',overflow:'auto'}}>
                <div className="raffleParametersNFTImageContainer">
                        <img className="raffleParametersNFTImage" loading="lazy" src={"https://ipfs.io/ipfs/"+raffleNFT.ipfsHash} alt={raffleNFT.displayName}
                            onError={event => {
                                event.target.src = "https://shrooms-rewards.adalink.io/icons/broken-link.webp"
                                event.onerror = null
                        }}/>
                        <div className="normalText" style={{maxWidth:'200px',marginLeft:'auto',marginRight:'auto'}}>{raffleNFT.displayName}</div>
                </div>
                <div className="raffleParameters normalText">
                    <div className="raffleParameter">
                        <a form="raffleDurationDropMenu">Duration: </a>
                        <select className="dropDownMenu" name="raffleDurationDropMenu" id="raffleDurationDropMenu" onChange={() => setRaffleDuration(document.getElementById('raffleDurationDropMenu').value)}>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="30">30</option>

                        </select><a> Day(s)</a>
                    </div>
                    <div className="raffleParameter">
                        <a form="raffleCoinDropMenu">Coin/Token: </a>
                        <select className="dropDownMenu" name="raffleCoinDropMenu" id="raffleCoinDropMenu" onChange={() => setRaffleCoin(document.getElementById('raffleCoinDropMenu').value)}>
                            <option value="ADA">ADA</option>
                            <option value="UGLY">UGLY</option>
                            <option value="Shroom">Shroom</option>
                        </select>
                    </div>
                    <div className="raffleParameter">
                        <a form="raffleEntryAmount">Entry amount: </a>
                        <input className="raffleEntryAmount" placeholder="0" name="raffleEntryAmount" id="raffleEntryAmount" onKeyDown={(event) => {if ((!/[0-9.]/.test(event.key)) && (!/[\B]/.test(event.key)) ) {event.preventDefault();}}} onChange={() => {setRaffleEntryAmount(removePrettyFormat(document.getElementById('raffleEntryAmount').value));document.getElementById('raffleEntryAmount').value=displayNumberInPrettyFormat(removePrettyFormat(document.getElementById('raffleEntryAmount').value))}}>
                        </input><a> {raffleCoin}</a>
                    </div>
                    <br/>
                    <div className="raffleParameter">
                        By using this site, I agree to the <a className="normalLink" onClick={() => setTermsAndConditionsWindowTrigger(true)}>terms & conditions</a> of Shrooms.
                        <br/>
                        Check Shrooms raffle rate charges <a className="normalLink" onClick={() => setRaffleChargeRatesWindowTrigger(true)}>here</a>.
                    </div>
                </div>
                <div className="raffleParameters-mobile normalText">
                    <div className="raffleParameter">
                        <a form="raffleDurationDropMenu">Duration: </a>
                        <select className="dropDownMenu" name="raffleDurationDropMenu-mobile" id="raffleDurationDropMenu-mobile" onChange={() => setRaffleDuration(document.getElementById('raffleDurationDropMenu-mobile').value)}>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="30">30</option>
                        </select><a> Day(s)</a>
                    </div>
                    <div className="raffleParameter">
                        <a form="raffleCoinDropMenu">Coin/Token: </a>
                        <select className="dropDownMenu" name="raffleCoinDropMenu-mobile" id="raffleCoinDropMenu-mobile" onChange={() => setRaffleCoin(document.getElementById('raffleCoinDropMenu-mobile').value)}>
                            <option value="ADA">ADA</option>
                            <option value="UGLY">UGLY</option>
                            <option value="Shroom">Shroom</option>
                        </select>
                    </div>
                    <div className="raffleParameter">
                        <a form="raffleEntryAmount">Entry amount: </a>
                        <input  className="raffleEntryAmount" placeholder="0" name="raffleEntryAmount-mobile" id="raffleEntryAmount-mobile" onKeyDown={(event) => {if ((!/[0-9.]/.test(event.key)) && (!/[\B]/.test(event.key)) ) {event.preventDefault();}}} onChange={() => {setRaffleEntryAmount(removePrettyFormat(document.getElementById('raffleEntryAmount-mobile').value));document.getElementById('raffleEntryAmount-mobile').value=displayNumberInPrettyFormat(removePrettyFormat(document.getElementById('raffleEntryAmount-mobile').value))}}>
                        </input><a> {raffleCoin}</a>
                    </div>
                </div>
                </div>
                <div className="raffleParameters normalText" style={{marginTop: '30px'}}>
                        {registeredPolicies[raffleNFT.policy]==null?
                        <div><img src={"https://shrooms-rewards.adalink.io/icons/unverified.webp"} width="20"/>Note: This NFT's policy ID is not recognised by Shrooms and will be listed under the unverified policies.</div>
                        :
                        <div>Note: This NFT's policy ID is recognized to belong to <a style={{color:'#beb5ff'}} >{registeredPolicies[raffleNFT.policy].CollectionName}</a> collection and will be listed under its name.</div>
                        }
                </div>
                <br/>
                <button className="raffleCancelButton" onClick={() => setRaffleParametersWindowTrigger(false)}>Cancel</button>
                <button className="raffleOkButton" onClick={() => 
                    {
                        if(isRaffleEntryAmountValid()){
                            setRaffleConfirmationWindowTrigger(true);
                            setRaffleParametersWindowTrigger(false);
                        }else{
                            switch(raffleCoin){
                                case "ADA":
                                    setPopupWindowMsg("Raffle entry amount invalid! Amount must be between 1 - 20 ADA.");
                                break;
                                case "Shroom":
                                    setPopupWindowMsg("Raffle entry amount invalid! Amount must be 100M or higher.");
                                break;
                                case "UGLY":
                                    setPopupWindowMsg("Raffle entry amount invalid! UGLY has 6 decimal digits.");
                                break;
                            }
                            setPopupWindowTrigger(true);
                        }
                }}>List</button>
            </RaffleParametersWindow>
            <RaffleParametersWindow trigger={raffleEntryWindowTrigger} setTrigger={setRaffleEntryWindowTrigger} ref={raffleEntryWindowRef}>
                {selectedRaffleDetails==undefined?<></>:<>
                <h3 className={"menuTitle"}>Raffle Details</h3>
                <button className="raffleShareButton" onClick={() => { navigator.clipboard.writeText(getRaffleLink(selectedRaffleDetails['TxHash']));setMessage("Raffle link copied to clipboard.");setMessageWindowTrigger(true)}}>Share Raffle</button>
                <br></br>
                
                <div className="raffleDetailsContainer" >
                <div className="raffleParametersNFTImageContainer">
                        <img className="raffleParametersNFTImage" loading="lazy" src={"https://ipfs.io/ipfs/"+getIPFSHash(selectedRaffleDetails['IPFSHash'])} alt={selectedRaffleDetails['DisplayName']}
                            onError={event => {
                                event.target.src = "https://shrooms-rewards.adalink.io/icons/broken-link.webp"
                                event.onerror = null
                        }}/>
                        <div className="normalText" style={{maxWidth:'200px',marginLeft:'auto',marginRight:'auto'}}>{selectedRaffleDetails['DisplayName']}</div>
                </div>
                <div className="raffleParameters normalText">
                    <div className="raffleParameter">
                        {"NFT's Policy ID: "+selectedRaffleDetails['PolicyID'].substr(0,8)+"..."+selectedRaffleDetails['PolicyID'].slice(-7)} 
                    </div>
                    <div>
                        View NFT on: <a>
                            <a className="normalLink" href={"https://pool.pm/"+selectedRaffleDetails['PolicyID']+"."+hex2ascii(selectedRaffleDetails['AssetName'])} target="_blank" rel="noreferrer noopener"><img className="marketPlaceLogo" src={poolpmLogo} width="26"/></a>
                            </a>
                    </div>
                    <div>
                        View collection on: <a>
                            <a className="normalLink" href={"https://jpg.store/collection/"+selectedRaffleDetails['PolicyID']} target="_blank" rel="noreferrer noopener"><img className="marketPlaceLogo" src={jpgStoreLogo} width="26"/></a>
                            <a className="normalLink" href={"https://plutus.art/collection/"+selectedRaffleDetails['PolicyID']} target="_blank" rel="noreferrer noopener"><img className="marketPlaceLogo" src={plutusArtLogo} width="26"/></a>
                        </a>
                    </div>
                    {countdownTimer(selectedRaffleDetails['EndsAt'])==0?
                        <div className="raffleDetail">Ended</div>
                        :
                        <div className="raffleDetail">Ends in: {countdownTimer(selectedRaffleDetails['EndsAt'])}</div>}
                    <div className="raffleDetail">
                       My entries: {myEntries[selectedRaffleDetails['TxHash']]==null?0:myEntries[selectedRaffleDetails['TxHash']]}
                    </div>
                    <div className="raffleDetail">
                       Total entries: {totalEntries[selectedRaffleDetails['TxHash']]==null?0:totalEntries[selectedRaffleDetails['TxHash']]}
                    </div>
                    <div className="raffleDetail">
                       Entry amount: {displayNumberInPrettyFormat(selectedRaffleDetails['EntryAmount'])} {selectedRaffleDetails['Coin']}
                    </div>
                    <div className="raffleDetail">
                        <a>Entries count: </a>
                        <select className="dropDownMenu" name="entriesCountDropMenu-mobile" id="entriesCountDropMenu-mobile" onChange={() => setRaffleEntriesCount(document.getElementById('entriesCountDropMenu-mobile').value)}>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </select>
                    </div>
                    <br/>
                    <div className="raffleDetail">
                       { selectedRaffleDetails['Coin']=="ADA"?
                        <>🎉️ Instant reward: {Math.floor((1+parseInt(raffleEntriesCount)*parseFloat(selectedRaffleDetails['EntryAmount']))/2)} Fungi  <a className="qMark" onClick={() => setFungiTokenWindowTrigger(true)}><img src={infoIcon} width="10" height={"10"} /></a></>
                        :
                        <></>
                       } 
                    </div>
                </div>
                <div className="raffleParameters-mobile normalText">
                    <div style={{textAlign:"center"}}>
                            <a className="normalLink" href={"https://pool.pm/"+selectedRaffleDetails['PolicyID']+"."+hex2ascii(selectedRaffleDetails['AssetName'])} target="_blank" rel="noreferrer noopener"><img className="marketPlaceLogo" src={poolpmLogo} style={{margin:"5px"}} width="30"/></a>
                            <a className="normalLink" href={"https://jpg.store/collection/"+selectedRaffleDetails['PolicyID']} target="_blank" rel="noreferrer noopener"><img className="marketPlaceLogo" src={jpgStoreLogo} style={{margin:"5px"}} width="26"/></a>
                            <a className="normalLink" href={"https://plutus.art/collection/"+selectedRaffleDetails['PolicyID']} target="_blank" rel="noreferrer noopener"><img className="marketPlaceLogo" src={plutusArtLogo} style={{margin:"5px"}} width="26"/></a>
                    </div>
                    {countdownTimer(selectedRaffleDetails['EndsAt'])==0?
                        <div className="raffleDetail">Ended</div>
                        :
                        <div className="raffleDetail">Ends in: {countdownTimer(selectedRaffleDetails['EndsAt'])}</div>}
                    <div style={{overflow:"auto"}}>
                        <div className="raffleDetail" style={{float:"left"}}>
                        My entries: {myEntries[selectedRaffleDetails['TxHash']]==null?0:myEntries[selectedRaffleDetails['TxHash']]}
                        </div>
                        <div className="raffleDetail" style={{float:"right"}}>
                        Total entries: {totalEntries[selectedRaffleDetails['TxHash']]==null?0:totalEntries[selectedRaffleDetails['TxHash']]}
                        </div>
                    </div>
                    <div className="raffleDetail" >
                       Entry amount: {displayNumberInPrettyFormat(selectedRaffleDetails['EntryAmount'])} {selectedRaffleDetails['Coin']}
                    </div>
                    <div className="raffleDetail">
                        <a>Entries count: </a>
                        <select className="dropDownMenu" name="entriesCountDropMenu" id="entriesCountDropMenu" onChange={() => setRaffleEntriesCount(document.getElementById('entriesCountDropMenu').value)}>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </select>
                    </div>
                    <div className="raffleDetail">
                       { selectedRaffleDetails['Coin']=="ADA"?
                        <>🎉️ Instant reward: {Math.floor((1+parseInt(raffleEntriesCount)*parseFloat(selectedRaffleDetails['EntryAmount']))/2)} Fungi  <a className="qMark" onClick={() => setFungiTokenWindowTrigger(true)}><img src={infoIcon} width="10" height={"10"} /></a></>
                        :
                        <></>
                       } 
                    </div>
                </div>
                <div className="raffleParameters normalText">
                    
                    <div className="raffleParameter">
                        By using this site, I agree to the <a className="normalLink" onClick={() => setTermsAndConditionsWindowTrigger(true)}>terms & conditions</a> of Shrooms.
                    </div>
                    <br/>
                </div>
                </div>
                <button className="raffleCancelButton" onClick={() => setRaffleEntryWindowTrigger(false)}>Cancel</button>
                <button className="raffleOkButton"  onClick={() => 
                    {
                        enterRaffle(selectedRaffleDetails,raffleEntriesCount)
                }}>Enter</button></>}
            </RaffleParametersWindow>
            <RaffleParametersWindow trigger={endedRaffleDetailsWindowTrigger} setTrigger={setEndedRaffleDetailsWindowTrigger} ref={endedRaffleDetailsWindowRef}>
                {selectedRaffleDetails==undefined?<></>:
                    selectedRaffleDetails['Winner']==undefined?<></>:
                <>
                <h3 className={"menuTitle"}>Raffle Details</h3>
                <br></br>
                <div className="raffleDetailsContainer" >
                <div className="raffleParametersNFTImageContainer">
                        <img className="raffleParametersNFTImage" loading="lazy" src={"https://ipfs.io/ipfs/"+getIPFSHash(selectedRaffleDetails['IPFSHash'])} alt={selectedRaffleDetails['DisplayName']}
                            onError={event => {
                                event.target.src = "https://shrooms-rewards.adalink.io/icons/broken-link.webp"
                                event.onerror = null
                        }}/>
                        <div className="normalText" style={{maxWidth:'200px',marginLeft:'auto',marginRight:'auto'}}>{selectedRaffleDetails['DisplayName']}</div>
                </div>
                <div className="raffleParameters normalText">
                    <div className="raffleParameter">
                        {"NFT's Policy ID: "+selectedRaffleDetails['PolicyID'].substr(0,8)+"..."+selectedRaffleDetails['PolicyID'].slice(-7)} 
                        </div>
                        <div>
                        View NFT on: <a>
                            <a className="normalLink" href={"https://pool.pm/"+selectedRaffleDetails['PolicyID']+"."+hex2ascii(selectedRaffleDetails['AssetName'])} target="_blank" rel="noreferrer noopener"><img className="marketPlaceLogo" src={poolpmLogo} width="26"/></a>
                            </a>
                        </div>
                        <div>
                            View collection on: <a>
                                <a className="normalLink" href={"https://jpg.store/collection/"+selectedRaffleDetails['PolicyID']} target="_blank" rel="noreferrer noopener"><img className="marketPlaceLogo" src={jpgStoreLogo} width="26"/></a>
                                <a className="normalLink" href={"https://plutus.art/collection/"+selectedRaffleDetails['PolicyID']} target="_blank" rel="noreferrer noopener"><img className="marketPlaceLogo" src={plutusArtLogo} width="26"/></a>
                            </a>
                        </div>
                    <div className="raffleDetail">Ended in: {(new Date(parseInt(selectedRaffleDetails['EndedAt']*1000))).toLocaleDateString()+" "+(new Date(parseInt(selectedRaffleDetails['EndedAt']*1000))).toLocaleTimeString()}</div>
                    
                    <div className="raffleDetail">
                        Winner: {
                            selectedRaffleDetails['Winner'] == "na"?
                                 <a>N/A</a>
                                 :
                                 <a className="normalLink" href={"https://pool.pm/"+selectedRaffleDetails['Winner']} target="_blank" rel="noreferrer noopener">{selectedRaffleDetails['Winner'].substring(0,8)+"..."+selectedRaffleDetails['Winner'].slice(-6)}</a>
                            }
                    </div>
                    <div className="raffleDetail">
                       My entries: {myEntries[selectedRaffleDetails['TxHash']]==null?0:myEntries[selectedRaffleDetails['TxHash']]}
                    </div>
                    <div className="raffleDetail">
                       Total entries: {selectedRaffleDetails['TotalEntries']}
                    </div>
                    <div className="raffleDetail">
                       Entry amount: {displayNumberInPrettyFormat(selectedRaffleDetails['EntryAmount'])} {selectedRaffleDetails['Coin']}
                    </div>
                    <br/>
                </div>
                <div className="raffleParameters-mobile normalText">
                    <div style={{textAlign:"center"}}>
                            <a className="normalLink" href={"https://pool.pm/"+selectedRaffleDetails['PolicyID']+"."+hex2ascii(selectedRaffleDetails['AssetName'])} target="_blank" rel="noreferrer noopener"><img className="marketPlaceLogo" src={poolpmLogo} style={{margin:"5px"}} width="30"/></a>
                            <a className="normalLink" href={"https://jpg.store/collection/"+selectedRaffleDetails['PolicyID']} target="_blank" rel="noreferrer noopener"><img className="marketPlaceLogo" src={jpgStoreLogo} style={{margin:"5px"}} width="26"/></a>
                            <a className="normalLink" href={"https://plutus.art/collection/"+selectedRaffleDetails['PolicyID']} target="_blank" rel="noreferrer noopener"><img className="marketPlaceLogo" src={plutusArtLogo} style={{margin:"5px"}} width="26"/></a>
                    </div>
                    <div className="raffleDetail">Ended in: {(new Date(parseInt(selectedRaffleDetails['EndedAt']*1000))).toLocaleDateString()+" "+(new Date(parseInt(selectedRaffleDetails['EndedAt']*1000))).toLocaleTimeString()}</div>
                    
                    <div className="raffleDetail">
                        Winner: {
                            selectedRaffleDetails['Winner'] == "na"?
                                <a>N/A</a>
                                 :
                                 <a className="normalLink" href={"https://pool.pm/"+selectedRaffleDetails['Winner']} target="_blank" rel="noreferrer noopener">{selectedRaffleDetails['Winner'].substring(0,8)+"..."+selectedRaffleDetails['Winner'].slice(-6)}</a>
                            }
                    </div>
                    <div style={{overflow:"auto"}}>
                        <div className="raffleDetail" style={{float:"left"}}>
                        My entries: {myEntries[selectedRaffleDetails['TxHash']]==null?0:myEntries[selectedRaffleDetails['TxHash']]}
                        </div>
                        <div className="raffleDetail" style={{float:"right"}}>
                        Total entries: {selectedRaffleDetails['TotalEntries']}
                        </div>
                    </div>
                    <div className="raffleDetail">
                       Entry amount: {displayNumberInPrettyFormat(selectedRaffleDetails['EntryAmount'])} {selectedRaffleDetails['Coin']}
                    </div>
                </div>
                </div>
                <button className="raffleCancelButton" onClick={() => setEndedRaffleDetailsWindowTrigger(false)}>OK</button>
                </>}
            </RaffleParametersWindow>
            <TermsAndConditionsWindow trigger={termsAndConditionsWindowTrigger} title="Terms & Conditions" setTrigger={setTermsAndConditionsWindowTrigger} ref={termsAndConditionsWindowRef}>
            <div className='termsAndConditionsText'>
                <p>I herby acknowledge that $SHROOM token is a meme coin that has zero intrensic value.</p>
                <p>I am fully responsible of any potential losses using this site, being it by trading or directly losing assets. Shrooms bares no responsibility on any listed assets on this site whatsoever.</p>
            </div>
            </TermsAndConditionsWindow>
            <TermsAndConditionsWindow trigger={raffleChargeRatesWindowTrigger} title="Raffle Charge Rates" setTrigger={setRaffleChargeRatesWindowTrigger} ref={raffleChargesRatesWindowRef}>
            <div className='termsAndConditionsText'>
                <h5>Initial down payment:</h5>
                <br/>
                <p>- For Raffles in ADA: 2 ADA + 500,000,000 Shroom</p>
                <p>- For Raffles in other tokens: 4 ADA + 500,000,000 Shroom</p>
                <br/>
                <h5>Rate Charges For ADA Raffles:</h5>
                <br/>
                <p>- In case no entries were made, you will automatically get back the NFT along with the intial down payment minus transaction fees.</p>
                <p>- If total ADA collected is more than 100, Shrooms will get a 2% of revenue.</p>
                <p>- If total ADA collected is less than 100, Shrooms will get a flat 2 ADA.</p>
                <br/>
                <h5>Rate Charges For Token Raffles:</h5>
                <br/>
                <p>- In case no entries were made, you will automatically get back the NFT along with the intial down payment minus transaction fees.</p>
                <p>- If total token collected is more than 100, Shrooms will get a 2% of revenue.</p>
                <p>- If total token collected is less than 100, Shrooms will get a flat 2 tokens.</p>
                <br/>
                <p>You will recieve your raffle revenue or NFT automatically once it ends. There is no need to manually claim it.</p>  
            </div>
            </TermsAndConditionsWindow> 
            
            <TermsAndConditionsWindow trigger={showBetaTermsAndConditions} title="Know Your Assumptions" setTrigger={setShowBetaTermsAndConditions}>
                <div className='termsAndConditionsText'>
                    <p>Shrooms raffle platform is originally developed for Shrooms and affiliated projects to create raffles for their communities. 
                    The current version is considered a beta version. Nonetheless, we decided to let the platform open for the public and make it possible 
                    for anyone to create and host their own raffles.</p>
                    <p>By creating raffles, we assume that you understand the potential risks as this platform is still in beta.</p>
                </div>
                <div style={{marginTop:"10px",overflow:"auto"}}>
                    <input type="checkbox" className="checkBox"  id="dontShowThisAgainCheckBox" name="dontShowThisAgainCheckBox" value="Bike" onClick={() => {if(document.getElementById('dontShowThisAgainCheckBox').checked) setCookie('show-beta-aggreement', "false", { path: '/' });else setCookie('show-beta-aggreement', "true", { path: '/' })}}/><label className="normalText" style={{float:"left"}}> Don't show this again</label>
                </div>
                <button className="warningLongButton" onClick={() => setShowBetaTermsAndConditions(false)}>I agree</button>
            </TermsAndConditionsWindow>
            <TermsAndConditionsWindow trigger={fungiTokenWindowTrigger} title="What is Fungi Token" setTrigger={setFungiTokenWindowTrigger} ref={fungiTokenWindowRef}>
                <img src={fungiImage} width="150"/>
                
                <div className="normalText" style={{textAlign:'justify',marginTop:'15px'}}>
                    <p>Fungi is the main reward system in ADA raffles. Upon raffle entry, a user instantly gets Fungi tokens equivalent to the value of ADA
                     they entered.</p>
                    <p>Each Fungi can be redeemed at Shrooms ATM to get $SHROOM, 1 Fungi is similar to spending 2 ADA.</p>
                    <p>You never truly lose any ADA with our raffle system! 🍄️</p> 
                </div>
            </TermsAndConditionsWindow>
            <PriceTableWindow trigger={priceTableWindowTrigger} setTrigger={setPriceTableWindowTrigger} ref={tablePriceWindowRef}>
            </PriceTableWindow>
            <WarningWindow trigger={raffleConfirmationWindowTrigger} setTrigger={setRaffleConfirmationWindowTrigger}>
                <br/>
                <div className="normalText" style={{textAlign:'left'}}>
                    <div >
                        You are about to start a raffle for "{raffleNFT.displayName}". This is not a reversible action. Are you sure to proceed?
                    </div>
                    <br/>
                    <div >
                        Raffle Details:
                    </div>
                    <div >
                        - Duration: {raffleDuration} day{raffleDuration=="1"?"":"(s)"}
                    </div>
                    <div >    
                        - {raffleCoin=="ADA"?"Coin: ":"Token: "}{raffleCoin}
                    </div>
                    <div>
                        - Entry amount: {displayNumberInPrettyFormat(raffleEntryAmount)} {raffleCoin}
                    </div>
                </div>
                <br/>
                <button className="warningCancelButton" onClick={() => setRaffleConfirmationWindowTrigger(false)}>Cancel</button>
                <button className="warningOkButton" onClick={async () => await createRaffle()}>Proceed</button>
            </WarningWindow>
            <WarningWindow trigger={messageWindowTrigger} setTrigger={setMessageWindowTrigger} ref={messageWindowRef}>
                <br/>
                <div className="normalText" style={{textAlign:'left'}}>
                    {message}
                </div>
                <br/>
                <button className="warningLongButton" onClick={() => setMessageWindowTrigger(false)}>OK</button>
            </WarningWindow>
                <SuccessWindow trigger={successWindowTrigger} setTrigger={setSuccessWindowTrigger}>
                    {(() => {
                        switch(successWindowMsgMode){
                            case 0:
                                return(
                                    <>
                                    <div className='successMsgFont'>Transaction submitted successfully. Wallet is now registered.</div>
                                    <img className='successMsgImage' id="successImg" src={registrationSuccessfulImg}/>
                                    </>
                                )
                            case 1:
                                return(
                                    <>
                                    <div className='successMsgFont'>Transaction submitted successfully. {selectedToken} tokens on the way.</div>
                                    <img className='successMsgImage' style={{width:"200px"}} id="successImg" src={getSucessClaimImg(selectedToken)}/>
                                    <div className='successMsgFont'>Note: You can close this window, Rewards will still arrive to wallet.</div>    
                                    </> 
                                )
                            case -1:
                                return(
                                    <>
                                    <div className='successMsgFont'>Registration and reward claiming will be resumed once the new snapshot data is fully processed.</div>
                                    <img className='successMsgImage' id="successImg" src={systemMaintinanceImg}/>
                                    </>                                   
                                )
                            case -2:
                                return(
                                    <>
                                    <div className='successMsgFont'>Registration and reward claiming will be resumed once the new snapshot data is fully processed.</div>
                                    <img className='successMsgImage' id="successImg" src={systemMaintinanceImg}/>
                                    </>                                   
                                )   
                            case 3:
                                return(
                                    <>
                                    <div className='successMsgFont'>Shrooms updating its nodes to v1.35.5, reward claiming will be resumed shortly.</div>
                                    <img className='successMsgImage' id="successImg" src={systemMaintinanceImg}/>
                                    </>                                   
                                )    
                        }
                    }                
                    )()}

                    <button className='successOkButton' onClick={() => setSuccessWindowTrigger(false)}>Ok</button>
                </SuccessWindow>
                <SuccessWindow trigger={successRaffleCreationWindowTrigger} setTrigger={setSuccessRaffleCreationWindowTrigger}>
                    <div className='successMsgFont'>Transaction submitted successfully. Raffle will be available shortly.</div>
                    <img className='successMsgImage' style={{width:"200px"}} id="successImg" src={getSucessClaimImg(selectedToken)}/>
                    <div className='successMsgFont'>Note: You can close this window, press <img src={refreshIcon} width="10"></img> to refresh raffles.</div>   
                    <button className='successOkButton' onClick={() => setSuccessRaffleCreationWindowTrigger(false)}>Ok</button>
                </SuccessWindow>
                <SuccessWindow trigger={successRaffleEntryWindowTrigger} setTrigger={setSuccessRaffleEntryWindowTrigger}>
                    <div className='successMsgFont'>Transaction submitted successfully. Your entry will be synced once it is on-chain.</div>
                    <img className='successMsgImage' style={{width:"200px"}} id="successImg" src={getSucessClaimImg(selectedToken)}/>
                    <div className='successMsgFont'>Note: You can close this window, press <img src={refreshIcon} width="10"></img> to refresh raffles.</div>   
                    <button className='successOkButton' onClick={() => setSuccessRaffleEntryWindowTrigger(false)}>Ok</button>
                </SuccessWindow>
                <SuccessWindow trigger={successATMUsageWindowTrigger} setTrigger={setSuccessATMUsageWindowTrigger}>
                    <div className='successMsgFont'>Transaction submitted successfully. Shroom coins will shortly arrive to your wallet.</div>
                    <img className='successMsgImage' style={{width:"200px"}} id="successImg" src={getSucessClaimImg(selectedToken)}/>
                    <div className='successMsgFont'>Note: You can close this window, your Shrooms will still arrive to wallet.</div>   
                    <button className='successOkButton' onClick={() => setSuccessATMUsageWindowTrigger(false)}>Ok</button>
                </SuccessWindow>
            {selectedDashboard==""?
            <></>
            :
            <><div key={selectedToken} id="mainContainer" className="mainCointainer slide-in-fwd-center" >
                {selectedDashboard=="staking"?
                <>
                    {successWindowMsgMode>-1?<></>:
                    <div className="normalText epochEndMsgContainer slide-in-fwd-center">
                        {endOfEpochMsg}
                    </div>
                    }
                    
                    <div className="walletAndStakingParentContainer slide-in-fwd-center">
                        <div className="normalText walletAndEpochContainer">
                        <div className="walletContainer2">
                            <div>
                                <img src={activatedWalletIcon} className="walletIcon" width={60} height="60"></img>
                                <div className="walletNameFont">{activatedWalletName}</div>
                                <div className="addressFont">{activatedWalletAddress}</div>
                            </div>
                            <br/>
                            {window.innerWidth>900?
                            <div className="walletDataArea"><div>Stake Address: <div className="walletDataValue">{activatedWalletStakeAddress}</div></div></div>
                            :
                            <div className="walletDataArea">
                                <div>Stake</div>
                                <div>Address: <div className="walletDataValue">{activatedWalletStakeAddress}</div></div>
                            </div> 
                            }
                            <div className="walletDataArea"><div>Registration Status: <div className="walletDataValue">{showRegistrationStatus()}</div></div></div>
                            <div className="walletDataArea"><div>ADA Balance: <div className="walletDataValue">{activatedWalletBalance} ADA</div></div></div>
                            <div className="walletDataArea"><div>{selectedToken} Balance: <div className="walletDataValue">{showTokenBalance()} {selectedToken}</div></div></div>
                            <div className="walletDataArea"><div>Number of NFTs: <div className="walletDataValue">{showNFTsCount()}</div></div></div>
                        </div>
                        <div className="epochContainer">
                            <div>
                                <img src={cardanoLogo} className="walletIcon" width={60} height="60"></img>
                                <div className="walletNameFont">Epoch Timer</div>
                                <div className="addressFont">Current epoch: {currentEpoch}</div>
                            </div>
                            <br />
                            <div style={{margin:"auto",width:"95%"}}>
                                <ProgressBar bgcolor="#beb5ff" progress={(slotsSinceCurrentEpochStart/slotsInEpoch*100).toFixed(1)} height={40} />
                            </div>
                            <h5 style={{textAlign:"center",marginTop:"15px",marginBottom:"10px"}}>Current Epoch Ends In</h5>
                            <div className="epochTimer" >
                                <div className="epochTimerDigit">
                                    <div>Days</div>
                                    <div>{daysUntilNextEpoch}</div>
                                </div>
                                <div className="epochTimerDigitSeparator">:</div>
                                <div className="epochTimerDigit">
                                    <div>Hours</div>
                                    <div>{hoursUntilNextEpoch}</div>
                                </div>
                                <div className="epochTimerDigitSeparator">:</div>
                                <div className="epochTimerDigit">
                                    <div>Minutes</div>
                                    <div>{minutesUntilNextEpoch}</div>
                                </div>
                                <div className="epochTimerDigitSeparator">:</div>
                                <div className="epochTimerDigit">
                                    <div>Seconds</div>
                                    <div>{secondsUntilNextEpoch}</div>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="normalText stakingContainer">
                            <div>
                                <img src={stakingIcon} className="walletIcon" width={60} height="60"></img>
                                <div className="walletNameFont">Reward Summary</div>
                                <div className="addressFont">{showRegistrationEpoch()}</div>
                            </div>
                            <br/>
                            <div className="walletDataArea"><div>Current Reward Multiplier: <div className="walletDataValue">{showRegistrationStatus()=="Registered"?showRewardMultiplier():"N/A"}</div></div></div>
                            {window.innerWidth>900?
                            <div className="walletDataArea"><div>Current Epoch Estimated Rewards: <div className="walletDataValue">{showRegistrationStatus()=="Registered"?showEstimatedRewards():"N/A"} {selectedToken}</div></div></div>
                            :
                            <div className="walletDataArea">
                                <div>Current Epoch</div>
                                <div>Estimated Rewards: <div className="walletDataValue">{activatedWalletRegestrationStatus=="Registered"?showEstimatedRewards():"N/A"} {selectedToken}</div></div>
                            </div> 
                            }
                            {window.innerWidth>900?
                            <div className="walletDataArea"><div>Previous Epoch Rewards: <div className="walletDataValue">{showPreviousRewards()} {successWindowMsgMode==-2?"":selectedToken}</div></div></div>
                            :
                            <div className="walletDataArea">
                                <div>Previous Epoch</div>
                                <div>Rewards: <div className="walletDataValue">{showPreviousRewards()} {successWindowMsgMode==-2?"":selectedToken}</div></div>
                            </div> 
                            }
                            
                            <div className="walletDataArea"><div>Unclaimed Rewards: <div className="walletDataValue">{showUnclaimedRewards()} {successWindowMsgMode==-2?"":selectedToken}</div></div></div>
                            <div className="walletDataArea"><div>Claimed Rewards: <div className="walletDataValue">{showClaimedRewards()} {selectedToken}</div></div></div>
                            <div className="walletDataArea"><div>Total Rewards: <div className="walletDataValue">{showTotalRewards()} {successWindowMsgMode==-2?"":selectedToken}</div></div></div>
                            <br/>
                            {showRegistrationStatus()=="Registered"?
                                <button  className="claimRewardsButton"onClick={() => {if(successWindowMsgMode<0){setSuccessWindowTrigger(true);return};setBufferWindowMsg("Initiating request...");refreshDisabled=true;claimRewards(showUnclaimedRewards(),showClaimedRewards(),getSetUnclaimedRewards(),getSetClaimedRewards(),setPopupWindowTrigger,setPopupWindowMsg,setBufferWindowTrigger,setBufferWindowMsg,setSuccessWindowTrigger,setSuccessWindowMsgMode);refreshDisabled=false}} >{generateClaimButtonText(selectedToken)}</button>
                                :
                                <button  className="claimRewardsButton" onClick={async () => {if(successWindowMsgMode<0){setSuccessWindowTrigger(true);return};setConfrimRegistrationWindowTrigger(true)}}>{generateRegisterButtonText(selectedToken)}</button>
                            }
                        </div> 
                    </div>
    
                    <div style={{display:(selectedToken!="Shroom" )?"none":"block"}} className="normalText walletSNFTsDisplayContainer slide-in-fwd-center">
                        <BufferWindow trigger={assetsBufferWindowTrigger} setTrigger={setAssetsBufferWindowTrigger} >
                            <img src={loadingIcon} width="50px" height={"50px"} className="bufferIcon"/>
                            <div className="msgFont">{bufferWindowMsg}</div>
                        </BufferWindow>
                        <div className="walletSNFTsDisplayTitle">
                            <img src={walletNFTsIcon} className="walletIcon" width={60} height="60"></img>
                            <div className="walletNameFont">Wallet's SNFTs</div>
                            <div className="addressFont">Click on each project to display its NFTs</div>
                        </div>                    
                        <div className="projectsList">
                            <div style={{marginBottom:'10px',marginLeft:'5px'}}>Projects List</div>
                            <div className="projectDataArea" onClick={() => {setSelectedProject("FreddieTheWhale");selectedProjectDisplaySyntax=constructSelectedProjectDisplaySyntax("FreddieTheWhale")}} style={selectedProject=="FreddieTheWhale"?{backgroundColor:"#d3cff8",color:"#141616"}:{}}><img src={freddieTheWhaleIcon} className="projectDataIcon" width={40} height="40"/><div className="projectDataName">Freddie The Whale<div className="projectDataValue">{activatedWalletName=="No wallet"?"N/A":freddieTheWhaleList.length}</div></div></div>
                            <div className="projectDataArea" onClick={() => {setSelectedProject("Gnomies");selectedProjectDisplaySyntax=constructSelectedProjectDisplaySyntax("Gnomies")}} style={selectedProject=="Gnomies"?{backgroundColor:"#d3cff8",color:"#141616"}:{}}><img src={gnomiesIcon} className="projectDataIcon" width={40} height="40"/><div className="projectDataName" style={selectedProject=="Gnomies"?{}:{color:'gold'}}>Gnomies<div className="projectDataValue">{activatedWalletName=="No wallet"?"N/A":treeOfLifeList.length+gnomiesOGList.length}</div></div></div>
                            <div className="projectDataArea" onClick={() => {setSelectedProject("JarHeads");selectedProjectDisplaySyntax=constructSelectedProjectDisplaySyntax("JarHeads")}} style={selectedProject=="JarHeads"?{backgroundColor:"#d3cff8",color:"#141616"}:{}}><img src={jarHeadsIcon} className="projectDataIcon" width={40} height="40"/><div className="projectDataName">Jar Heads<div className="projectDataValue">{activatedWalletName=="No wallet"?"N/A":jarHeadsList.length}</div></div></div>
                            <div className="projectDataArea" onClick={() => {setSelectedProject("Nekomimi");selectedProjectDisplaySyntax=constructSelectedProjectDisplaySyntax("Nekomimi")}} style={selectedProject=="Nekomimi"?{backgroundColor:"#d3cff8",color:"#141616"}:{}}><img src={nekomimiIcon} className="projectDataIcon" width={40} height="40"/><div className="projectDataName">Nekomimi<div className="projectDataValue">{activatedWalletName=="No wallet"?"N/A":nekomimiList.length}</div></div></div>
                            <div className="projectDataArea" onClick={() => {setSelectedProject("OverExposed");selectedProjectDisplaySyntax=constructSelectedProjectDisplaySyntax("OverExposed")}} style={selectedProject=="OverExposed"?{backgroundColor:"#d3cff8",color:"#141616"}:{}}><img src={overExposedIcon} className="projectDataIcon" width={40} height="40"/><div className="projectDataName">Over Exposed<div className="projectDataValue">{activatedWalletName=="No wallet"?"N/A":overExposedGenesisList.length+mekanismList.length+mekanismCacheList.length}</div></div></div>
                            <div className="projectDataArea" onClick={() => {setSelectedProject("Shrooms");selectedProjectDisplaySyntax=constructSelectedProjectDisplaySyntax("Shrooms")}} style={selectedProject=="Shrooms"?{backgroundColor:"#d3cff8",color:"#141616"}:{}}><img src={shroomsLogo} className="projectDataIcon" width={40} height="40"/><div className="projectDataName">Shrooms<div className="projectDataValue">{activatedWalletName=="No wallet"?"N/A":shroomiesOGList.length}</div></div></div>
                            <div className="projectDataArea" onClick={() => {setSelectedProject("UglyBros");selectedProjectDisplaySyntax=constructSelectedProjectDisplaySyntax("UglyBros")}} style={selectedProject=="UglyBros"?{backgroundColor:"#d3cff8",color:"#141616"}:{}}><img src={uglyBrosIcon} className="projectDataIcon" width={40} height="40"/><div className="projectDataName">Ugly Bros<div className="projectDataValue">{activatedWalletName=="No wallet"?"N/A":uglyBrosS1List.length+uglyBrosXmasList.length+uglyBrosCommunityList.length+uglyBrosS2List.length}</div></div></div>
                            <div className="projectDataArea" onClick={() => {setSelectedProject("WhatTheDuck");selectedProjectDisplaySyntax=constructSelectedProjectDisplaySyntax("WhatTheDuck")}} style={selectedProject=="WhatTheDuck"?{backgroundColor:"#d3cff8",color:"#141616"}:{}}><img src={whatTheDuckIcon} className="projectDataIcon" width={40} height="40"/><div className="projectDataName">What The Duck<div className="projectDataValue">{activatedWalletName=="No wallet"?"N/A":whatTheDuckList.length+whatTheDuckVIPBoxList.length}</div></div></div>
                            <div className="projectDataArea" onClick={() => {setSelectedProject("ZiProject");selectedProjectDisplaySyntax=constructSelectedProjectDisplaySyntax("ZiProject")}} style={selectedProject=="ZiProject"?{backgroundColor:"#d3cff8",color:"#141616"}:{}}><img src={ziProjectIcon} className="projectDataIcon" width={40} height="40"/><div className="projectDataName" style={selectedProject=="ZiProject"?{}:{color:'gold'}}>Zi Project<div className="projectDataValue">{activatedWalletName=="No wallet"?"N/A":firstArrivalsList.length+ziBabiesList.length}</div></div></div>
                        
                        </div>
                        <div className="assetsDisplayArea">
                        {selectedProjectDisplaySyntax}
                        </div>
                    </div>

                    {selectedToken=="UGLY"?
                    <ProjectNFTsViewer projectName="Ugly Bros" projectIcon={uglyBrosIcon} activatedWalletName={activatedWalletName} nftsMediaReference={NFTsMediaReference} nftsNameReference={NFTsNameReference}
                        collectionsDictionary={collectionsDictionary} collectionsIcons={uglyBrosCollectionsDictionary} trigger={true}>
                        <BufferWindow trigger={assetsBufferWindowTrigger} setTrigger={setAssetsBufferWindowTrigger} >
                            <img src={loadingIcon} width="50px" height={"50px"} className="bufferIcon"/>
                            <div className="msgFont">{bufferWindowMsg}</div>
                        </BufferWindow>
                    </ProjectNFTsViewer>
                    :<></>}
                </>
                :<></>}
                {selectedDashboard=="raffles"?
                <div className="normalText rafflesWindowContainer slide-in-fwd-center" >
                    <div className="raffleWindowHeader">
                        <div className="raffleWindowTitleContainer">
                            <img src={fungiImage} className="walletIcon" width={60} height="60"></img>
                            
                            <div className="raffleWindowTitleSection"><div className="raffleWindowTitle">Raffles Window</div> <div className="refreshIcon" onClick={async () => {setBufferWindowMsg("Loading raffles...");setBufferWindowTrigger(true);await refreshRaffles();setBufferWindowTrigger(false)}}></div></div>
                            
                        </div>
                        <button className="createRaffleButton" onClick={() => {setRaffleCoin("ADA");updateNFTsSearchResult("");setSelectNFTMenuTrigger(true)}}>Create new raffle</button>
                    </div>
                    <div style={{maxWidth:"400px"}}>
                    <div className="collectionSearchBarContainer">
                            <div className="collectionSearchBar">
                                    <img src={searchIcon} style={{marginLeft:"5px"}}/>  
                                    <input className="collectionSearchInput" placeholder="Search by collection name" id="collectionSearchInput" onFocus={() => setShowCollectionSearchResult(true)} onChange={() => updateCollectionsSearchResult(document.getElementById("collectionSearchInput").value)}></input>  
                            </div>
                            {showCollectionSearchResult? 
                            <div className="rafflesCollectionsList" id="rafflesCollectionsList" ref={collectionSearchResultRef}>
                                    {constructCollectionsListSyntax(searchedCollectionsList)}
                            </div>
                            :<></>
                            }
                    </div>
                    </div>
                    <div className="not_raffleWindowRightSide">
                        {showFilterElements==false?
                        <div className="raffleFilterContainer" onClick={() => setShowFilterElements(true)}><img  src={filterIcon} width="20"/>
                        </div>
                        :
                        <div className="raffleFilterContainer scale-in-ver-top" onClick={() => {if (window.innerWidth<930) setShowFilterElements(false)}}><img  src={filterIcon} width="20"/>
                            <div className="filterElement"> Collection: <select className="dropDownMenu" name="raffleCollectionDropMenu" id="raffleCollectionDropMenu" onChange={() => setRaffleCollectionFilter(document.getElementById('raffleCollectionDropMenu').value)}>
                                <option className="option" value="All">All</option>
                                <option value="Selected">Selected</option> </select> </div>                            
                             <div className="filterElement"> Created by: <select className="dropDownMenu" name="raffleCreatedByDropMenu" id="raffleCreatedByDropMenu" onChange={() => setRaffleCreatedByFilter(document.getElementById('raffleCreatedByDropMenu').value)}>
                                <option className="option" value="All">All</option>
                                <option value="Me">Me</option>
                                <option value="Others">Others</option> </select> </div>
                             <div className="filterElement"> Entry type: <select className="dropDownMenu" name="raffleEntryTypeDropMenu" id="raffleEntryTypeDropMenu" onChange={() => setRaffleEntryTypeFilter(document.getElementById('raffleEntryTypeDropMenu').value)}>
                                <option value="All">All</option>
                                <option value="Me">Entered by me</option>
                                <option value="Others">Not entered</option></select> </div>
                            <div className="filterElement"> Coin: <select className="dropDownMenu" name="raffleCoinFilterDropMenu" id="raffleCoinFilterDropMenu" onChange={() => setRaffleCoinFilter(document.getElementById('raffleCoinFilterDropMenu').value)}>
                                <option value="All">All</option>
                                <option value="ADA">ADA</option>
                                <option value="UGLY">UGLY</option>
                                <option value="Shroom">Shroom</option></select> </div>
                            <div className="filterElement"> Time: <select className="dropDownMenu" name="raffleTimeFilterDropMenu" id="raffleTimeFilterDropMenu" onChange={() => setRaffleTimeFilter(document.getElementById('raffleTimeFilterDropMenu').value)}>
                                <option value="Active">Active</option>
                                <option value="Recent">Recent</option>
                                <option value="Ending soon">Ending soon</option>
                                <option value="Ended">Ended</option></select> </div>                                
                        </div>
                        }
                        <div key="rafflesDisplayArea" className="rafflesDisplayArea" >
                            {constructSelectedProjectRafflesDisplaySyntax(selectedProject)}
                        </div>
                    </div>
                </div>
                :<></>}
                {selectedDashboard=="ATM"?
                <div className="slide-in-fwd-center">
                <div className="normalText textContainer">
                    <h3><b>Welcome to Shrooms ATM Section</b></h3>
                    <br/>
                    <p>You can get Shroom coins in three easy steps:</p>
                    <p>1. Read Shrooms paper <a className="normalLink" href="https://www.shrooms.site/_files/ugd/6579ec_11ec9cf435964bc584309a4f8f4d902b.pdf" target="_blank" rel="noopener noreferrer">"Shroom Boom"</a>.</p>
                    <p>2. Connect your wallet of choice by clicking on "Connect wallet".</p>
                    <p>3. Click on one of the options below.</p>
                </div>
                <div className="containerOfATMContainer">
                    <div className="option1Container">
                        <div className="atmScreen">
                            <div className="normalText">
                                <h4><b>2 ADA Option</b></h4>
                                <br/>
                                <div>You will get back 1.5 ADA along with a <a className="normalLink" onClick={() => setPriceTableWindowTrigger(true)}>random</a> amount of $SHROOM tokens.</div>
                                <button className="getShroomButton" onClick={ () =>  sendToATM("2000000","0")}>Get Shroom Coins</button>
                            </div>
                        </div> 
                    </div>
                    <div className="option2Container">
                        <div className="atmScreen">
                            <div className="normalText">
                                <h4><b>3 ADA Option</b></h4>
                                <br/>
                                <div>You will get back 1.5 ADA along with either <em>500,000,000</em> or <em>1,000,000</em> Shrooms.</div>
                                <button className="getShroomButton" onClick={ () =>  sendToATM("3000000","0")}>Get Shroom Coins</button>
                            </div>
                        </div> 
                    </div>
                </div>
                <div className="containerOfATMContainer">
                    <div className="option1Container">
                                <div className="atmScreen">
                                    <div className="normalText">
                                        <h4><b>5 ADA Option</b></h4>
                                        <br/>
                                        <div>You will get 1.5 ADA and amount of $SHROOM equavalent of excuting "2 ADA Option" TEN times.</div>
                                        <button className="getShroomButton" onClick={ () =>  sendToATM("5000000","0")}>Get Shroom Coins</button>
                                    </div>
                                </div> 
                    </div>
                    <div className="option2Container">
                                <div className="atmScreen">
                                    <div className="normalText">
                                        <h4><b>Fungi Redeeming Option</b></h4>
                                        <br/>
                                        <div>You will get 1.5 ADA and amount of $SHROOM equavalent of excuting "2 ADA Option" once for each $FUNGI.</div>
                                        <div style={{marginTop:'10px',marginBottom:'10px'}}><a>Redeem: </a>
                                            <input type="number" min="0" step="1" className="raffleEntryAmount" placeholder="0" name="fungiAmount" id="fungiAmount" 
                                            onKeyDown={(event) => {if (((!/[0-9]/.test(event.key)) ) && (!/[\B]/.test(event.key)) ) {event.preventDefault();}}} 
                                             onChange={() => setFungiAmount(document.getElementById('fungiAmount').value)}>
                                            </input><a> Fungi </a>
                                        </div>
                                        <button className="getShroomButton" onClick={ () => parseInt(fungiAmount)>0? sendToATM("1750000",fungiAmount):{}}>Get Shroom Coins</button>
                                    </div>
                                </div> 
                    </div>
                </div>
                </div>
                :<></>}
            </div>

            <div className="normalText footerContainer">
                       <img src={shroomsLogo} width="64px" height={"64px"}></img>  Minting by <a className="normalLink" href="https://shrooms.site" target="_blank" rel="noreferrer noopener">Shrooms</a>
            </div>
            </>}
        </React.Fragment>
        
    )
}

export default Home;
export {showNFTsOfCollection};