import React from 'react';

import Header from './HeaderComponent.js';

import Home from './HomeComponent.js';

//            
//            <Header />

function Main (){

    return(
        <React.Fragment>
            <Header />
            <Home />
        </React.Fragment>
        
    )

}

//export default withRouter(Main);
export default Main;