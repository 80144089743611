import React from 'react';
import './PriceTableWindow.css';

function WalletMenu(props) {
    return (props.trigger) ? (
        <div className='outSidePriceTableWindow'>
            <div className='mainWindow-Body slide-in-bottom'>
                <button className='close-btn' onClick={() => props.setTrigger(false)}>  X  </button>
                <h3 className='menuTitle'>Current Rate Price</h3>
                <table id="table" className='priceTable'>
                    <tbody>
                        <tr>
                            <th> % Chance </th><th> Current Amount </th><th> Next Halving </th>
                        </tr>
                        <tr>
                            <td>1</td><td>10,000,000</td><td>5,000,000</td>
                        </tr>
                        <tr>
                            <td>4</td><td>25,000,000</td><td>12,500,000</td>
                        </tr>
                        <tr>
                            <td>8</td><td>50,000,000</td><td>25,000,000</td>
                        </tr>
                        <tr>
                            <td>22</td><td>75,000,000</td><td>37,500,000</td>
                        </tr>
                        <tr>
                            <td>30</td><td>100,000,000</td><td>50,000,000</td>
                        </tr>
                        <tr>
                            <td>22</td><td>125,000,000</td><td>62,500,000</td>
                        </tr>
                        <tr>
                            <td>8</td><td>250,000,000</td><td>125,000,000</td>
                        </tr>
                        <tr>
                            <td>4</td><td>500,000,000</td><td>250,000,000</td>
                        </tr>
                        <tr>
                            <td>1</td><td>1,000,000,000</td><td>500,000,000</td>
                        </tr>
                    </tbody>
                </table>
                { props.children }
            </div>
        </div>
    ) : "";
}

export default WalletMenu;