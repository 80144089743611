import React, { useState } from 'react';
import {showNFTsOfCollection} from './HomeComponent.js';

let selectedCollectionDisplaySyntax = "";


function ProjectNFTsViewer(props) {
    const [selectedProject,setSelectedProject]=useState("");
    //const [selectedCollectionDisplaySyntax,setSelectedCollectionDisplaySyntax] = useState("")
    function constructSelectedCollectionDisplaySyntax(selectedCollection){
        
        if(activatedWalletName!="No wallet")
            switch(selectedCollection){
                    case "UglyBrosS1":
                        return showNFTsOfCollection(selectedCollection,uglyBrosS1List,15);                          
                    case "UglyBrosXmas":
                        return showNFTsOfCollection(selectedCollection,uglyBrosXmasList,5); 
                    case "UglyBrosCommunity":
                        return showNFTsOfCollection(selectedCollection,uglyBrosCommunityList,1); 
                    case "UglyBrosS2":
                        return showNFTsOfCollection(selectedCollection,uglyBrosS2List,6);   
                    default:

            }
        else
            return(
                <div className="normalText">
                    Connect a wallet to show eligible NFTs.
                </div>    
            )
    }

    let activatedWalletName = props.activatedWalletName;
    let nftsMediaReference = props.nftsMediaReference;
    let nftsNameReference = props.nftsNameReference;
    let collectionsDictionary = props.collectionsDictionary;
    let uglyBrosS1List,uglyBrosCommunityList,uglyBrosXmasList,uglyBrosS2List;
    try{
        uglyBrosS1List = collectionsDictionary["UglyBrosS1"];
        uglyBrosXmasList = collectionsDictionary["UglyBrosXmas"];
        uglyBrosCommunityList = collectionsDictionary["UglyBrosCommunity"];
        uglyBrosS2List = collectionsDictionary["UglyBrosS2"];
    }
    catch{
        uglyBrosS1List = [];
        uglyBrosXmasList = [];
        uglyBrosCommunityList = [];
        uglyBrosS2List = [];
    }

    return (props.trigger) ? (
        <div className="normalText walletSNFTsDisplayContainer slide-in-fwd-center">
        { props.children }
        <div className="walletSNFTsDisplayTitle">
            <img src={props.projectIcon} className="walletIcon" width={60} height="60" style={{padding:'10px'}}></img>
            <div className="walletNameFont">{props.projectName} NFTs</div>
            <div className="addressFont">Click on each collection to display its NFTs</div>
        </div>                    
        <div className="projectsList">
            <div style={{marginBottom:'10px',marginLeft:'5px'}}>Collections List</div>
            <div className="projectDataArea" onClick={() => {setSelectedProject("UglyBrosS1");selectedCollectionDisplaySyntax=constructSelectedCollectionDisplaySyntax("UglyBrosS1")}} style={selectedProject=="UglyBrosS1"?{backgroundColor:"#d3cff8",color:"#141616"}:{}}><img src={props.collectionsIcons['UglyBrosS1']} className="projectDataIcon" width={40} height="40"/><div className="projectDataName">Ugly Bros Season 1<div className="projectDataValue">{activatedWalletName=="No wallet"?"N/A":uglyBrosS1List.length}</div></div></div>
            <div className="projectDataArea" onClick={() => {setSelectedProject("UglyBrosXmas");selectedCollectionDisplaySyntax=constructSelectedCollectionDisplaySyntax("UglyBrosXmas")}} style={selectedProject=="UglyBrosXmas"?{backgroundColor:"#d3cff8",color:"#141616"}:{}}><img src={props.collectionsIcons['UglyBrosXmas']} className="projectDataIcon" width={40} height="40"/><div className="projectDataName">Ugly Bros Xmas<div className="projectDataValue">{activatedWalletName=="No wallet"?"N/A":uglyBrosXmasList.length}</div></div></div>
            <div className="projectDataArea" onClick={() => {setSelectedProject("UglyBrosCommunity");selectedCollectionDisplaySyntax=constructSelectedCollectionDisplaySyntax("UglyBrosCommunity")}} style={selectedProject=="UglyBrosCommunity"?{backgroundColor:"#d3cff8",color:"#141616"}:{}}><img src={props.collectionsIcons['UglyBrosCommunity']} className="projectDataIcon" width={40} height="40"/><div className="projectDataName">Ugly Bros Community<div className="projectDataValue">{activatedWalletName=="No wallet"?"N/A":uglyBrosCommunityList.length}</div></div></div>
            <div className="projectDataArea" onClick={() => {setSelectedProject("UglyBrosS2");selectedCollectionDisplaySyntax=constructSelectedCollectionDisplaySyntax("UglyBrosS2")}} style={selectedProject=="UglyBrosS2"?{backgroundColor:"#d3cff8",color:"#141616"}:{}}><img src={props.collectionsIcons['UglyBrosS2']} className="projectDataIcon" width={40} height="40"/><div className="projectDataName">Ugly Bros Season 2<div className="projectDataValue">{activatedWalletName=="No wallet"?"N/A":uglyBrosS2List.length}</div></div></div>
        
        </div>
        <div className="assetsDisplayArea">
        { selectedCollectionDisplaySyntax}
        </div>
    </div> 
    ) : "";
}

export default ProjectNFTsViewer;