import React from 'react';
import './WalletDropDown.css';

function WalletDropDown(props) {
    return (props.trigger) ? (
            <div className="outSideDropDown">
            <div className='walletDropDown-Body scale-in-ver-top'>
                { props.children }
            </div>
            </div>
    ) : "";
}

export default WalletDropDown;