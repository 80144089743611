import React from 'react'
  
const Progress_bar = ({bgcolor,progress,height}) => {
     
    const Parentdiv = {
        height: height,
        width: '100%',
        backgroundColor: '#E1E2ED',
        borderRadius: 10,
        marginTop: "5px",
        textAlign: 'center'
      }
      
      const Childdiv = {
        height: `${parseInt(progress*10)<25?100-(1-parseInt(progress*10)/25)*50:100}%`,
        marginTop:`${progress<2.5?(height/4*(1-progress/2.5)):0}px`,
        width: `${progress}%`,
        backgroundColor: bgcolor,
        borderRadius:10,
        textAlign: 'center',
        float:"left"
      }
      
      const progresstext = {
        padding: 0,
        color: 'black',
        fontWeight: 900,
        lineHeight: 2.5,
        position:"absolute",
        width: '90%'
      }
        
    return (
    <div style={Parentdiv}>
      <div style={progresstext}>{`${progress}%`}</div>
      <div style={Childdiv}>
        
      </div>
    </div>
    )
}
  
export default Progress_bar;